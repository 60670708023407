import React from "react";
import "./Loading.scss";

const Loading = () => {
    return (
        <div className="lds-facebook-wrapper">
            <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export { Loading };

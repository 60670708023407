import * as yup from "yup";
import { urlFormatReg } from 'utils/util'

export const slugValidation = (slug: any) => {
    const noSpecialCharacterReg = /^[A-Za-z0-9 \<>\_-]+$/;
    if (slug && slug !== "") {
        if (slug[0]==='/') {
            return {
                isValid: false,
                errorMessage: "Slug must not contain '/' at first letter",
            };
        }
        else if(slug.includes(' ')) {
            return {
                isValid: false,
                errorMessage: "Slug must not contain space letter",
            };
        } 
        else if(!noSpecialCharacterReg.test(slug)) {
            return {
                isValid: false,
                errorMessage: "Slug must not contain specific character",
            };
        }
    }
    return {
        isValid: true,
    };
};

export const linkValidation = (link: any) => {
    // console.log({link})
    if(link && link !=="") {
        if (!urlFormatReg.test(link)) {
            return {
                isValid: false,
                errorMessage: "link must be a valid https url",
            };
        }
    }
    return {
        isValid: true,
    };
};

export const formSchema = yup.object().shape({
    spId: yup.string().required('ID is a required field').trim(),
    title: yup.string().required('Title is a required field').trim(),
    description: yup.string().required('Description is a required field').trim(),
    longDesc: yup.string().required('Long description is a required field').trim(),
    difficulty: yup.string().required('Difficulty is a required field').trim(),
    length: yup.string().required('Length is a required field').trim(),
    intensity: yup.string().required('Intensity is a required field').trim(),
    startTime: yup.date().required('Start time is a required field'),
    endTime: yup.date().required('End time is a required field'),
});
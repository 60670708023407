import { BaseReducer } from "../base";

class MachineReducer extends BaseReducer {
    constructor() {
        super(
            "MACHINE",
            {
                currentPage: 0,
                search: "",
                options: {
                    orderBy: {
                        value: "CREATED_AT_DESC",
                        label: "Created At DESC",
                        name: "orderBy",
                    },
                    status: {
                        value: "",
                        label: "All",
                        name: "status",
                    },
                },
            },
            {}
        );
    }
}

const machineReducer = new MachineReducer();

export { machineReducer };

import React, { ReactChildren, useEffect, useState } from "react";
import style from "./InputPositiveNumber.module.scss";
import TextField from "../TextField";

const InputNumber = (props: any) => {
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const { inputProps } = props;
  return (
    <div className={style["InputPositiveNumber"]}>
      <TextField
        {...props}
        onKeyDown={(e) =>
          exceptThisSymbols.includes(e.key) && e.preventDefault()
        }
        inputProps={{ type: "number", ...inputProps }}
      />
    </div>
  );
};

export default InputNumber;

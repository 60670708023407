import React, { useEffect, useRef } from "react";
import style from "./style.module.scss";
import {
    TextField as ComponentMui,
    TextFieldProps as TextFieldMuiProps,
} from "@mui/material";
import Info from '@mui/icons-material/Info';

interface ComponentProps {
    children?: any
    error?: any
    helperText?: any
    label?: any
    placeholder?: string
    style?: any
    inputProps?:any
}
const TextField = (props: ComponentProps | TextFieldMuiProps) => {
    const { children, error, helperText, label, placeholder } = props;
    return (
        <div className={style["TextField-Wrapper"] + ' TextField-Wrapper'}>
            <ComponentMui
                {...props}
                placeholder={placeholder ? placeholder : label}
            >
                {children}
            </ComponentMui>
            {(error && helperText && helperText !=='') && (
                <div className={style["text-error"]}>
                    <div className={style['icon']}><Info /></div>
                    <div className={style['text']}>{helperText}</div>
                </div>
            )}
        </div>
    );
};

export default TextField;

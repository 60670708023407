import { BaseAction } from "../base.action";

export class SupportPostAction extends BaseAction {
    constructor() {
        super("SUPPORT_POST");
    }
    reloadData() {
        return {
            type: `${this.name}_RELOAD_DATA`
        };
    };
}

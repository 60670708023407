const GLOBAL_FUNCTIONS = {
    SetObject: (sourceObject: any, desObject?: any) => {
        const obj = Object.assign({}, sourceObject);
        if (desObject !== undefined) {
            const keys = Object.keys(desObject);
            keys.forEach(k => {
                obj[k] = desObject[k];
            });
        }
        return obj;
    },
    FormatNumToThousand: (pNum: any, pUnitStr?: string) => {
        const unitStr = pUnitStr === undefined ? ',' : pUnitStr;
        return pNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${unitStr}`);
    },
    FormatTextToSlug: (text: string) => {
        return text.toLowerCase().replace(/[^A-Za-z0-9\s]/g, '').replace(/\s/g, '-')
    }
}

export default GLOBAL_FUNCTIONS;

import { BaseAPI } from "./base";
import { ENV } from "scripts/apps/environment";

export class ExperienceAPI extends BaseAPI {
    constructor() {
        super(ENV.SERVER_DOMAIN + "/rest/v1/experience")
    }
    async importCsv(formBody: {
        file: any
    }) {
        var form = new FormData()
        for (const key of Object.keys(formBody)) {
            let value = (formBody as any)[key]
            form.append(key, value)
        }
        const url: string = this._parseUrl("importCsv")
        const options: any = {
            method: "POST",
            headers: {
            },
            body: form,
            credentials: "include"
        }
        const res: any = await this._exec(url, options)
        if(res.code !== 200){
            throw res
        }
        return res
    }
    

}
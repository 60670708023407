import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { set } from "lodash";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import { useAdminGameProductCategoryGroupsQuery, useAdminProductsQuery } from "services";

const useMainHook = () => {
  const {
    global,
    supportCategoryStore,
    globalDispatch,
    supportCategoryDispatch,
  } = React.useContext(AdminAppContext);

  React.useLayoutEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const params: any = useParams();

  const {
    data: ProductCategoryGroups,
    loading: loadingProductCategoryGroups,
    refetch: refetchProductCategoryGroups,
  } = useAdminGameProductCategoryGroupsQuery();

  const [state, setState] = React.useState({
    columns: [
      {
        numeric: false,
        disablePadding: false,
        label: "Image",
        id: "image",
        className: "productShopImageCell",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Name",
        id: "name",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Description",
        id: "description",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Category Name",
        id: "categoryName",
        hasSub: false,
      },
      // {
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Group",
      //   id: "groupId",
      //   hasSub: false,
      // },
      // {
      //   numeric: true,
      //   disablePadding: false,
      //   label: "Game Type",
      //   id: "gameType",
      //   hasSub: false,
      // },
    ],
    rows: [],

    totalCount: 0,

    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchParam = searchParams.get("seach");
  const pageSizeParam = searchParams.get("pageSize") || 10;
  const pageParam = searchParams.get("page") || 0;

  const [queryParameter, setQueryParameter] = React.useState<any>({
    pageSize: Number(pageSizeParam) || 10,
    page: Number(pageParam) || 0,
    name: searchParam || "",
  });

  React.useEffect(() => {
    let urlParams = `?tab=product&page=${queryParameter.page}&pageSize=${queryParameter.pageSize}`;
    if (queryParameter.name) {
      urlParams = urlParams + `&search=${queryParameter.name}`;
    }
    if (queryParameter.sortOrder) {
      urlParams = urlParams + `&sortOrder=${queryParameter.sortOrder}`;
    }
    if (queryParameter.categoryGroupId) {
      urlParams =
        urlParams + `&categoryGroupId=${queryParameter.categoryGroupId}`;
    }
    history.replace(urlParams);
  }, [queryParameter]);

  const { data, loading, refetch } = useAdminProductsQuery({
    variables: {
     input: {
      name: queryParameter.name,
      page: queryParameter.page + 1,
      pageSize: queryParameter.pageSize,
      categoryId: Number(params.id),
     }
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (data && data.adminProducts?.products && ProductCategoryGroups) {
      setState({
        ...state,
        rows: data.adminProducts.products.map((item: any) => {
          const GroupCategory =
          ProductCategoryGroups?.adminGameProductCategoryGroups?.filter(
            (el: any) => el.id == item.groupId
          ) || [];
          return {
            id: item.id,
            image: <img src={item.imageUrl} alt='product image' />,
            name: item?.name,
            description: item?.description,
            categoryName: item?.categoryName,
            groupId: GroupCategory[0]?.name || "",
            gameType: item?.gameType,
            type: item?.type,
          };
        }),
        totalCount: data.adminProducts.metadata.totalRecords,
      });
    }
  }, [data, ProductCategoryGroups]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({ ...queryParameter, page: newPage });
  };
  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      page: 0,
      name: newValue,
    });
  };

  const onChangeSearchValue = (value: any) => {
    supportCategoryDispatch(
      Action.supportCategory.setState(
        set(supportCategoryStore, `search`, value)
      )
    );
  };
  const handleChangeSort = (event: any) => {
    const newValue = event.target.value;
    // console.log('value', newValue)
    setQueryParameter({
      ...queryParameter,
      page: 0,
      orderBy: newValue,
    });
  };
  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const onChangeSelect = (event: any) => {
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        selected: event,
      })
    );
    set(queryParameter, event.name, event.value);
    set(queryParameter, "first", 10);
    set(queryParameter, "offset", 0);
    setQueryParameter(queryParameter);
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        currentPage: 0,
      })
    );
  };

  const handleCloseAction = (event: any, id: any) => {
    const products = state.rows.map((x: any) => {
      const item: any = { ...x };
      if (item.id === id) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    });
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows: products,
      })
    );
  };

  const handleViewDetail = (event: any, item: any) => {
    history.push(`${CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}/detail/${item.id}`);
  };

  const goToSub = (even: any, item: any) => {
    const path = `${CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}/detail/${item.id}`;
    history.push({ pathname: path });
  };

  const handleAddPost = () => {
    history.push(CONSTANTS.NAVIGATION_URL.SUPPORT_ADD);
  };

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    goToSub,
    action: {
      detail: true,
    },
    handleClose: handleCloseAction,
    handleChangePage,
    handleViewDetail,
  };
  return {
    supportCategoryStore,
    propsTable,
    state,
    loading,
    handleAddPost,
    handleSearch,
    onSearchKeyDown,
    onChangeSelect,
    onChangeSearchValue,
    handleChangeSort,
    queryParameter,
  };
};

export default useMainHook;

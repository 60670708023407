import { BaseReducer } from '../base';

class GlobalReducer extends BaseReducer {
    constructor() {
        super("GLOBAL", {
            current_page: 0
        })
    }

}

const globalReducer = new GlobalReducer()

export {
    globalReducer
}

import { RingLoading } from "Components/RingLoading/RingLoading";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "Components/base";
import React from "react";
import useMainHook from "./Main.hook";
import style from "./MainGameSetting.module.scss";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import { torem } from "styles/theme/toRem";
import AddWrapper from "Admin/Screens/SupportCategories/Add/AddWrapper";
import { MONTHLY_CHALLENGE_TARGET_TYPE } from "consts/monthlyChallenge";
import { LANGUAGE_SELECT } from "consts/languageData";

const Main: React.FunctionComponent = () => {
  const {
    loading,
    formik,
    handleSubmitForms,
  } = useMainHook();
  return (
    <div className={style["MainGameSetting"]}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div className="aviron-title">Game Setting</div>
        </Grid>
        <Grid item className={"buttonGroup"}>
          <ButtonWithLoading
            variant="contained"
            loading={loading}
            type="submit"
            onClick={handleSubmitForms}
            // startIcon={<SaveIcon />}
            style={{ minWidth: torem(120) }}
          >
            Update
          </ButtonWithLoading>
        </Grid>
      </Grid>
      <div className={style["main"]}>
        <AddWrapper>
          <div className={style["settingBlock"]}>
            <div className={style["header"]}>Apps</div>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="appName"
                label="Name"
                placeholder="Enter Name"
                fullWidth
                name="appName"
                margin="normal"
                variant="outlined"
                error={formik.errors.appName ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.appName}
                helperText={
                  // @ts-ignore
                  formik.errors.appName && formik.touched.appName
                    ? formik.errors.appName
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron selectField">
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
              >
                Language
              </InputLabel>
              <Select
                fullWidth
                name="appLanguage"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.appLanguage}
                error={
                  formik.errors.appLanguage && formik.touched.appLanguage
                    ? true
                    : false
                }
              >
                {LANGUAGE_SELECT.map(
                  // @ts-ignore
                  (type, index) => {
                    return (
                      <MenuItem
                        value={type.key}
                        key={"gameLanguageSelect" + index}
                      >
                        {type.name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
              <FormHelperText error={formik.errors.appLanguage ? true : false}>
                {formik.errors.appLanguage && formik.touched.appLanguage
                  ? formik.errors.appLanguage
                  : null}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={style["settingBlock"]}>
            <div className={style["header"]}>Monthly Challenges</div>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="mcOffsetTime"
                label="Offset Time"
                placeholder="Enter Offset Time"
                fullWidth
                name="mcOffsetTime"
                margin="normal"
                variant="outlined"
                error={formik.errors.mcOffsetTime ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mcOffsetTime}
                helperText={
                  // @ts-ignore
                  formik.errors.mcOffsetTime && formik.touched.mcOffsetTime
                    ? formik.errors.mcOffsetTime
                    : null
                }
              />
            </FormControl>
          </div>
        </AddWrapper>
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Main;

import React, { useEffect, useRef } from "react";
import style from "./Dialog.module.scss";
import {
    Dialog as ComponentMui,
} from "@mui/material";
import {ReactComponent as CloseIcon} from 'Components/base/Icons/CloseIcon.svg';
//@ts-ignore
const Dialog = (props) => {
    const { children, className, onClose } = props;
    const dialogRef = useRef();
    // useEffect(()=>{
    //     if(dialogRef && dialogRef.current) {
    //         //@ts-ignore
    //         const spanEl = document.getElementById['onCloseIcon771753'];
    //         //@ts-ignore
    //         dialogRef.current.appendChild(spanEl);
    //     }
    // }, [dialogRef])
    return (
        <ComponentMui {...props} className={`${style["Dialog-Wrapper"]} ${className}`} ref={dialogRef}>
            <div className={style['onCloseIcon771753']} onClick={ onClose }><CloseIcon /></div>
            {children}
        </ComponentMui>
    );
};

export default Dialog;

import { Button, Grid, MenuItem, Select, SelectAutoComplete } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Products.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import { CATEGORY_GROUP, CATEGORY_TYPE } from "consts/gameShop";

interface Props {
  statusTab?: any;
}

const Products = (props: Props) => {
  const {
    propsTable,
    loading,
    handleSearch,
    queryParameter,
  } = useMainHook();
  return (
    <div>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: " ",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by Name",
                  type: "text",
                }}
              />
            ),
            selectFilter: (
              <>
                {/* <Select
                  displayEmpty
                  name="group"
                  value={""}
                  // onChange={handleChangeSort}
                  fullWidth
                  placeholder="All Group"
                >
                  {CATEGORY_GROUP.map((item, key) => {
                    return <MenuItem value={item.key}>{item.label}</MenuItem>;
                  })}
                </Select>
                <Select
                  displayEmpty
                  name="categoryType"
                  value={""}
                  // onChange={handleChangeSort}
                  fullWidth
                  placeholder="All Category"
                >
                  {CATEGORY_TYPE.map((item, key) => {
                    return <MenuItem value={item.key}>{item.label}</MenuItem>;
                  })}
                </Select>
                <SelectAutoComplete
                  placeholder = "All Game Type"
                  fullWidth
                  inputProps={{
                    name: "gameType"
                  }}
                  options={CATEGORY_TYPE.map((item)=>{
                    return {
                      value: item.key,
                      label: item.label
                    }
                  })}
                /> */}
              </>
            ),
          }}
        />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Products;

import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { TextField } from "@mui/material";
import { useDebounce } from "scripts/apps/useDebounce";
import isEmpty from "lodash/isEmpty";
import "./ImageUploadInput.scss";
import { ReactComponent as UploadIcon } from "Components/base/Icons/ExportIcon.svg";

type PropsType = {
  label: string;
  showInput?: boolean;
  multipleFiles?: boolean;
  thumbValue: string;
  onChangeValue: any;
  uploadImage: (file: File | null) => void;
  setFieldValue: (field: string, value: any) => void;
  nameFieldForm?: string;
  required?: any;
  isHideUploadFile?: boolean
  disabled?: boolean
};

type stateType = {
  imgFile: File | null;
  imgUrl: string;
}[];

export const ImageUploadInput = (props: PropsType) => {
  const {
    label,
    showInput = true,
    multipleFiles = false,
    uploadImage,
    thumbValue,
    onChangeValue,
    setFieldValue,
    nameFieldForm,
    required,
    isHideUploadFile,
    disabled,
  } = props;

  const [uploadFiles, setUploadFiles] = useState<stateType>([]);
  const [touched, setTouched] = useState(false);

  const validate = () => {
    const errorColor = "#ccc";
    if (touched && isEmpty(uploadFiles) && isEmpty(thumbValue)) {
      return {
        borderStyle: { border: `1px solid ${errorColor}` },
        colorStyle: { color: errorColor },
      };
    }
    return {};
  };

  const removeImage = (index: number) => {
    setUploadFiles(
      uploadFiles.filter((value, i) => {
        if (index !== i) return value;
      })
    );
    uploadImage(null);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (multipleFiles) {
      // Upload multi files will update in the future
      const data = acceptedFiles.map((imgFile: File) => {
        if (imgFile) {
          return {
            imgFile,
            imgUrl: URL.createObjectURL(imgFile),
          };
        }
      });
      setUploadFiles(data);
    } else {
      const imgFile = acceptedFiles[0];
      if (imgFile) {
        setUploadFiles([
          {
            imgFile,
            imgUrl: URL.createObjectURL(imgFile),
          },
        ]);
        uploadImage(imgFile);
      }
    }
  }, []);

  const dropZoneObj = {
    onDrop,
    disabled: !isEmpty(thumbValue),
    multiple: multipleFiles,
    accept: "image/png, image/jpg, image/jpeg",
  };
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropZoneObj);

  const _renderThumbs = (imageFiles: any) => {
    return (
      <div className="flex-center uploadContentWrapper">
        {imageFiles.map((item: any, index: number) => (
          <div key={index} className="review-image-container">
            <i
              className="far fa-times-circle review-image-container__remove-btn"
              onClick={() => removeImage(index)}
            ></i>
            <img src={item.imgUrl} className="review-image-container__image" />
          </div>
        ))}
      </div>
    );
  };

  const _renderDragArea = (isDragActive: boolean) => {
    return (
      <div style={{ textAlign: "center" }}>
        {/* <i className="fas fa-file-upload upload-icon"></i> */}
        <UploadIcon className="upload-icon" />
        <div className="dragAreaText">
          {isDragActive
            ? "Drop the image here..."
            : "Choose photo to upload here"}
        </div>
      </div>
    );
  };

  const _renderInputURL = () => {
    return (
      <div className="flex-center">
        <TextField
          onClick={() => setTouched(true)}
          name={nameFieldForm || "thumb"}
          style={{ width: "100%" }}
          value={thumbValue}
          onChange={(e) => {
            onChangeValue(e);
          }}
          label=""
          variant="outlined"
          size="small"
          className="image-upload-components-input"
          placeholder="Paste Image URL"
          disabled={disabled}
        />
      </div>
    );
  };

  const reviewImageUrl = useDebounce(thumbValue, 500);
  return (
    <div className="image-upload-components flex-center">
      <div className="image-upload-label">
        {label}
        <>{required && <span className="MuiInputLabel-asterisk">*</span>}</>
      </div>

      {uploadFiles.length !== 0 ? (
        _renderThumbs(uploadFiles)
      ) : (
        <div>
          {isEmpty(thumbValue) ? (
            <>
              {!isHideUploadFile && (
                <>
                  <div
                    {...getRootProps()}
                    className="image-upload-zone-container flex-center uploadNewContentWrapper"
                  >
                    <input
                      {...getInputProps({
                        onClick: () => setTouched(true),
                      })}
                    />
                    {_renderDragArea(isDragActive)}
                  </div>
                  <div className="upload_or_line_wrapper">
                    <div className="upload_or_line">
                      <div className="upload_or_line__line"></div>
                      <div className="upload_or_line__text">or</div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="flex-center uploadContentWrapper">
              <div className="review-image-container">
                {!disabled ? (
                  <i
                    className="far fa-times-circle review-image-container__remove-btn"
                    onClick={() =>
                      setFieldValue(nameFieldForm ? nameFieldForm : "thumb", "")
                    }
                  ></i>
                ) : (
                  <></>
                )}
                <img
                  src={reviewImageUrl}
                  className="review-image-container__image"
                />
              </div>
            </div>
          )}
          {showInput && _renderInputURL()}
        </div>
      )}
    </div>
  );
};

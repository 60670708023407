import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "Components/base";
import { makeStyles } from "@mui/styles";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./Add.hook";
import HTMLEditor from "Components/base/ContentFieldForm/index";
import { torem } from "styles/theme/toRem";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import AddWrapper from "Admin/Screens/SupportCategories/Add/AddWrapper";
import style from "./AddPost.module.scss";
import Collapse from "../../../../Components/DynamicContionForm/Collapse";
import { ImageUploadInput } from "Components/ImageUploadInput/ImageUploadInput";
import ConditionForm from "../../../../Components/DynamicContionForm/Condition";
import { ACCHIVEMENT_TYPE } from "consts/achivement";
import lodash from 'lodash';
import AppSelect from "Components/MonthlyChallenge/AppSelect";
import { RingLoading } from "Components/RingLoading/RingLoading";
import TargetTypeSelect from "Components/MonthlyChallenge/TargetTypeSelect";
import JsonEditor from "Components/base/JsonEditorFormField";
const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: "12px",
        margin: "12px",
    },
    buttonGroup: {
        margin: "12px",
        padding: "12px",
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        width: "48%",
    },
}));

const AchievementAdd = () => {
    const inputLabel = React.useRef(null);
    const classes = useStyles();
    const initFormAdd = {
        lv1FormFields: [
            {
                lv2FormFields: [
                    {
                        type: "rank_in_game",
                        operator: "eq",
                        operator_value: 1,
                        lv3FormFields: [],
                    },
                ],
            },
        ],
    };
    const {
        loading,
        setBack,
        formik,
        handleUploadIconGray,
        handleUploadIcon,
        conditionFormik,
        handleSubmitForms,
    } = useAddHook();

    return (
      <div className={style["AddPostWrapper"]}>
        <Grid
          container
          spacing={torem(16)}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item>
            <div className="aviron-sub-title pointer" onClick={setBack}>
              Achievement Management &gt; Add Achievement
            </div>
            <div className="aviron-title" role="presentation">
              &nbsp; Add Achievement
            </div>
          </Grid>
          <Grid item className={"buttonGroup"}>
            <ButtonWithLoading
              variant="contained"
              loading={loading}
              type="submit"
              onClick={handleSubmitForms}
              startIcon={<SaveIcon />}
              style={{ minWidth: torem(120) }}
            >
              Create
            </ButtonWithLoading>
          </Grid>
        </Grid>
        <div className={style["addBody"]}>
          {!lodash.isEmpty(formik.errors) && (
            <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
              <FormHelperText>Error in Profile Form</FormHelperText>
            </div>
          )}
          <Collapse headerText="Profile" defaultOpen={true}>
            <form>
              <div className={style["profileContent"]}>
                <Grid
                  container
                  spacing={torem(16)}
                  className="inputSelectFormGroup"
                >
                  <Grid item xs={12}>
                    <FormControl fullWidth className="form-aviron">
                      <TextField
                        required
                        id="name"
                        label="Name"
                        placeholder="Enter Name"
                        fullWidth
                        name="name"
                        margin="normal"
                        variant="outlined"
                        error={formik.errors.name ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        helperText={
                          // @ts-ignore
                          formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={torem(16)}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className="form-aviron"
                      style={{ marginTop: torem(16) }}
                    >
                      <InputLabel
                        // ref={inputLabel}
                        htmlFor="outlined-machine-type"
                        required
                      >
                        App
                      </InputLabel>
                      <AppSelect
                        onChange={formik.handleChange}
                        value={formik.values?.app}
                        otherProps={{
                          error: formik.errors.app,
                          placeholder: "Select a App",
                        }}
                      />
                      <FormHelperText error={formik.errors.app ? true : false}>
                        {formik.errors.app && formik.touched.app
                          ? formik.errors.app
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className="form-aviron"
                      style={{ marginTop: torem(16) }}
                    >
                      <InputLabel
                        // ref={inputLabel}
                        htmlFor="outlined-machine-type"
                        required
                      >
                        Type
                      </InputLabel>
                      <TargetTypeSelect
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        otherProps={{
                          error: formik.errors.type,
                        }}
                      />
                      <FormHelperText error={formik.errors.type ? true : false}>
                        {formik.errors.type && formik.touched.type
                          ? formik.errors.type
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="description"
                    label="Description"
                    placeholder="Enter Description"
                    fullWidth
                    name="description"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={formik.values.description}
                    error={
                      formik.errors.description && formik.touched.description
                        ? true
                        : false
                    }
                    // required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.description}
                    helperText={
                      formik.errors.description && formik.touched.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    id="explanation"
                    label="Explanation"
                    placeholder="Enter Explanation"
                    fullWidth
                    name="explanation"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={formik.values.explanation}
                    error={
                      formik.errors.explanation && formik.touched.explanation
                        ? true
                        : false
                    }
                    // required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.explanation}
                    helperText={
                      formik.errors.explanation && formik.touched.explanation
                        ? formik.errors.explanation
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <Grid
                    container
                    spacing={torem(16)}
                    className={style["IconFieldWrapper"]}
                  >
                    <Grid item xs={6}>
                      <ImageUploadInput
                        label="Icon Gray"
                        required={true}
                        uploadImage={handleUploadIconGray}
                        //@ts-ignore
                        thumbValue={formik.values.iconGray}
                        onChangeValue={formik.handleChange}
                        setFieldValue={formik.setFieldValue}
                        nameFieldForm="iconGray"
                        isHideUploadFile
                      />
                      <FormHelperText
                        error={formik.errors.iconGray ? true : false}
                      >
                        {formik.errors.iconGray && formik.touched.iconGray
                          ? formik.errors.iconGray
                          : null}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                      <ImageUploadInput
                        label="Icon"
                        required={true}
                        uploadImage={handleUploadIcon}
                        //@ts-ignore
                        thumbValue={formik.values.icon}
                        onChangeValue={formik.handleChange}
                        setFieldValue={formik.setFieldValue}
                        nameFieldForm="icon"
                        isHideUploadFile
                      />
                      <FormHelperText error={formik.errors.icon ? true : false}>
                        {formik.errors.icon && formik.touched.icon
                          ? formik.errors.icon
                          : null}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl
                  fullWidth
                  className={"form-aviron"}
                  style={{ marginTop: torem(16) }}
                >
                  <JsonEditor
                    name="metadata"
                    value={formik.values.metadata}
                    formik={formik}
                  />
                </FormControl>
              </div>
            </form>
          </Collapse>
          <div className={style["conditionWrapper"]}>
            <Collapse headerText="Condition" defaultOpen={true}>
              <form>
                {/* @ts-ignore */}
                <ConditionForm
                  initValues={initFormAdd}
                  formik={conditionFormik}
                />
              </form>
            </Collapse>
          </div>
        </div>
        {loading ? <RingLoading /> : null}
      </div>
    );
};

export default AchievementAdd;

import React from 'react'
import style from './style.module.scss';
import { DialogActions as ComponentMui} from '@mui/material';

const DialogActions = (props: any) => {
    const {children} = props;
    return (
            <ComponentMui {...props} className={style['DialogActions-Wrapper']}>
                {children}
            </ComponentMui>
    )
}

export default DialogActions;
import { BaseAction } from "../base.action";

export class SubscriptionAction extends BaseAction {
    constructor() {
        super("SUBSCRIPTION")
    }

    reloadData() {
        return {
            type: `${this.name}_RELOAD_DATA`
        };
    };
}



import AppBar from "@mui/material/AppBar";
import Toolbar from '@mui/material/Toolbar';
import { makeStyles, styled } from "@mui/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useLogoutMutation, useSharedQuery } from "services";
import './header.scss';
import { torem } from "styles/theme/toRem";
import UserAccount from "./UserAccount";

const drawerWidth = torem(250);

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${torem(30)}`
}));

const useStyles = makeStyles((theme: any) => {
    return {
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth})`,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    };
});

export const Header: React.FunctionComponent = (props: any) => {
    const {className} = props;
    const classes = useStyles();
    const history = useHistory();
    const [logout] = useLogoutMutation();

    const { data } = useSharedQuery();

    const processLogout = async () => {
        try {
            await logout();
            window.ClearAllLocalStorage();
            window.location.href='/';
        } catch (err) {
            window.location.reload();
        }
    };
    return (
        <AppBar position="absolute" id="header" className={classes.appBarShift}>
            <CustomToolbar className="customToolbar">
                <div className={`headerLogo`}>
                    <a className="pointer" href="/">
                        <img src="/images/admin/logo.png" alt="Aviron logo" />
                    </a>
                </div>
                <UserAccount
                    username={data?.currentUser?.username || "---"}
                    avatarSrc={data?.currentUser?.avatarUrl}
                    handleLogout={processLogout}
                    currentUser={data?.currentUser || {}}
                />
            </CustomToolbar>
        </AppBar>
    );
};

import React from "react";
import "./RingLoading.scss";

const RingLoading = () => {
    return (
        <div className="lds-ring-wrapper">
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export { RingLoading };

import React from 'react'
import style from './style.module.scss';
import { TableRow as ComponentMui, TableRowProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps {
    children?: any;
};
const TableRow = (props:ComponentProps | ComponentMuiProps) => {
    const {children} = props;
    return (
            <ComponentMui {...props} className={style['TableRow-Wraper']}>
                {children}
            </ComponentMui>
    )
}

export default TableRow;
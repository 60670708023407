import React from 'react'
import style from './style.module.scss';
import { FormHelperText as ComponentMui } from '@mui/material';
import Info from '@mui/icons-material/Info';
import { torem } from 'styles/theme/toRem';

interface ComponentProps {
    children?: any;
    otherProps?: any
    error?: any
};
const FormHelperText = (props:ComponentProps) => {
    const {children, otherProps, error} = props;
    return (
      <ComponentMui
        error
        className={style["FormHelperTextWraper"]}
        {...otherProps}
      >
        {children && (
          <div className={style["text-error"] + ' text-error'}>
            <div className={style["icon"]}>
              <Info />
            </div>
            <div className={style["text"]}>{children}</div>
          </div>
        )}
      </ComponentMui>
    );
}

export default FormHelperText;
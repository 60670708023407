import { toast } from "react-toastify";

export const successHandle = (message: any, type: "notification") => {
  switch (type) {
    case "notification":
      toast.success(message, { theme: "colored", autoClose: 3000 });
      break;
    default:
      toast.success(message, { theme: "colored" });
  }
  return;
};

import React from "react";
interface Iprops {
  className?: string
}
const ApproveIcon = ({className}: Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7617 6.16193L15.993 4.39453L8.33313 12.0553L4.20814 7.93028L2.44055 9.69787L8.33314 15.5905L17.7617 6.16193Z"
        fill="#00BC3C"
      />
    </svg>
  );
};

export default ApproveIcon;

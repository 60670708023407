import loadable from "@react-loadable/revised";
import ComponentLoading from "Components/ComponentLoading";
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@mui/material/styles";
import { ProtectedRoute } from "Containers";
import { AdminAppContextProvider } from "contexts";
import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createApolloClient } from "scripts/apps/apollo";
import { CONSTANTS } from "consts";
import "styles/fonts/fontello/css/fontello.css";
import "styles/scss/style.scss";
import { ROOT_FILE } from "../consts";
import App from "./App";
// import ForgotPassword from "./Screens/ForgotPassword/ForgotPassword";
import Login from "./Screens/Login/Login";
// import ResetPassword from "./Screens/ResetPassword/ResetPassword";
import theme from "../styles/theme";

const ForgotPassword = loadable({
  loader() {
    return import("./Screens/ForgotPassword/ForgotPassword");
  },
  loading: ComponentLoading,
});

const ResetPassword = loadable({
  loader() {
    return import("./Screens/ResetPassword/ResetPassword");
  },
  loading: ComponentLoading,
});

const apolloClient = createApolloClient();

class AdminApp extends React.Component {
  render() {
    return (
      <ApolloProvider client={apolloClient}>
        <AdminAppContextProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Switch>
                <ProtectedRoute
                  exact
                  path={CONSTANTS.NAVIGATION_URL.LOGIN}
                  component={Login}
                />
                <Route
                  exact
                  path={CONSTANTS.NAVIGATION_URL.FORGOT_PASSWORD}
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path={CONSTANTS.NAVIGATION_URL.RESET_PASSWORD}
                  component={ResetPassword}
                />
                <ProtectedRoute path={`${ROOT_FILE}/`} component={App} />
              </Switch>
            </BrowserRouter>
            <ToastContainer limit={1} />
          </ThemeProvider>
        </AdminAppContextProvider>
      </ApolloProvider>
    );
  }
}

export default AdminApp;

import { ENV } from "scripts/apps/environment";
import axios, { AxiosRequestConfig } from "axios"
const FormData = require('form-data');

export class spIconUploadAPI {
  async uploadSpIcon(
    file: File,
  ) {
    console.log('file', file)
    const data: any = {
      "module": "structure_programs",
      "fileName": file.name,
      "clientId": ENV.CLIENT_ID_UPLOAD,
      "secretKey": ENV.SECRET_KEY_UPLOAD
    }
    const requestConfig: AxiosRequestConfig = {
      url: ENV.UPLOAD_SERVICE_URL + '/upload/get-signed-url',
      method: "POST",
      data
    }
    const {data: uploadUrlData } = await axios(requestConfig);

    const url: string = uploadUrlData?.signedUrl;
    axios.put(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    return {thumbUrl: uploadUrlData?.url, name: file.name}
  }
}

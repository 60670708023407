import moment from "moment";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import { BaseReducer } from "../base";

class SubscriptionReducer extends BaseReducer {
    constructor() {
        super(
            "SUBSCRIPTION",
            {
                currentPage: 0,
                search: "",
                advancedFilter: {
                    state: {
                        value: "",
                        label: "All",
                    },
                    planType: {
                        value: "",
                        label: "All",
                    },
                    customerType: {
                        value: "",
                        label: "All",
                    },
                    startTime: moment().subtract(10, "years").format(),
                    endTime: moment().endOf("days").format(),
                },
                reloadData: new Date()
            },
            {
                reloadData: `SUBSCRIPTION_RELOAD_DATA`,
            }
        );
    }

    reducer = (state: any = this.initState, action: {
        type: string
        payload: any
    }) => {
        switch (action.type) {
            case this.actions.reloadData:
                state.reloadData = new Date();
                break;
            case this.actions.setState:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
        }

        return state
    }

}

const subscriptionReducer = new SubscriptionReducer();

export { subscriptionReducer };

export const ACCHIVEMENT_TYPE = [
  {
    key: 'game_play',
    label: 'Game Play'
  },
  {
    key: 'rank_in_game',
    label: 'Rank In Game'
  },
  {
    key: 'output',
    label: 'Output'
  },
  {
    key: 'meters',
    label: 'Meters'
  },
  {
    key: 'calorie',
    label: 'Calories'
  },
  {
    key: 'day_streak',
    label: 'Day Streak'
  },
  {
    key: 'week_streak',
    label: 'Week Streak'
  },
  {
    key: 'challenge_streak',
    label: 'Challenge Streak'
  },
  {
    key: 'workout_session',
    label: 'Workout Session'
  },
]

export const ACCHIVEMENT_OPERATOR_TYPE = [
  {
    key: 'and',
    label: 'And'
  },
  {
    key: 'or',
    label: 'Or'
  },
  {
    key: 'eq',
    label: 'Equal'
  },
  {
    key: 'neq',
    label: 'Not Equal'
  },
  {
    key: 'gt',
    label: 'Greater'
  },
  {
    key: 'gte',
    label: 'Greater or Equal'
  },
  {
    key: 'lt',
    label: 'Less'
  },
  {
    key: 'lte',
    label: 'Less or Equal'
  },
]

export const ACCHIVEMENT_INTERVAL_TYPE = [
  {
    key: 'year',
    label: 'Year'
  },
  {
    key: 'month',
    label: 'Month'
  },
  {
    key: 'day',
    label: 'Day'
  },
  {
    key: 'hour',
    label: 'Hour'
  },
  {
    key: 'minute',
    label: 'Minute'
  },
  {
    key: 'second',
    label: 'Second'
  },
]
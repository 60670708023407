import { Button, Grid, MenuItem, Select } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Main.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AppSelect from "Components/MonthlyChallenge/AppSelect";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import TargetTypeSelect from "Components/MonthlyChallenge/TargetTypeSelect";
import { torem } from "styles/theme/toRem";
import { ReactComponent as ExportIcon } from "Components/base/Icons/ExportIcon.svg";
import style from './Main.module.scss'
import { ACHIEVENT_STATUS } from "./AchivementStatus";

const Main: React.FunctionComponent = () => {
  const {
    propsTable,
    state,
    loading,
    handleAddPost,
    handleSearch,
    queryParameter,
    handleChangeSort,
    handleChangeType,
    handleChangeApp,
    handleExportCSV,
    handleChangeStatus,
  } = useMainHook();
  return (
    <div className={style["AchievementWrapper"]}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-title">Achievement Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          <Button
            variant="outlined"
            onClick={handleExportCSV}
            startIcon={<ExportIcon />}
            style={{ minWidth: torem(120), marginRight: torem(16) }}
          >
            Export CSV
          </Button>
          <Button
            variant="contained"
            onClick={handleAddPost}
            startIcon={<AddIcon />}
            style={{ minWidth: "6.250vw" }}
          >
            ADD ACHIVEMENT
          </Button>
        </Grid>
      </Grid>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: "Achievement List",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by name",
                  type: "text",
                }}
              />
            ),
            selectFilter: (
              <>
                <AppSelect
                  onChange={handleChangeApp}
                  value={queryParameter.appId}
                  hidePlaceholder={true}
                />
                <Select
                  displayEmpty
                  name="sort"
                  value={queryParameter.sort}
                  onChange={handleChangeSort}
                  fullWidth
                >
                  <MenuItem value={"createAsc"}>Sort by Create ASC</MenuItem>
                  <MenuItem value={"createDesc"}>Sort by Create DESC</MenuItem>
                  <MenuItem value={"updateAsc"}>Sort by Update ASC</MenuItem>
                  <MenuItem value={"updateDesc"}>Sort by Update DESC</MenuItem>
                </Select>
                <TargetTypeSelect
                  value={queryParameter.type}
                  onChange={handleChangeType}
                  otherProps={{
                    placeholder: "All Type",
                  }}
                />
                <Select
                  displayEmpty
                  name="status"
                  value={queryParameter.status}
                  onChange={handleChangeStatus}
                  fullWidth
                  placeholder="All Status"
                  style={{ width: '100%' }}
                >
                  <MenuItem value={ACHIEVENT_STATUS.active}>Public</MenuItem>
                  <MenuItem value={ACHIEVENT_STATUS.inactive}>Draft</MenuItem>
                </Select>
              </>
            ),
          }}
        />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Main;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { CONSTANTS } from "consts";
import StructuredProgramAdd from "./Add/Add";
import StructuredProgramDetail from "./Detail/Detail";
import Main from "./Main/Main";

const StructuredProgram: React.FunctionComponent = () => {
    return (
        <div>
            <Switch>
                <Route
                    path={CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM_ADD}
                    component={StructuredProgramAdd}
                    exact
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM_DETAIL}
                    component={StructuredProgramDetail}
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.STRUCTURED_PROGRAM}
                    component={Main}
                />
            </Switch>
        </div>
    );
};
export default StructuredProgram;

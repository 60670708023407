import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CONSTANTS } from "consts";
import {
  api,
  useSharedQuery,
  useAdminProductCategoryQuery,
  useAdminDeleteProductCategoryMutation,
  useAdminUpdateProductCategoryMutation,
} from "services";
import { errorHandle, successHandle } from "utils";
import Swal from "sweetalert2";
import { formSchema } from "../Add/formSchemal";
import { errorHandleWithFormik } from "utils/errorHandleWithFormik";
import TrashDelIcon from "Components/base/Icons/TrashDelIcon.svg";

const tabOptions: {
  value: string;
  label: string;
  name: string;
  active: number;
}[] = [
  {
    label: "Child Category",
    value: "childCategory",
    name: "childCategory",
    active: 0,
  },
  { label: "Products", value: "product", name: "product", active: 1 },
];

const useDetailHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const { data: userLogged } = useSharedQuery();

  const history = useHistory();
  const params: any = useParams();

  const [loading, setLoading] = useState(false);
  const [updateCategory] = useAdminUpdateProductCategoryMutation();
  const [deleteCategory] = useAdminDeleteProductCategoryMutation();

  const [state, setState] = React.useState<any>({
    thumb: null,
    selectedFile: null,
  });

  const {
    data: ProductCategory,
    refetch: refetchProductCategory,
    loading: loadingProductCategory,
  } = useAdminProductCategoryQuery({
    variables: {
      input: {
        id: Number(params?.id),
      },
    },
    fetchPolicy: 'no-cache',
  });
  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      categoryGroupId: "",
      sortOrder: 1,
      description: "",
      parentCategory: {
        id: 0,
        name: "",
      },
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: (values, formikBag) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values: any) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      text: `You won't be able to revert this!`,
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
    });
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);
    updateCategory({
      variables: {
        input: {
          id: values?.id,
          name: values?.name,
          description: values?.description,
          sortOrder: Number(values.sortOrder),
          categoryGroupId: values?.categoryGroupId,
          categoryParentId: values?.parentCategory?.id,
        },
      },
    })
      .then((res: any) => {
        toast.success("Update Product Category success",{ autoClose: 3000 });
        setLoading(false);
      })
      .catch((err: any) => {
        errorHandle(err, null, "updateCategory");
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (ProductCategory && ProductCategory.adminProductCategory) {
      const category = ProductCategory.adminProductCategory;
      formik.setValues({
        id: category?.id,
        name: category?.name,
        categoryGroupId: category?.categoryGroupId,
        sortOrder: category?.sortOrder,
        description: category?.description,
        parentCategory: category?.parentCategory,
      } as any);
    }
  }, [ProductCategory]);

  // React.useEffect(() => {
  //     if (formValues.id.length > 0) {
  //         formik.setValues(formValues);
  //     }
  // }, [formValues]);

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      imageUrl: TrashDelIcon,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);
    deleteCategory({
      variables: {
        input: {
          id: Number(params.id),
        },
      },
    })
      .then((res) => {
        successHandle("Delete Category success", "notification");
        // window.location.replace(CONSTANTS.NAVIGATION_URL.PRESS);
        history.push(CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY);
      })
      .catch((err) => {
        errorHandle(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setBack = () => {
    history.goBack();
  };

  const propsTable = {
    statusTab: {
      options: tabOptions,
      itemActive: tabOptions[0],
    },
  };

  return {
    loading: loadingProductCategory || loading,
    setBack,
    formik,
    handleDelete,
    propsTable,
    originId: Number(params.id),
  };
};

export default useDetailHook;

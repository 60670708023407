import { CONSTANTS } from 'consts';
import {
  GET_WORKOUT_LIST_REQUEST,
  GET_WORKOUT_LIST_SUCCESS,
  GET_WORKOUT_LIST_FAILED,
  GET_WORKOUT_DETAIL_REQUEST,
  GET_WORKOUT_DETAIL_SUCCESS,
  GET_WORKOUT_DETAIL_FAILED,
  CHANGE_PAGE_REQUEST,
  CHANGE_PAGE_SUCCESS,
  CHANGE_PAGE_FAILED,
  SELECT_CHART_OPTION,
} from 'contexts/types/workoutProfile';

import produce from 'immer';
const { WORKOUT_PARAMETER } = CONSTANTS;

const initialState: any = {
  currentSelect: {
    timeRange: null,
    chartOption: null,
    workoutItem: null
  },
  loading: {
    chart: false,
    history: false
  },
  metaData: {},
  workoutMetrics: {},
  workoutDetail: {},

  chartOptions: [],
  chartData: {},

  historyList: []
};

type actionType = {
  type: string,
  payload: any,
};
const reducer = produce((state, action: actionType) => {
  switch (action.type) {

    case GET_WORKOUT_LIST_REQUEST: {
      const { timeRange } = action.payload;
      state.loading.chart = true;
      state.loading.history = true;
      state.currentSelect = {
        timeRange: timeRange,
        workoutItem: null,
        chartOption: null,
      };
      return state;
    }

    case GET_WORKOUT_LIST_SUCCESS: {
      const {
        metaData,
        workoutMetrics,
        historyList
      } = action.payload;
      state.loading.chart = false;
      state.loading.history = false;
      state.metaData = metaData;
      state.workoutMetrics = workoutMetrics;
      state.historyList = historyList;
      state.workoutDetail = {};
      return state;
    };

    case GET_WORKOUT_LIST_FAILED: {
      state.loading.chart = false;
      state.loading.history = false;
      return state;
    }

    case GET_WORKOUT_DETAIL_REQUEST: {
      const { workoutItem } = action.payload;
      state.loading.chart = true;
      state.currentSelect.workoutItem = workoutItem;
      state.currentSelect = {
        ...state.currentSelect,
        workoutItem: workoutItem,
      };
      return state;
    };

    case GET_WORKOUT_DETAIL_SUCCESS: {
      const {
        chartData,
        chartOptions,
        workoutMetrics,
        workoutDetail
      } = action.payload;
      state.loading.chart = false;
      state.chartData = chartData;
      state.chartOptions = chartOptions;
      state.workoutMetrics = workoutMetrics;
      state.workoutDetail = workoutDetail;
      state.currentSelect = {
        ...state.currentSelect,
        chartOption: WORKOUT_PARAMETER.kDIndexKiloJoules
      };
      return state;
    };

    case GET_WORKOUT_DETAIL_FAILED: {
      state.loading.chart = false;
      return state;
    };

    case CHANGE_PAGE_REQUEST: {
      state.loading.history = true;
      return state;
    };

    case CHANGE_PAGE_SUCCESS: {
      const {
        metaData,
        historyList
      } = action.payload;
      state.loading.history = false;
      state.metaData = metaData;
      state.historyList = historyList;
      return state;
    };

    case CHANGE_PAGE_FAILED: {
      state.loading.history = false;
      return state;
    };


    case SELECT_CHART_OPTION: {
      const { chartOption } = action.payload;
      state.currentSelect.chartOption = chartOption;
      return state;
    };

    default:
      return state;
  }
}, initialState);

export const workoutReducer = { initialState, reducer }
import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#E03100',
    main: '#F5490F',
    light: '#F76D3F'
  },
  secondary: {
    contrastText: white,
    dark: '#3D1204',
    main: '#190702',
    light: '#7B2508'
  },
  success: {
    contrastText: white,
    dark: '#008129',
    main: '#00BC3C',
    light: '#80DE9E'
  },
  info: {
    contrastText: white,
    dark: '#1144C2',
    main: '#2F6BFF',
    light: '#82A6FF'
  },
  warning: {
    contrastText: white,
    dark: '#DC931F',
    main: '#F3A72E',
    light: '#F8CE8B'
  },
  error: {
    contrastText: white,
    dark: '#AF0725',
    main: '#E60A32',
    light: '#F38599'
  },
  default: {
    contrastText: white,
    dark: '#ccc',
    main: '#ccc',
    light: '#ccc'
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blueGrey[600],
    white: colors.white
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};

import React from "react";
import { Route, Switch } from "react-router-dom";
import { CONSTANTS } from "consts";
import Main from "./Main/Main";

const Press: React.FunctionComponent = () => {
    return (
        <div>
            <Switch>
                <Route path={CONSTANTS.NAVIGATION_URL.GAME_SETTING} component={Main} />
            </Switch>
        </div>
    );
};
export default Press;

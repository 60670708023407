import { parse } from 'json2csv'

export default async function exportCsv(fileName: string, array: any[]){
    const csv = parse(array)
    const csvContent = `data:text/csv;charset=utf-8,${"\uFEFF"}${csv}`
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link); 
    link.click();
}
import React from "react";
import style from "./style.module.scss";
import {
    DialogContent as ComponentMui,
    // DialogContentProps as ComponentMuiProps,
} from "@mui/material";

// interface ComponentProps extends ComponentMuiProps {
//     children?: any;
// }
//@ts-ignore
const DialogContent = (props: any) => {
    const { children } = props;
    return (
        <ComponentMui {...props} className={style["DialogContent-Wrapper"] + ' DialogContent-Wrapper'}>{children}</ComponentMui>
    );
};

export default DialogContent;

import React from "react";
import style from "./style.module.scss";
import {
    FormControlLabel as ComponentMui,
    FormControlLabelProps as ComponentMuiProps,
} from "@mui/material";

interface ComponentProps extends ComponentMuiProps {}
const FormControlLabel = (props: ComponentProps) => {
    return (
        <ComponentMui {...props} className={style["FormControlLabel-Wraper"]} />
    );
};

export default FormControlLabel;

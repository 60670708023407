import React, { useState } from "react";
import style from "./UserAccount.module.scss";
import { Avatar } from "Components/base";
import { ReactComponent as LogoutIcon } from "./Logout.svg";
import { ReactComponent as UserSettingIcon } from "./UserSetting.svg";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

interface UserAccoutProps {
    username?: string;
    avatarSrc?: any;
    handleLogout?: Function;
    currentUser?: any;
}
const UserAccount = ({
    username,
    avatarSrc,
    handleLogout,
    currentUser,
}: UserAccoutProps) => {
    const history = useHistory();
    const [openMenu, setOpenMenu] = useState(false);
    const handleClickLogout = () => {
        handleLogout && handleLogout();
        setTimeout(() => {
            setOpenMenu(false);
        }, 500);
    };
    const handleClickUserSetting = () => {
        history.push(
            `${CONSTANTS.NAVIGATION_URL.ADMIN_DETAIL}`.replace(
                ":adminId",
                currentUser?.id
            )
        );
    };
    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpenMenu(false);
            }}
        >
            <div
                className={style["UserAccount"]}
                onClick={() => {
                    setOpenMenu(!openMenu);
                }}
            >
                <div className={style["usernameWrapper"]}>
                    <div className={style["username"]}>Hello {username}</div>
                    <div className={style["avatar"]}>
                        <Avatar src={avatarSrc} />
                    </div>
                </div>
                {openMenu && (
                    <div className={style["menuDropdown"]}>
                        <div
                            className={style["item"]}
                            onClick={handleClickUserSetting}
                        >
                            <UserSettingIcon />
                            Account Profile
                        </div>
                        {/* @ts-ignore */}
                        <div
                            className={style["item"]}
                            onClick={handleClickLogout}
                        >
                            <LogoutIcon />
                            Logout
                        </div>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default UserAccount;

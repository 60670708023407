import React from 'react';
import style from './AchivementStatus.module.scss';
import StatusLabel from 'Components/StatusLabel';

interface AchivementStatus {
  status: string;
}

export const ACHIEVENT_STATUS = {
  active: 'active',
  inactive: 'inactive'
}

const getStatusOption = (status: any) => {
  if (status === ACHIEVENT_STATUS.active) {
    return {
      text: "Public",
      color: "0, 188, 60",
      colorBg: "rgb(230, 248, 236)",
    };
  } else if (status === ACHIEVENT_STATUS.inactive) {
    return {
      text: "Draft",
      color: "102, 102, 102",
      colorBg: "rgb(230, 230, 230)",
    };
  } else {
    return {
      text: "---",
      color: "102, 102, 102",
      colorBg: "rgb(230, 230, 230)",
    };
  }
};

const AchivementStatus = ({status}: AchivementStatus) => {
  return (
    <div className={style['ProductStatus']}>
      <StatusLabel {...getStatusOption(status)} />
    </div>
  )
}

export default AchivementStatus;
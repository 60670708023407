export const CATEGORY_GROUP = [
  {
    key: 'background',
    label: 'Background'
  },
  {
    key: 'character',
    label: 'Character'
  },
]

export const CATEGORY_TYPE = [
  {
    key: 'category1',
    label: 'Category Name 1'
  },
  {
    key: 'category2',
    label: 'Category Name 2'
  },
  {
    key: 'category3',
    label: 'Category Name 3'
  },
]

export const PRODUCT_STORE_TYPE = [
  {
    key: 10,
    label: 'AVIRON'
  },
];

export const STATUS_GAME_SHOP_PRODUCT = {
  active: 'active',
  inactive: 'inactive'
};
import { Button, Tooltip } from "@mui/material";
import { displayLimitText } from "utils/displayLimitText";
import React, { useState } from "react";
import style from './style.module.scss'

interface RootProps {
    text: string;
    limit?: number;
    more?: any;
    styles?: React.CSSProperties
}

const ShortTextDisplay = ({ text, limit = 30, more = "...", styles }: RootProps) => {
    const [openAlert, setOpentAlert] = useState(false);
    const coppyText = (event:any) => {
        event.stopPropagation();
        if(event.target.value === event.currentTarget.value && !openAlert) {
            navigator.clipboard.writeText(text)
            setOpentAlert(true);
            setTimeout(()=>{setOpentAlert(false)}, 800)
            return false
        }
    }
    return (
        <div>
            {text && text.length > limit ? (
                <Tooltip
                    title={
                        <div style={{ fontSize: 14, padding: '12px 10px' }} className={style['contentTooltip']}>
                            <div>{text}</div>
                            <div className={style['coppyWrapper']}>
                            <button
                                onClick={coppyText}
                                className={style['copyButton']}
                            >
                                {!openAlert ? 'Copy' : 'Copied'}
                            </button>
                            </div>
                        </div>
                    }
                    arrow={true}
                    placement="top-end"
                >
                    <div style={styles}>{displayLimitText(text, limit, more)}</div>
                </Tooltip>
            ) : (
                <div style={styles}>{text}</div>
            )}
        </div>
    );
};
export default ShortTextDisplay;

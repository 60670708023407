import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useAdminAchievementQuery,
  useCreateSupportMutation,
  useCreateUploadPhotoUrlMutation,
  useDeleteAchievementMutation,
  useUpdateAchievementMutation,
} from "services";
import { formSchema } from "./formSchemal";
import Swal from "sweetalert2";
import {
  formatConditionForm,
  formatConditionRequest,
} from "./formatData";
import { errorHandle } from "utils";
import TrashIcon from "Components/base/Icons/TrashDelIcon.svg";
import { formatTextEditorToJSONTxt } from "utils/formatTextEditorToJSONTxt";

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  // console.log('supportCategories', supportCategories)
  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();
  const [updateAchievement] = useUpdateAchievementMutation();
  const [deleteAchievement] = useDeleteAchievementMutation();
  const params = useParams();
  const {
    data: achievement,
    loading: achievementLoading,
    refetch: achievementRefetch,
  } = useAdminAchievementQuery({
    variables: {
      input: {
        //@ts-ignore
        achievementId: Number(params?.id),
        //@ts-ignore
        appId: Number(params?.appId),
      },
    },
    fetchPolicy: 'no-cache',
  });
  const [state, setState] = React.useState<any>({
    selectedCategoryId: null,
    thumb: null,
    selectedFile: null,
  });

  const conditionFormik = useRef<any>();
  const [initConditionFormik, setInitConditionFormik] = useState<any>(null);
  const formik = useFormik({
    initialValues: {
      name: "",
      app: "",
      type: "",
      description: "",
      explanation: "",
      icon: "",
      iconGray: "",
      status: "",
      metadata: {},
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      conditionFormik?.current?.submitForm();
    },
  });

  useEffect(() => {
    if (achievement) {
      const detail = achievement?.adminAchievement;
      formik.setValues({
        name: detail?.name,
        app: detail?.appId,
        type: detail?.type,
        description: detail?.description,
        explanation: detail?.explanation,
        iconGray: detail?.iconGrey,
        icon: detail?.icon,
        status: detail?.status || 'inactive',
        metadata: detail?.metadata || {}
      } as any);

      // set inital value for condition form
      const conditionForm = detail?.condition
        ? formatConditionForm(detail?.condition.exprs)
        : [];
      setInitConditionFormik({ lv1FormFields: conditionForm });
    }
  }, [achievement]);

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const uploadImage = async (file: any) => {
    const uploadUrlData = await createUploadUrl({
      variables: {
        input: {
          contentType: "image/png",
          name: file.name.slice(file.name, file.name.lastIndexOf(".")),
        },
      },
    });
    await api.util.uploadImage(
      get(uploadUrlData, "data.createUploadPhotoUrl.uploadUrl"),
      {
        key: get(uploadUrlData, "data.createUploadPhotoUrl.fields.key"),
        policy: get(uploadUrlData, "data.createUploadPhotoUrl.fields.policy"),
        "x-goog-algorithm": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_algorithm"
        ),
        "x-goog-credential": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_credential"
        ),
        "x-goog-signature": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_signature"
        ),
        "x-goog-date": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_date"
        ),
        "content-type": "image/png",
        acl: "public-read",
        file: file,
      }
    );
    return get(uploadUrlData, "data.createUploadPhotoUrl.publicUrl");
  };

  const handleUploadIconGray = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("iconGray", "");
    } else {
      formik.setFieldValue("iconGray", file);
    }
  };

  const handleUploadIcon = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("icon", "");
    } else {
      formik.setFieldValue("icon", file);
    }
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    // console.log({ formik, conditionFormik });
    if (formik.values.name.trim() !== "") {
      if (formik.isValid && conditionFormik?.current?.isValid) {
        setLoading(false);
        const result = await Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, save it!",
        });
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        const conditionRequest = await formatConditionRequest(
          conditionFormik?.current?.values?.lv1FormFields
        );
        const profileRequest = formik.values;
        const metadata = profileRequest?.metadata || {}
        // console.log({metadata});setLoading(false);
        updateAchievement({
          variables: {
            input: {
              //@ts-ignore
              achievementId: Number(params?.id),
              description: profileRequest.description,
              explanation: profileRequest.description,
              //@ts-ignore
              appId: Number(params?.appId),
              name: profileRequest.name,
              type: profileRequest.type,
              iconGrey: profileRequest?.iconGray,
              icon: profileRequest?.icon,
              condition: {
                exprs: conditionRequest,
              },
              metadata: metadata
            },
          },
        })
          .then((res) => {
            setLoading(false);
            toast.success("Update Achivement Success", {
              autoClose: 3000,
            });
          })
          .catch((err) => {
            errorHandle(err, formik);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (!formik.isValid) {
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else if (!conditionFormik?.current?.isValid) {
        const el = document.getElementById("conditionFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };
  const handleUnPublic = async () => {
    const result = await Swal.fire({
      title: "Unpublic Achievemnet?",
      icon: "warning",
      text: `When you unpublish this achievement, all user will not claim it`,
      showCancelButton: true,
      confirmButtonText: "Yes, Unpublic it!",
    });
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);
    updateAchievement({
      variables: {
        input: {
          //@ts-ignore
          achievementId: Number(params?.id),
          //@ts-ignore
          appId: Number(params?.appId),
          status: "inactive",
        },
      },
    })
    .then((res) => {
      setLoading(false);
      toast.success("UnPublic Achivement Success", {
        autoClose: 3000,
      });
      achievementRefetch();
    })
    .catch((err) => {
      errorHandle(err, formik);
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });
  }
  const handlePublic = async () => {
    const result = await Swal.fire({
      title: "Public Achievement?",
      icon: "warning",
      text: `When you publish this achievement, all user will not claim it`,
      showCancelButton: true,
      confirmButtonText: "Yes, Public it!",
    });
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);
    updateAchievement({
      variables: {
        input: {
          //@ts-ignore
          achievementId: Number(params?.id),
          //@ts-ignore
          appId: Number(params?.appId),
          status: "active",
        },
      },
    })
    .then((res) => {
      setLoading(false);
      toast.success("Public Achivement Success", {
        autoClose: 3000,
      });
      achievementRefetch();
    })
    .catch((err) => {
      errorHandle(err, formik);
      setLoading(false);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  
  const handleDeleteAchievement = async() => {
    //@ts-ignore
    const result = await Swal.fire({
      title: "Remove Achievement",
      html: `<div>
                Do you want to remove Achievement <br /><b>${formik.values.name}</b></div>`,
      showCancelButton: true,
      confirmButtonText: "Delete",
      customClass: "errorCustom",
      imageUrl: TrashIcon,
    });
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);
    deleteAchievement({
      variables: {
        input: {
          //@ts-ignore
          achievementId: Number(params?.id),
          //@ts-ignore
          appId: Number(params?.appId),
        },
      },
    })
      .then(() => {
        setLoading(false);
        toast.success("Delete Achievement success !", {
          autoClose: 3000,
        });
        setTimeout(() => {
          history.push(CONSTANTS.NAVIGATION_URL.ACHIEVEMENT);
        }, 500);
      })
      .catch((err) => {
        errorHandle(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return {
    loading: achievementLoading || loading,
    setBack,
    formik,
    handleUploadIconGray,
    handleUploadIcon,
    conditionFormik,
    handleSubmitForms,
    initConditionFormik,
    achievement,
    handleUnPublic,
    handlePublic,
    handleDeleteAchievement,
  };
};

export default useAddHook;

import { MenuItem, Select } from "Components/base";
import style from "./TargetTypeSelect.module.scss";
import React, { useEffect } from "react";
import { useAchievementTypesQuery } from "services";
import { CircularProgress } from "@mui/material";
import { torem } from "styles/theme/toRem";

interface TargetTypeSelectProps {
  onChange?: Function;
  value?: any;
  otherProps?: any;
}
const TargetTypeSelect = ({
  onChange,
  value,
  otherProps,
}: TargetTypeSelectProps) => {
  const {
    data: achievementTypes,
    loading: loadingAchievementTypes,
    refetch: refetchAchievementType,
  } = useAchievementTypesQuery();

  return (
    <div className={style["TargetTypeSelect"] + " TargetTypeSelect"}>
      <Select
        displayEmpty
        name="type"
        placeholder={"Select a Type"}
        value={value}
        onChange={onChange}
        fullWidth
        {...otherProps}
      >
        {achievementTypes?.adminAchievementTypes?.map((item: any, key: any) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>;
        })}
      </Select>
      {loadingAchievementTypes && (
        <div className={style["loading"]}>
          <CircularProgress size={torem(30)} />
        </div>
      )}
    </div>
  );
};

export default TargetTypeSelect;

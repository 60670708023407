import { BaseReducer } from "../base";

class DealerLocationReducer extends BaseReducer {
    constructor() {
        super(
            "DEALER_LOCATION",
            {
                search: "",
                currentPage: 0,
                advancedFilter: {
                    
                },
            },
            {}
        );
    }
}

export const dealerLocationReducer = new DealerLocationReducer();

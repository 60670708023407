import React from 'react'
import style from './style.module.scss';
import { TableCell as ComponentMui, TableCellProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps {
    children?: any;
};
const TableCell = (props:ComponentProps | ComponentMuiProps) => {
    const {children} = props;
    return (
            <ComponentMui {...props} className={style['TableCell-Wraper']}>
                {children}
            </ComponentMui>
    )
}

export default TableCell;
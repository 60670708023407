import { MenuItem, Select } from "Components/base";
import style from "./ParentProductCategorySelect.module.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  useAchievementTypesQuery,
  useAdminProductCategoriesQuery,
} from "services";
import { CircularProgress } from "@mui/material";
import { torem } from "styles/theme/toRem";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ClickAwayListener } from "@mui/base";
import { useParams } from "react-router-dom";

interface ParentProductCategorySelectProps {
  formik?: any;
  placeholder?: string;
  name?: string;
  menuProps?: any;
  onChange?: any;
  initValue?: any;
  originId?: any;
  ignoreProduct?: boolean;
  isChild?: boolean
}
const ParentProductCategorySelect = ({
  placeholder = "Select Parent Category",
  name = "parentCategory",
  formik,
  menuProps,
  onChange,
  initValue,
  originId,
  ignoreProduct,
  isChild,
}: ParentProductCategorySelectProps) => {
  const [query, setQuery] = useState("");
  const [activeItem, setActiveItem] = useState({
    id: 0,
    name: "",
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [openInput, setOpenInput] = useState({
    select: true,
    input: false,
  });
  const { data, loading, refetch } = useAdminProductCategoriesQuery({
    variables: {
      input: {
        name: "",
        page: 0,
        pageSize: 20,
        ignoreProduct: ignoreProduct || false,
        isChild: isChild || false
      },
    },
    fetchPolicy: 'no-cache',
  });
  const onChangeInputSearch = (event: any) => {
    setQuery(event.target.value);
  };
  const handleClickItem = (item: any) => {
    formik && formik.setFieldValue(name, {name: item.name, id: item.id});
    setActiveItem(item);
    setOpenMenu(false);
    onChange && onChange(name, item);
    setQuery(item.name);
    setOpenInput({
      input: false,
      select: true,
    });
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      refetch({
        input: {
          name: query,
          page: 0,
          pageSize: 20,
          ignoreProduct: ignoreProduct || false,
          isChild: isChild || false
        },
      });
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [query]);
  const inputRef = useRef<any>();
  const handleClickInputSelect = () => {
    setOpenInput({
      input: true,
      select: false,
    });
    setOpenMenu(true);
    setTimeout(() => {
      document.getElementById(name)?.focus();
    }, 500);
  };

  useEffect(()=>{
    if(initValue) {
      setActiveItem(initValue);
      setQuery(initValue.name)
    }
  }, [initValue])

  return (
    <div
      className={
        style["ParentProductCategorySelect"] + " ParentProductCategorySelect"
      }
    >
      <ClickAwayListener
        onClickAway={() => {
          setOpenMenu(false);
          setOpenInput({
            input: false,
            select: true,
          });
        }}
      >
        <div>
          <div className={style["inputSelect"]}>
            <div className={style["inputGroup"]}>
              {openInput?.select && (
                <div
                  className={style["inputResult"]}
                  onClick={handleClickInputSelect}
                >
                  {activeItem.name ? (
                    activeItem.name
                  ) : (
                    <div style={{ color: "#808080" }}>{placeholder}</div>
                  )}
                </div>
              )}
              {openInput?.input && (
                <input
                  name={name}
                  type="text"
                  value={query}
                  onChange={onChangeInputSearch}
                  placeholder={"Search..."}
                  onFocus={() => {
                    setOpenMenu(true);
                  }}
                  id={name}
                  ref={inputRef}
                />
              )}
            </div>
            <div className={style["iconGroup"]}>
              <div className={style["clearIcon"]}>
                {query !== "" && openInput.input && (
                  <div
                    onClick={() => {
                      setQuery("");
                      setTimeout(() => {
                        document.getElementById(name)?.focus();
                      }, 500);
                    }}
                  >
                    <ClearIcon />
                  </div>
                )}
              </div>
              <div
                className={style["expandIcon"]}
                onClick={handleClickInputSelect}
              >
                <ExpandMoreIcon />
              </div>
            </div>
          </div>
          {openMenu && (
            <div
              className={style["menu"]}
              style={{ width: menuProps?.width || undefined }}
            >
              <div
                style={{ color: "#808080" }}
                className={style["item"]}
                onClick={() => {
                  handleClickItem({ id: 0, name: "" });
                }}
              >
                {placeholder}
              </div>
              {data?.adminProductCategories?.data?.map(
                (item: any, key: any) => {
                  if (item.id !== originId) {
                    return (
                      <div
                        key={"categoryParentId" + key}
                        className={`${style["item"]} ${
                          activeItem?.id === item.id && style["itemActive"]
                        }`}
                        onClick={() => {
                          handleClickItem(item);
                        }}
                        id={name + item.id}
                      >
                        {item.name}
                      </div>
                    );
                  }
                }
              )}
              {/* {data?.adminProductCategories?.data?.length === 0 && (
                <i style={{ display: "flex", justifyContent: "center" }}>
                  No data
                </i>
              )} */}
              {loading && (
                <div className={style["loading"]}>
                  <CircularProgress size={torem(30)} />
                </div>
              )}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default ParentProductCategorySelect;

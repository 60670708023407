import { BaseReducer } from "../base";

class PostReducer extends BaseReducer {
    constructor() {
        super(
            "POST",
            {
                currentPage: 0,
                search: "",
                advancedFilter: {
                    category: {
                        value: "",
                        label: "All",
                    },
                    publicStatus: {
                        value: [true, false],
                        label: "All",
                    },
                    pinStatus: {
                        value: [true, false],
                        label: "All",
                    },
                },
            },
            {}
        );
    }
}

export const postReducer = new PostReducer();

import React, { useState } from "react";
import style from "./Collapse.module.scss";
import { ReactComponent as CollapseIcon } from "Components/base/Icons/ArrowDown.svg";
import TrashIcon from "Components/base/Icons/TrashIcon";
import IconAchievement from './iconAchievement.png';

interface CollapseProps {
    headerText: string;
    desc?: string
    defaultOpen?: boolean;
    children?: any;
    removeIconProps?: {
        action?: Function;
        text?: any;
    } | null;
}
const Collapse = ({
    children,
    headerText,
    defaultOpen,
    removeIconProps,
    desc,
}: CollapseProps) => {
    const [open, setOpen] = useState(defaultOpen);
    const handleClickCollapse = () => {
        setOpen(!open);
    };
    return (
        <div className={style["Collapse"] + " CollapseCard"}>
            <div className={open ? style["opened"] : ""}>
                <div className={style["header"] + " header"}>
                    <div className={style["leftHeader"]}>
                        {/* <img className={style['icon']} src={IconAchievement} /> */}
                        <div className={style['textWrap']}>
                            <div className={style['text']}>{headerText}</div>
                            <div className={style['desc']}>{desc}</div>
                        </div>
                    </div>
                    {removeIconProps && (
                        <div
                            className={style["removeCollaspe"]}
                            //@ts-ignore
                            onClick={removeIconProps?.action}
                        >
                          <TrashIcon />{removeIconProps?.text || "Remove Condition"}
                        </div>
                    )}
                    <div
                        className={style["iconCollapse"]}
                        onClick={handleClickCollapse}
                    >
                        <CollapseIcon />
                    </div>
                </div>
                <div
                    className={style["content"]}
                    style={{ display: open ? "block" : "none" }}
                >
                    <div
                        className={
                            style["childrenContent"] + " childrenContent"
                        }
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Collapse;

import { formatConditionRequest } from "../Detail/formatData";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useCreateAchievementMutation,
  useCreateUploadPhotoUrlMutation
} from "services";
import Swal from "sweetalert2";
import { errorHandle } from "utils";
import { formSchema } from "./formSchemal";
import { ACHIEVENT_STATUS } from "../Main/AchivementStatus";
import { isJsonValue } from "apollo-utilities";
import { formatTextEditorToJSONTxt } from "utils/formatTextEditorToJSONTxt";

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  // console.log('supportCategories', supportCategories)
  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const [createAchievement] = useCreateAchievementMutation();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();

  const [state, setState] = React.useState<any>({
    selectedCategoryId: null,
    thumb: null,
    selectedFile: null,
  });

  const conditionFormik = useRef<any>();
  const formik = useFormik({
    initialValues: {
      name: "",
      app: "",
      type: "",
      description: "",
      explanation: "",
      icon: "",
      iconGray: "",
      status: 'inactive',
      metadata: {}
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      conditionFormik?.current?.submitForm();
    },
  });

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const uploadImage = async (file: any) => {
    const uploadUrlData = await createUploadUrl({
      variables: {
        input: {
          contentType: "image/png",
          name: file.name.slice(file.name, file.name.lastIndexOf(".")),
        },
      },
    });
    await api.util.uploadImage(
      get(uploadUrlData, "data.createUploadPhotoUrl.uploadUrl"),
      {
        key: get(uploadUrlData, "data.createUploadPhotoUrl.fields.key"),
        policy: get(uploadUrlData, "data.createUploadPhotoUrl.fields.policy"),
        "x-goog-algorithm": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_algorithm"
        ),
        "x-goog-credential": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_credential"
        ),
        "x-goog-signature": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_signature"
        ),
        "x-goog-date": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_date"
        ),
        "content-type": "image/png",
        acl: "public-read",
        file: file,
      }
    );
    return get(uploadUrlData, "data.createUploadPhotoUrl.publicUrl");
  };

  const handleUploadIconGray = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("iconGray", "");
    } else {
      formik.setFieldValue("iconGray", file);
    }
  };

  const handleUploadIcon = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("icon", "");
    } else {
      formik.setFieldValue("icon", file);
    }
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    // console.log({ formik, conditionFormik });
    if (formik.values.name.trim() !== "") {
      if (
        formik.isValid &&
        conditionFormik?.current?.isValid
      ) {
        setLoading(false);
        const result = await Swal.fire({
          title: "Are you sure?",
          icon: "warning",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, save it!",
        });
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        const conditionRequest = await formatConditionRequest(
          conditionFormik?.current?.values?.lv1FormFields
        );
        const profileRequest = formik.values;
        const metadata = profileRequest?.metadata || {}
        createAchievement({
          variables: {
            input: {
              description: profileRequest?.description,
              explanation: profileRequest?.description,
              appId: Number(profileRequest?.app),
              name: profileRequest?.name,
              type: profileRequest?.type,
              iconGrey: profileRequest?.iconGray,
              icon: profileRequest?.icon,
              status: ACHIEVENT_STATUS.inactive,
              condition: {
                exprs: conditionRequest,
              },
              metadata: metadata
            },
          },
        }).then((res)=>{
          setLoading(false);
          toast.success("Create Achievement Success", { autoClose: 3000 });
          history.push(CONSTANTS.NAVIGATION_URL.ACHIEVEMENT)
        }).catch((err)=>{
          errorHandle(err, formik)
          setLoading(false)
        }).finally(()=>{
          setLoading(false);
        });
      } else if (!formik.isValid) {
        setLoading(false);
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        return;
      } else if (!conditionFormik?.current?.isValid) {
        const el = document.getElementById("conditionFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };

  return {
    loading,
    setBack,
    formik,
    handleUploadIconGray,
    handleUploadIcon,
    conditionFormik,
    handleSubmitForms
  };
};

export default useAddHook;

export const gameBetaUserConstant = [
  {
    numeric: false,
    disablePadding: false,
    label: "Username",
    id: "username",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Email",
    id: "email",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Join at",
    id: "created_at",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Action",
    id: "action",
    hasSub: false,
  },
];

import lodash from "lodash";

export const formatConditionForm = (exprs?: any) => {
  let lv1 = [];
  if (!lodash.isEmpty(exprs)) {
    const getLv2 = (exprs: any, lv2: any) => {
      for (let i = 0; i < exprs.length; i++) {
        let lv3 = [];
        if (exprs[i]?.interval) {
          let temp = {
            operator: exprs[i]?.interval?.type,
            operator_value: exprs[i]?.interval?.value,
          };
          lv3.push(temp);
        }
        lv2.push({
          type: exprs[i].type,
          operator: exprs[i].operator,
          operator_value: exprs[i].operator_value,
          lv3FormFields: lv3,
        });
      }
    };
    for (let i = 0; i < exprs.length; i++) {
      let lv2 = [];
      let lv3 = [];
      if (exprs[i]?.interval) {
        let temp = {
          operator: exprs[i]?.interval?.type,
          operator_value: exprs[i]?.interval?.value,
        };
        lv3.push(temp);
      }
      lv2.push({
        type: exprs[i].type,
        operator: exprs[i].operator,
        operator_value: exprs[i].operator_value,
        lv3FormFields: lv3,
      });
      lv1.push({
        lv2FormFields: lv2,
      });
      //@ts-ignore
      if (exprs[i]?.condition) {
        //@ts-ignore
        getLv2(exprs[i]?.condition?.exprs, lv2);
      }
    }
  }
  return lv1;
};

export const formatConditionRequest = async (condition: any) => {
  let exprs = [];

  for (let i = 0; i < condition.length; i++) {
    const lv2FormFields = condition[i].lv2FormFields;
    let exprsObj = {};
    for (let j = 0; j < lv2FormFields.length; j++) {
      const lv3FormFields = lv2FormFields[j]?.lv3FormFields || [];
      if (j === 0) {
        exprsObj = {
          operator: lv2FormFields[j].operator,
          operator_value: Number(lv2FormFields[j].operator_value),
          type: lv2FormFields[j].type,
          interval:
            lv3FormFields.length > 0
              ? {
                  type: lv3FormFields[0].operator,
                  value: Number(lv3FormFields[0].operator_value),
                }
              : undefined,
        };
      } else {
        //@ts-ignore
        exprsObj.condition = {
          exprs: [
            {
              operator: lv2FormFields[j].operator,
              operator_value: Number(lv2FormFields[j].operator_value),
              type: lv2FormFields[j].type,
              interval:
                lv3FormFields.length > 0
                  ? {
                      type: lv3FormFields[0].operator,
                      value: Number(lv3FormFields[0].operator_value),
                    }
                  : undefined,
            },
          ],
        };
      }
    }
    exprs.push(exprsObj);
  }
  return exprs;
};


import { useHistory } from "react-router-dom";
import { useGetBuffsQuery, useUpdateBuffsMutation, useDeleteBuffMutation } from "services/graphql/crm_server";
import * as React from "react";
import { toast } from "react-toastify";

import { ENV } from 'scripts/apps/environment'

const context = {
    clientName: ENV.CRM_SERVER_CONTEXT
  }

type buffType = {
    id: number;
    name: string;
    value_type: string;
    open: boolean;
}

const useBuffHook = () => {
    const history = useHistory();
    const [updateBuffs] = useUpdateBuffsMutation()
    const [deleteBuff] = useDeleteBuffMutation()
    const { data, loading } = useGetBuffsQuery({
        context
      });
      const [buffs, setBuffs] = React.useState([] as buffType[]);
    React.useMemo(() => {
        if (data && data.getBuffs) {
            setBuffs(data.getBuffs.map((e: any) => {
                return {
                    id: e.id,
                    name: e.name,
                    value_type: e.value_type,
                    open: false,
                }
            }))
        }
    }, [data]);

    const columns = [
        {
            numeric: false,
            disablePadding: false,
            label: "Id",
            id: "id",
            underline: true,
            hasSub: true,
        },
        {
            numeric: false,
            disablePadding: false,
            label: "Name",
            id: "name",
        },
        {
            numeric: false,
            disablePadding: false,
            label: "Value Type",
            id: "value_type",
        }
      ]

    const onChangeBuffName = (event: any, buff: any) => {
        const {value} = event.target
        setBuffs(buffs.map((item: any) => {
            if (item.id === buff.id) {
                item.name = value
            } 
            return item;
        })
      )
    }
    const onChangeValueType = (event: any, buff: any) => {
        const {value} = event.target
        setBuffs(buffs.map((item: any) => {
            if (item.id === buff.id) {
                item.value_type = value
            } 
            return item;
        })
      )

    }

    const handleClose = (event: any, id: any) => {
        setBuffs(buffs.map((x) => {
              const item: any = { ...x };
              if (item.id === id) {
                  item.open = item.open !== undefined ? !item.open : true;
              } else {
                  item.open = false;
              }
              return item;
          })
        )
      };

      const handleDelete = async (buff: any) => {
        const buffToDel = buffs.find(e => e.id == buff.id)
        const newBuff = buffs.filter(e => e.id != buff.id)
        setBuffs(newBuff)
        if (buffToDel) {
          await deleteBuff({
            variables: {
              id: buffToDel.id
            },
            context
          })
          toast.success("Delete game level success");
        }
      }

      const setBack = () => {
        history.goBack();
    };


    const handleAddMore = () => {
        const newBuffId = Math.max(...buffs.map(e => e.id)) + 1
      const newBuff = Object.assign({}, {
        id: newBuffId,
        name: "",
        value_type: "",
        open: false,
        })
      setBuffs([...buffs, newBuff])
    }
    const handleSave = async () => {
        console.log('handleSave', buffs)
        const buffsToSave = buffs.map(e => {
            return {
                id: e.id,
                name: e.name,
                value_type: e.value_type
            }
          })
        if (buffsToSave.every(item => 
            Object.values(item).some(value => !value)
          )) {
            toast.error("Some value are empty");
            return
          }
        await updateBuffs({
            variables: {
              data: buffsToSave
            },
            context
          })
          toast.success("Save game levels success");
    }

    return {
        loading,
        buffs,
        columns,
        onChangeBuffName,
        onChangeValueType,
        handleClose,
        handleDelete,
        setBack,
        handleAddMore,
        handleSave,
    };
};

export default useBuffHook;

import { useEffect, useState } from "react";
import { useAdminLoginMutation } from "services";
import { errorHandle } from "utils";
import validate from "validate.js";

const schema = {
    email: {
        presence: { allowEmpty: false, message: "is required" },
        email: true,
        length: {
            maximum: 64,
        },
    },
    password: {
        presence: { allowEmpty: false, message: "is required" },
        length: {
            maximum: 128,
        },
    },
};

const useLoginHook = () => {
    const [login] = useAdminLoginMutation();

    const [formState, setFormState] = useState<any>({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
    });
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState((formState: any) => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [formState.values]);

    const handleChange = (event: any) => {
        event.persist();
        setFormState((formState: any) => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === "checkbox"
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const handleSignIn = (event: any) => {
        event.preventDefault();
        setIsProcessing(true);
        login({
            variables: {
                username: formState.values.email,
                password: formState.values.password,
            },
            fetchPolicy: 'no-cache',
        })
            .then((result) => {
                // toast.success("Login success");
                window.location.href = "/";
            })
            .catch((err) => {
                errorHandle(err);
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    const hasError = (field: string) =>
        formState.touched[field] && formState.errors[field] ? true : false;

    return {
        formState,
        handleSignIn,
        hasError,
        handleChange,
        isProcessing,
    };
};

export default useLoginHook;

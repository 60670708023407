import { Button, Grid } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook, { useActiveSidebarBetaUser } from "./Main.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import { torem } from "styles/theme/toRem";
import AddBetaUserModal from "../Main/Add";
import { ReactComponent as ExportIcon } from "Components/base/Icons/ExportIcon.svg";
import { ReactComponent as RefreshIcon } from "Components/base/Icons/Refresh.svg";

const Main: React.FunctionComponent = () => {
  const {
    propsTable,
    loading,
    handleSearch,
    handleClickReset,
    openAddModal,
    setOpenAddModal,
    handleExportCsv,
    refetch,
  } = useMainHook();

  useActiveSidebarBetaUser();
  return (
    <div>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div className="aviron-title">Manage Beta User</div>
        </Grid>
        <Grid item xs={12} md={8} lg={9} className="buttonGroup">
          <Button
            className="btn-default"
            onClick={handleClickReset}
            startIcon={<RefreshIcon />}
            style={{ marginRight: torem(16) }}
          >
            RESET
          </Button>
          <Button
            variant="outlined"
            role="presentation"
            onClick={handleExportCsv}
            style={{ marginRight: torem(16) }}
            startIcon={<ExportIcon />}
          >
            export csv
          </Button>
          <Button
            variant="contained"
            role="presentation"
            onClick={() => {
              setOpenAddModal(true);
            }}
            startIcon={<AddIcon />}
          >
            Add user
          </Button>
        </Grid>
      </Grid>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: "Beta User List",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by username or email ...",
                  type: "text",
                }}
              />
            ),
          }}
        />
      </div>

      <AddBetaUserModal
        open={openAddModal}
        handleClose={() => {
          setOpenAddModal(false);
        }}
        refetchUserList={refetch}
      />
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Main;

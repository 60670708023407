import React from 'react';
import style from "./AddWrapper.module.scss";
const AddWrapper = (props: any) => {
    const {children} = props;
    return (
        <div className={style["AddWrapper"] + ' AddWrapper'}>
            <div className={style["formContetWrapper"] + ' formContetWrapper'}>
                {children}
            </div>
        </div>
    );
};

export default AddWrapper;

import React from 'react'
import style from './style.module.scss';
import { Typography as ComponentMui, TypographyProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps {
    children?: any;
    component?: any,
    className?: string,
};
const Typography = (props:ComponentProps | ComponentMuiProps) => {
    const {children, className} = props;
    return (
            <ComponentMui {...props} className={style['Typography-Wraper'] + ' ' + className}>
                {children}
            </ComponentMui>
    )
}

export default Typography;
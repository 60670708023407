import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import React from 'react';

const DatePicker = ({ name, key, label, fontSize, minDate, minDateMessage, maxDate, maxDateMessage, onChange, format, ...props }: any) => {
    // console.log('valueeeeee', props)
    const handleDateChange = (date: any) => {
        onChange && onChange(name, date);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label={label}
                value={props.value}
                onChange={handleDateChange}
                inputFormat={format || "MM/dd/yyyy"}
                renderInput={(params) => <TextField {...params} {...props} />}
                minDate={minDate}
                //@ts-ignore
                name={name}
                // minDateMessage={minDateMessage}
                maxDate={maxDate}
                // maxDateMessage={maxDateMessage}
            />
        </LocalizationProvider>
    )
}

export { DatePicker };

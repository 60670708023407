import React from 'react';

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
        width: window.innerHeight,
        heigth: window.innerWidth,
    });
    function handleResize() {
        setWindowSize({
            heigth: window.innerHeight,
            width: window.innerWidth
        })
    }
    // handleResize();
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    })
    return windowSize;
}
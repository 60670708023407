import React, { useEffect, useRef, useState, Suspense } from "react";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import style from "./ConditionForm.module.scss";
import AddIcon from "Components/base/Icons/AddIcon";
import { ReactComponent as CloseIcon } from "Components/base/Icons/CloseIcon2.svg";
import { MenuItem, Select, TextField } from "Components/base";
import InputPositiveNumber from "Components/base/InputPositiveNumber";
import { torem } from "styles/theme/toRem";
import { ACCHIVEMENT_INTERVAL_TYPE } from "consts/achivement";
import { RingLoading } from "Components/RingLoading/RingLoading";
import * as Yup from "yup";

interface Lv3FormProps {
  setParentValues?: any;
  parentValues?: any;
  parentIndex?: any;
  initValue?: any;
  disabled?: boolean;
}
const IntervalForm = (props: Lv3FormProps) => {
  const { parentValues, setParentValues, parentIndex, initValue, disabled } =
    props;
  const [lv3Formik, setLv1Formik] = useState({ intervals: null });
  useEffect(() => {
    if (lv3Formik) {
      let lv2FormFields = parentValues.lv2FormFields;
      lv2FormFields[parentIndex].lv3FormFields = lv3Formik.intervals;
      setParentValues({
        lv2FormFields,
      });
    }
  }, [lv3Formik]);
  return (
    <Suspense fallback={<RingLoading />}>
      <div className={style["haveFieldtree"]}>
        <Formik
          onSubmit={(values) => {
            // console.log("values", values);
          }}
          initialValues={{
            intervals: initValue || [],
          }}
          innerRef={(formikActions: any) => {
            formikActions && setLv1Formik(formikActions?.values);
          }}
        >
          {({ values, handleSubmit, errors, touched }) => (
            <Form>
              <FieldArray
                name="intervals"
                render={(arrayHelpers) => (
                  <ul>
                    {values.intervals.length > 0 &&
                      values.intervals.map((interval: any, index: any) => {
                        return (
                          <li key={index}>
                            <div className={style["labelTree"]}>Interval</div>
                            <div className={style["childTree"]}>
                              <div className={style["operatorWrapper"]}>
                                <div className={style["operator"]}>
                                  <div className={style["operatorSelect"]}>
                                    <Field
                                      name={`intervals.${index}.operator`}
                                      render={({ field }: any) => (
                                        <Select
                                          style={{
                                            width: torem(156),
                                          }}
                                          inputProps={{
                                            ...field,
                                            disabled,
                                          }}
                                        >
                                          {ACCHIVEMENT_INTERVAL_TYPE.map(
                                            (item, key) => {
                                              return (
                                                <MenuItem
                                                  key={key}
                                                  value={item.key}
                                                >
                                                  {item.label}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      )}
                                    />
                                  </div>
                                  <div className={style["operatorInput"]}>
                                    <Field
                                      name={`intervals.${index}.operator_value`}
                                      render={({ field }: any) => (
                                        <InputPositiveNumber
                                          style={{
                                            width: torem(156),
                                          }}
                                          inputProps={{
                                            ...field,
                                            disabled,
                                          }}
                                          // error={errors && errors?.intervals}
                                          // //@ts-ignore
                                          // helperText={errors?.intervals && errors?.intervals[index]?.operator_value}
                                        />
                                      )}
                                    />
                                    {/* <div>{JSON.stringify(errors?.intervals, null)}</div> */}
                                  </div>
                                </div>
                                {!disabled && (
                                  <div
                                    className={style["removeField"]}
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <CloseIcon />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    {values.intervals.length === 0 &&
                      parentIndex === 0 &&
                      !disabled && (
                        <li>
                          <div
                            className={style["addButtonText"]}
                            onClick={() =>
                              arrayHelpers.push({
                                operator: "day",
                                operator_value: 0,
                              })
                            }
                          >
                            <AddIcon />
                            Add Interval
                          </div>
                        </li>
                      )}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </ul>
                )}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Suspense>
  );
};

export default IntervalForm;

import Button from "Components/base/Button";
import React from "react";

export const ButtonWithLoading: React.FunctionComponent<any> = ({
    loading,
    children,
    ...rest
}) => {
    return (
        <Button {...rest}>
            {loading ? (
                <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
                children
            )}
        </Button>
    );
};

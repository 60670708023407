import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";

import React from "react";
import { useLocation } from "react-router-dom";

interface ParamListConfig {
  page?: number | string;
  offset?: number | string;
  first?: number | string;
  startTime?: string;
  endTime?: string;
  search?: string;
  sort?: string;
  tablename?: string;
  planId?: string;
  appId?: string;
  orderBy?: string;
  state?: string;
  cancelBy?: string;
  productType?: string;
  interval?: string;
  type?: string;
  machineType?: string;
  planName?: string;
  planProductType?: string;
  customerType?: string;
  planType?: string;
  status?: string;
  label?: string[] | string;
  admin?: string;
  paymentMethod?: string,
  model?: string
  membershipType?: string
}
export type QueryConfig = {
  [key in keyof ParamListConfig]: string;
};

const useQueryParams = () => {
  const { search } = useLocation();

  const value = React.useMemo(() => new URLSearchParams(search), [search]);

  const queryConfig: ParamListConfig = omitBy(
    {
      page: Number(value.get("page")),
      offset: Number(value.get("offset")),
      first: Number(value.get("first")),
      startTime: value.get("startTime"),
      endTime: value.get("endTime"),
      search: value.get("search"),
      sort: value.get("sort"),
      tablename: value.get("tablename"),
      planId: value.get("planId"),
      appId: value.get("appId"),
      orderBy: value.get("orderBy"),
      state: value.get("state"),
      cancelBy: value.get("cancelBy"),
      productType: value.get("productType"),
      interval: value.get("interval"),
      customerType: value.get("customerType"),
      type: value.get("type"),
      machineType: value.get("machineType"),
      planName: value.get("planName"),
      planProductType: value.get("planProductType"),
      status: value.get("status"),
      label: value.get("label"),
      admin: value.get("admin"),
      paymentMethod: value.get("paymentMethod"),
      model: value.get("model"),
      membershipType: value.get("membershipType"),
    },
    isNil
  );

  return queryConfig;
};
export default useQueryParams;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { CONSTANTS } from "consts";
import Add from "./Add/Add";
import Detail from "./Detail/Detail";
import Main from "./Main/Main";

const GameShopProduct: React.FunctionComponent = () => {
    return (
        <div>
            <Switch>
                <Route
                    path={CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT_ADD}
                    component={Add}
                    exact
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT_DETAIL}
                    component={Detail}
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}
                    component={Main}
                />
            </Switch>
        </div>
    );
};
export default GameShopProduct;

import React from "react";
import StatusLabel from "Components/StatusLabel";
import { BETA_REQUEST_STATUS_COLOR } from "./GameBetaRequest.constant";
export interface StatusProps {
  status: "" | "accepted" | "waiting" | "rejected" | "canceled";
}

const BetaRequestStatusLabel = ({ status }: StatusProps) => {
  const getStatus = () => {
    switch (status) {
      case "accepted":
        return BETA_REQUEST_STATUS_COLOR.accepted;
      case "waiting":
        return BETA_REQUEST_STATUS_COLOR.waiting;
      case "rejected":
        return BETA_REQUEST_STATUS_COLOR.rejected;
      case "canceled":
        return BETA_REQUEST_STATUS_COLOR.canceled;
      default:
        return {
          text: "---",
          color: "102, 102, 102",
          colorBg: "#E6E6E6",
        };
    }
  };
  const statusObj = getStatus();
  return (
    <div>
      <StatusLabel
        text={statusObj.text}
        color={statusObj.color}
        colorBg={statusObj.colorBg}
      />
    </div>
  );
};

export default BetaRequestStatusLabel;

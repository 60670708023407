import React from 'react'
import style from './style.module.scss';
import { DialogContentText as ComponentMui, DialogContentTextProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps extends ComponentMuiProps {
    children?: any;
};
const DialogContent = (props:ComponentProps) => {
    const {children} = props;
    return (
            <ComponentMui {...props} className={style['DialogContentText-Wraper']}>
                {children}
            </ComponentMui>
    )
}

export default DialogContent;
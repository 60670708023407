import React, { useEffect, useState } from 'react';
import style from './DetaiTabs.module.scss';
import Tabs from 'Components/base/Tabs';
import ChildCategory from "../ChildCategory/ChildCategory";
import Products from "../Products/Products";
import { useLocation } from 'react-router-dom';
const DetaiTabs = (props : any) => {
  const {isHideTab} = props;
  const tabOptions = [
    { active: 0, value: <ChildCategory />, label: "Child Category" },
    { active: 1, value: <Products />, label: "Products" },
];
const location = useLocation();
const search = location.search;
const searchParams = new URLSearchParams(search);
  const [activeTab, setActiveTab] = useState(searchParams.get('tab')==='childCategory' ? tabOptions[0]: tabOptions[1]);
  const handleChangeTab = (newActive: any) => {
    setActiveTab(newActive);
  }
  return (
      <div className={style["DetaiTabs"]} id={"tabWrapper"}>
          <Tabs
              options={tabOptions}
              defaultActive={activeTab}
              handleChangeTab={handleChangeTab}
          />
          <div className={style["bodyTab"]}>{activeTab.value}</div>
      </div>
  );
}

export default DetaiTabs;
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Checkbox,
} from "Components/base";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./Detail.hook";
import { torem } from "styles/theme/toRem";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import style from "./AddPost.module.scss";
import SPItem from "../../../../Components/StructuredProgram/Item";
import lodash from 'lodash';
import { RingLoading } from "Components/RingLoading/RingLoading";
import { DatePicker } from "Components/DatePicker/DatePicker";
import AddIcon from "Components/base/Icons/AddIcon";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as EditIcon }  from "Components/base/Icons/EditIcon.svg";
import { ImageUpload } from "Components/ImageUpload/ImageUpload";

type ItemProps = {
  key: number;
  name: string;
  gameId: string;
  gameType: number;
  desc: string;
  requirements: any;
  dueDate: number;
  thumbnail: string;
  thumbnailUrl: string;
}

const difficulties = ["EASY", "MEDIUM", "HARD", "PAIN"]

const StructuredProgramDetail = () => {
    const {
        loading,
        setBack,
        formik,
        conditionFormik,
        handleSubmitForms,
        addMoreProgram,
        gameInfos,
        requirements,
        onAddProgram,
        onDelProgram,
        onEditProgram,
        state,
        handleClose,
        changeImage,
    } = useAddHook();

    return (
      <div className={style["AddSPWrapper"]}>
        <Grid
          container
          spacing={torem(16)}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item>
            <div className="aviron-sub-title pointer" onClick={setBack}>
              Structured Program Management &gt; Add Structured Program
            </div>
            <div className="aviron-title" role="presentation">
              &nbsp; Add Structured Program
            </div>
          </Grid>
          <Grid item className={"buttonGroup"}>
            <ButtonWithLoading
              variant="contained"
              loading={loading}
              type="submit"
              onClick={handleSubmitForms}
              startIcon={<SaveIcon />}
              style={{ minWidth: torem(120) }}
            >
              Save
            </ButtonWithLoading>
          </Grid>
        </Grid>
        <div className={style["addBody"]}>
          {/* {!lodash.isEmpty(formik.errors) && (
            <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
              <FormHelperText>Check error in form</FormHelperText>
            </div>
          )} */}
          <div className={style["main-sp"]}>
            <Grid
              container
              spacing={torem(16)}
              className="inputSelectFormGroup"
            >
              <Grid item xs={12}>
                {/* <Grid item xs={6}>
                  <img src={formik.values.thumbnailUrl} alt="Icon" style={{marginBottom: '15px'}} />
                </Grid> */}
                <Grid item xs={12}>
                  <FormControl fullWidth className="form-aviron">
                    <ImageUpload
                      label="Game Thumbnail"
                      required={true}
                      //@ts-ignore
                      thumbValue={formik.values.thumbnailUrl}
                      onChangeValue={formik.handleChange}
                      setFieldValue={formik.setFieldValue}
                      nameFieldForm="iconGray"
                      isHideUploadFile
                      defaultValue={formik.values.thumbnailUrl}
                      multi={false}
                      changeImage={changeImage}
                    />
                    <div style={{ marginBottom: '20px' }}>
                      <FormHelperText>
                        Image use same path for Production and Development. Please check carefully the image name, it will replace current once if exists.<br/>
                        Please use SP id as image name !!!
                      </FormHelperText>
                    </div>
                  </FormControl>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="spId"
                      label="ID"
                      placeholder="Enter SP Id"
                      fullWidth
                      name="spId"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.spId ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spId}
                      helperText={
                        // @ts-ignore
                        formik.errors.spId && formik.touched.spId
                          ? formik.errors.spId
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="title"
                    label="Title"
                    placeholder="Enter title"
                    fullWidth
                    name="title"
                    margin="normal"
                    variant="outlined"
                    error={formik.errors.title ? true : false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    helperText={
                      // @ts-ignore
                      formik.errors.title && formik.touched.title
                        ? formik.errors.title
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="description"
                    label="description"
                    placeholder="Enter description"
                    fullWidth
                    name="description"
                    margin="normal"
                    variant="outlined"
                    error={formik.errors.description ? true : false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    helperText={
                      // @ts-ignore
                      formik.errors.description && formik.touched.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    multiline
                    id="longDesc"
                    label="longDesc"
                    placeholder="Enter longDesc"
                    fullWidth
                    name="longDesc"
                    margin="normal"
                    variant="outlined"
                    error={formik.errors.longDesc ? true : false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.longDesc}
                    helperText={
                      // @ts-ignore
                      formik.errors.longDesc && formik.touched.longDesc
                        ? formik.errors.longDesc
                        : null
                    }
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className="form-aviron"
                  style={{ marginTop: torem(16) }}
                >
                  <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    required
                  >
                    Difficulty
                  </InputLabel>
                  <Select
                    name="difficulty"
                    value={formik.values?.difficulty}
                    onChange={formik.handleChange}
                    fullWidth
                  >
                    {difficulties.map(e => (<MenuItem value={e}>{e}</MenuItem>))}
                      
                  </Select>
                  <FormHelperText error={formik.errors.difficulty ? true : false}>
                    {formik.errors.difficulty && formik.touched.difficulty
                      ? formik.errors.difficulty
                      : null}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="length"
                    label="length"
                    placeholder="Enter length"
                    fullWidth
                    name="length"
                    margin="normal"
                    variant="outlined"
                    error={formik.errors.length ? true : false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.length}
                    helperText={
                      // @ts-ignore
                      formik.errors.length && formik.touched.length
                        ? formik.errors.length
                        : null
                    }
                  />
                </FormControl>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="intensity"
                    label="intensity"
                    placeholder="Enter intensity"
                    fullWidth
                    name="intensity"
                    margin="normal"
                    variant="outlined"
                    error={formik.errors.intensity ? true : false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.intensity}
                    helperText={
                      // @ts-ignore
                      formik.errors.intensity && formik.touched.intensity
                        ? formik.errors.intensity
                        : null
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={torem(16)}>
              <Grid item xs={6}>
                <FormControl fullWidth className="form-aviron select">
                  <DatePicker
                    label="Start Time"
                    value={formik.values.startTime}
                    name="startTime"
                    onChange={formik.handleChange}
                    maxDate={
                      formik.values.endTime
                            ? formik.values.endTime
                            : undefined
                    }
                    maxDateMessage={
                        <span>
                            start must be before end
                        </span>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className="form-aviron select">
                  <DatePicker
                    label="End Time"
                    value={formik.values.endTime}
                    name="endTime"
                    onChange={formik.handleChange}
                    minDate={
                      formik.values.startTime
                            ? formik.values.startTime
                            : undefined
                    }
                    minDateMessage={
                        <span>
                            start must be before end
                        </span>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={torem(16)}>
              <Grid item xs={6}>
                <FormControl fullWidth className="form-aviron">
                  <Checkbox
                    checked={formik.values.hasStreaming}
                    name="hasStreaming"
                    value={formik.values.hasStreaming}
                    label="Has Streaming"
                    inputProps={{
                        "aria-label":
                            "primary checkbox",
                    }}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth className="form-aviron">
                  <Checkbox
                      checked={formik.values.isSuggest}
                      name="isSuggest"
                      value={formik.values.isSuggest}
                      label="Is Suggest"
                      inputProps={{
                          "aria-label":
                              "primary checkbox",
                      }}
                      onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="tag"
                  label="tag"
                  placeholder="Enter tag"
                  fullWidth
                  name="tag"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.tag ? true : false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tag}
                  helperText={
                    // @ts-ignore
                    formik.errors.tag && formik.touched.tag
                      ? formik.errors.tag
                      : null
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  multiline
                  id="note"
                  label="note"
                  placeholder="Enter note"
                  fullWidth
                  name="note"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.note ? true : false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.note}
                  helperText={
                    // @ts-ignore
                    formik.errors.note && formik.touched.note
                      ? formik.errors.note
                      : null
                  }
                />
              </FormControl>
            </Grid>
          </div>
          <div className={style["programs"]}>
            <div className={style["simple-program"] + ' ' + style["header"]}>
              <div className={style["program-key"]}><b>Key</b></div>
              <div className={style["program-thumb"]}><b>Thumb</b></div>
              <div className={style["program-name"]}><b>Name</b></div>
              <div className={style["program-cond"]}><b>Condition</b></div>
              <div className={style["program-action"]}><b>Action</b></div>
            </div>
            {formik.values.items.map((el: ItemProps, idx) => {
              return (
                <div className={style["simple-program"]}>
                  <div className={style["program-key"]}>{el.key}</div>
                  <div className={style["program-thumb"]}><img src={el.thumbnailUrl} alt="Icon" style={{width: '40px'}} /></div>
                  <div className={style["program-name"]}>{el.name}</div>
                  <div className={style["program-cond"]}>{Object.keys(el.requirements)?.map((req, idx) => {
                    return (<div><span>{req}</span>: &nbsp;<span>{el.requirements[req]}</span></div>)
                  })}</div>
                  <div className={style["program-action"]}>
                    <div className={style["edit"]} onClick={() => onEditProgram(el)}><EditIcon/></div>
                    <div onClick={() => onDelProgram(el)}><TrashIcon/></div>
                  </div>
                </div>
              )
            })}
            <div className={ style["addGroupConditionButton"] }>
              <div className={style["addButtonText"]} onClick={addMoreProgram}>
                <AddIcon />
                Add Program
              </div>  
            </div>  
          </div>
        </div>
        {loading ? <RingLoading /> : null}
        {<SPItem item={state.item} gameInfos={gameInfos} requirements={requirements} onPushOut={onAddProgram} onDelProgram={onDelProgram} open={state.open} handleClose={handleClose} />}
      </div>
    );
};

export default StructuredProgramDetail;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { CONSTANTS } from "consts";
import AchievementAdd from "./Add/Add";
import AchievementDetail from "./Detail/Detail";
import Main from "./Main/Main";

const Achievement: React.FunctionComponent = () => {
    return (
        <div>
            <Switch>
                <Route
                    path={CONSTANTS.NAVIGATION_URL.ACHIEVEMENT_ADD}
                    component={AchievementAdd}
                    exact
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.ACHIEVEMENT_DETAIL}
                    component={AchievementDetail}
                />
                <Route
                    path={CONSTANTS.NAVIGATION_URL.ACHIEVEMENT}
                    component={Main}
                />
            </Switch>
        </div>
    );
};
export default Achievement;

import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Input,
    Select,
    Button,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useBuffHook from "./Buff.hook";

export const Buff = () => {
    const {
        loading,
        buffs,
        columns,
        onChangeBuffName,
        onChangeValueType,
        handleClose,
        handleDelete,
        setBack,
        handleAddMore,
        handleSave,
    } = useBuffHook();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    return loading ? (<RingLoading />) : (
        <div>
            <Grid item xs={12} lg={12}>
                <div
                    className="aviron-title pointer"
                    role="presentation"
                    onClick={setBack}
                >
                    <i className="icon-left-open-big" />
                    &nbsp; Game Levels 
                </div>
            </Grid>
            <div className="aviron-title">Buff Types management</div>
            <div className="table">
                <Table className="video-list-table custom-table-element" style = {{width: 700}}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    key={`columns-${column.id}`}
                                    padding={column.disablePadding ? "none" : "normal"}
                                >
                                            {column.label}
                                </TableCell>
                            ))}
                            <TableCell key="action">
                                <span className="column-no">Actions</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        buffs.map(el => {
                            return (
                                <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${el.id}`}>
                                    <TableCell>
                                        {el.id}
                                    </TableCell>
                                    <TableCell>
                                        <Input value={el.name} onChange={(event) => onChangeBuffName(event, el)}  style = {{width: 360, marginRight: 10}} />
                                    </TableCell>
                                    <TableCell>
                                        <Input value={el.value_type} onChange={(event) => onChangeValueType(event, el)}  style = {{width: 260, marginRight: 10}} />
                                    </TableCell>
                                    <TableCell>
                                    <div className="position-relative">
                                            <button
                                                aria-controls={`action-menu-${el.id}`}
                                                aria-haspopup="true"
                                                aria-describedby={el.id.toString()}
                                                className="actions-table pointer"
                                                // variant="contained"
                                                role="presentation"
                                                onClick={(event) => {
                                                    
                                                    setAnchorEl(
                                                        event.currentTarget
                                                    );
                                                    handleClose(
                                                        event,
                                                        el.id
                                                    );
                                                }}
                                                data-cy="cms-table-action-btn"
                                            >
                                                <i className="icon-ic_more_vert" />
                                            </button>
                                            <Menu
                                                id={`action-menu-${el.id}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                //open={Boolean(anchorEl)}
                                                open={el.open}
                                                onClose={(event) => {
                                                    setAnchorEl(null);
                                                    handleClose(event, el.id);
                                                }}
                                                data-cy="cms-table-action-menu"
                                            >
                                                <MenuItem
                                                    className="actions-table-delete pointer"
                                                    role="presentation"
                                                    onBlur={(event) =>
                                                        handleClose(
                                                            event,
                                                            el.id
                                                        )
                                                    }
                                                    onClick={(event) => handleDelete(el)}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </div>
            <Grid
                item
                xs={12}
                md={6}
                lg={8}
            >       
                <Button
                    variant="contained"
                    onClick={(event) => handleAddMore()}
                    color="secondary"
                    className="btn-primary"
                    data-cy="add-machine-btn"
                >
                    <i className="icon-e-add" /> ADD BUFF
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    color="secondary"
                    className="btn-secondary"
                    data-cy="add-machine-btn"
                >
                    <i className="icon-secondary" /> SAVE
                </Button>
            </Grid>
        </div>
    );
};

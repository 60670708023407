import { Button, Grid, MenuItem, Select } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./ChildCategory.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import { CATEGORY_GROUP, CATEGORY_TYPE } from "consts/gameShop";
import GroupCategory from "Components/GameShopProduct/GroupCategorySelect";

interface Props {
  tabs?: any;
}
const ChildCategory = (props: Props) => {
  const {
    propsTable,
    state,
    loading,
    handleSearch,
    handleChangeGroup,
    queryParameter,
  } = useMainHook();
  return (
    <div>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: " ",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by name",
                  type: "text",
                }}
              />
            ),
            selectFilter: (
              <>
                <GroupCategory
                  value={queryParameter.categoryGroupId}
                  onChange={handleChangeGroup}
                />
              </>
            ),
          }}
        />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default ChildCategory;

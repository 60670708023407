import lodash from "lodash";
import { parseStringToBoolean } from "utils/parseStringToBoolean";

export const formatAppForm = (data: any) => {
  let res = [];
  if (!lodash.isEmpty(data)) {
    for (let i = 0; i < data.length; i++) {
      res.push({
        app: data[i].appId,
        order: data[i].sortOrder,
        clientIndex: data[i].clientIndex,
        new: data[i].isNew,
        gameType: data[i]?.gameConfig?.gameType
      });
    }
  } else {
    return [];
  }
  return res;
};

export const formatAppRequest = (data: any) => {
  // console.log('appData', data);
  let res = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    res.push({
      appId: Number(item?.app),
      sortOrder: Number(item?.order),
      clientIndex: Number(item?.clientIndex),
      isNew: parseStringToBoolean(item?.new),
      gameConfig: {
        gameType: item?.gameType
      }
    });
  }
  // console.log('res', res)
  return res;
}
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";
import React from "react";
import style from "./PaginationConrolled.module.scss";
import PaginationItem from "@mui/material/PaginationItem";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
    root: {
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
        float: "right",
    },
}));

const PaginationControlled: React.FunctionComponent<{
    handleChangePage: Function;
    count: number;
    currentPage: number;
}> = ({ handleChangePage, count, currentPage }) => {
    const classes = useStyles();
    const handleChange = (event: any, value: number) => {
        handleChangePage(value - 1);
    };

    return (
        <div
            className={
                classes.root + " " + style["PaginationControlledWrapper"]
            }
        >
            <Pagination
                count={count}
                page={currentPage + 1}
                onChange={handleChange}
                showFirstButton={true}
                showLastButton={true}
                hideNextButton={true}
                hidePrevButton={true}
                color="primary"
                renderItem={(item) => {
                    const { page } = item;
                    if (String(page).length > 3) {
                        return (
                            <Tooltip title={item?.page + ""} arrow>
                                <PaginationItem {...item} />
                            </Tooltip>
                        );
                    }
                    return <PaginationItem {...item} />;
                }}
            />
        </div>
    );
};

export default PaginationControlled;

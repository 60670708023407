import * as yup from "yup";

export const formSchema = yup.object().shape({
    name: yup.string().max(255).required('Name is a required field').trim(),
    description: yup.string().max(255).required('Description is a required field').trim(),
    price: yup.number().typeError("Price must be a number").required('Price is a required field').max(2000000),
    currency: yup.string().required('Currency is a required field').trim(),
    category: yup.string().required('Category is a required field').trim(),
    // gameType: yup.string().required('Game Type is a required field').trim(),
    store: yup.string().required('Store is a required field'),
    icon: yup.string(),
});
export const stateOptions: {
  value: string | number;
  label: string;
  active: any;
  pauseStatus?: any;
}[] = [
  { value: "", label: "All", active: 0, pauseStatus: [true, false] },
  { value: "accepted", label: "Accepted", active: 1, pauseStatus: [false] },
  { value: "waiting", label: "Waiting", active: 2, pauseStatus: [false] },
  { value: "rejected", label: "Rejected", active: 3, pauseStatus: [false] },
  { value: "canceled", label: "Canceled", active: 4, pauseStatus: [false] },
];

export const BetaRequestColumns = [
  {
    numeric: false,
    disablePadding: false,
    label: "Username",
    id: "username",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Email",
    id: "email",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Serial Number",
    id: "machineId",
    hasSub: false,
    className: "cmsTable_keyCell",
  },

  {
    numeric: false,
    disablePadding: false,
    label: "Workouts/Week",
    id: "workoutPerWeek",
    hasSub: false,
    className: "cmsTable_keyCell",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Discord",
    id: "discord",
    hasSub: false,
    className: "cmsTable_keyCell",
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Request Time",
    id: "created_at",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Update Time",
    id: "updated_at",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Status",
    id: "status_tab",
    hasSub: false,
  },
  {
    numeric: false,
    disablePadding: false,
    label: "Actions",
    id: "action",
    hasSub: false,
  },
];

export const selectOptionsFilterDate: {
  value: string;
  label: string;
  name: string;
}[] = [
  { label: "Created At DESC", value: "CREATED_AT_DESC", name: "orderBy" },
  { label: "Created At ASC", value: "CREATED_AT_ASC", name: "orderBy" },
  { label: "Updated At ASC", value: "UPDATED_AT_ASC", name: "orderBy" },
  { label: "Updated At DESC", value: "UPDATED_AT_DESC", name: "orderBy" },
];

export const BETA_REQUEST_STATUS_COLOR = {
  accepted: {
    text: "Accepted",
    color: "0, 188, 60",
    colorBg: "#E6F8EC",
  },
  waiting: {
    text: "Waiting",
    color: "243, 167, 46",
    colorBg: "#FEF6E9",
  },
  rejected: {
    text: "Rejected",
    color: "230, 10, 50",
    colorBg: "#FDE7EB",
  },
  canceled: {
    text: "Canceled",
    color: "102, 102, 102",
    colorBg: "#E6E6E6",
  },
};

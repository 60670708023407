import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import { useAdminGameSettingsQuery, usePressesQuery, useUpdateGameSettingMutation } from "services";
import formSchema from './formSchemal'
import Swal from "sweetalert2";
import { errorHandle, successHandle } from "utils";

const useMainHook = () => {
    const { global, globalDispatch } =
        React.useContext(AdminAppContext);

    React.useEffect(() => {
        if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SETTING) {
            globalDispatch(
                Action.global.setState({
                    current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SETTING,
                })
            );
        }
    }, [global.current_page, globalDispatch]);

    const [loading, setLoading] = React.useState(false);

    const {data: gameSetting, loading: loadingGameSetting, refetch: refetchGameSetting} = useAdminGameSettingsQuery(
        {fetchPolicy: 'no-cache'}
    );

    const [updateGameSetting] = useUpdateGameSettingMutation();

    const formik = useFormik({
        initialValues: {
            appName: "",
            appLanguage: 'en',
            mcOffsetTime: 0
        },
        enableReinitialize: true,
        validationSchema: formSchema,
        onSubmit: (values) => {
            handleUpdateGameSetting(values);
        },
    });

    const handleUpdateGameSetting = async (values: any) => {
        // console.log(values);
        const {appName, appLanguage, mcOffsetTime} = values;
        const result = await Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            text: `Your changing will change all system.`,
            showCancelButton: true,
            confirmButtonText: "Yes, save it!",
          });
          if (!result.isConfirmed) {
            return;
          }
          setLoading(true)
          updateGameSetting({
            variables: {
              input: {
                app: {
                  name: appName,
                  language: appLanguage,
                },
                monthly_challenge: {
                  offset_time_value: Number(mcOffsetTime),
                },
              },
            },
          })
            .then(() => {
              successHandle("Update Game Setting Success", "notification");
              refetchGameSetting();
            })
            .catch((err) => {
              errorHandle(err);
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const handleSubmitForms = () => {
        formik.submitForm()
    }

    React.useEffect(()=>{
        // console.log('gameSetting', gameSetting);
        if(gameSetting && gameSetting?.adminGameSettings) {
            const data = gameSetting?.adminGameSettings;
            formik.setValues({
                appName: data?.app?.name || '',
                appLanguage: data?.app?.language || 'en',
                mcOffsetTime: data?.monthly_challenge?.offset_time_value || 0
            })
        }
    }, [gameSetting])

    return {
        loading: loadingGameSetting || loading,
        formik,
        handleSubmitForms,
    };
};

export default useMainHook;

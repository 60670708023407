import React from "react";
import style from "./RadioGroup.module.scss";
import {
    RadioGroup as ComponentMui,
    RadioGroupProps as ComponentMuiProps,
} from "@mui/material";
import InputLabel from "Components/base/Input";

//@ts-ignore
const Container = (props) => {
    const { children, className, label, required } = props;
    return (
        <div className={style["RadioGroup-Wrapper"] + " RadioGroup-Wrapper"}>
            {label && (
                <div className={style["label"]}>
                    {label}
                    <>{required && <span className="MuiInputLabel-asterisk"> *</span>}</>
                </div>
            )}
            <ComponentMui {...props} className={className}>
                {children}
            </ComponentMui>
        </div>
    );
};

export default Container;

import React, { useEffect, useMemo, useState } from "react";
import style from "./AddGameSettingModal.module.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectAutoComplete,
} from "Components/base";
import {
  useAdminCreateBetaUserMutation,
  useGetUserNotJoinBetaQuery,
} from "services";
import debounce from "lodash/debounce";
import { handleRenderNotification } from "utils/handleRenderNoti";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
interface AddBetaUserModalProps {
  open: boolean;
  handleClose: VoidFunction;
  refetchUserList: VoidFunction;
}

const AddBetaUserModal = (props: AddBetaUserModalProps) => {
  const { open, handleClose, refetchUserList } = props;
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const { data, loading, refetch } = useGetUserNotJoinBetaQuery({
    variables: {
      page: 1,
      pageSize: 10,
      search: searchValue,
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (open) {
      refetch({
        page: 1,
        pageSize: 10,
        search: searchValue,
      });
    } else {
      setSelectedUser([]);
    }
  }, [open, searchValue]);

  const handleFormatData = (value: any) => {
    const { adminGetUsersNotJoinBeta = {} } = value || {};
    const { users = [] } = adminGetUsersNotJoinBeta;
    return users.map((item: any) => ({
      value: item?.customId || "",
      label: item?.username || "",
    }));
  };

  const [createUserMutation, { loading: loadingMutation }] =
    useAdminCreateBetaUserMutation();

  const optionList = useMemo(() => {
    const list = handleFormatData(data);
    const listFilter = list.filter(
      (item: { label: string; value: string }) =>
        !selectedUser.includes(item.value)
    );

    return listFilter;
  }, [data]);

  const handleChangeSelect = (name: any, value: any) => {
    setSelectedUser(
      value.map((item: { value: string; label: string }) => item.value)
    );
    setSearchValue("");
  };

  const debounce_fun = debounce(function (value) {
    setSearchValue(value);
  }, 500);

  const handleChangeInput = (name: string, value: any) => {
    debounce_fun(value);
  };

  const handleAddUser = () => {
    createUserMutation({
      variables: {
        customId: selectedUser,
      },
    })
      .then((res: any) => {
        const { errors } = res || {};
        if (errors) {
          handleRenderNotification({
            message: "Add user failed",
            status: "error",
          });
          return;
        }
        handleRenderNotification({
          message: "Add user successfully",
          status: "success",
        });
        refetchUserList();
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <div className={style["AddGameSettingModal"]}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-cy="add-machine-dialog"
        className={style["EditGameSettingModal"]}
      >
        <DialogTitle id="alert-dialog-title">Add User Beta</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={style["row"]}>
              <SelectAutoComplete
                fullWidth
                name="users"
                placeholder="Select UserName ..."
                multiple
                limitTags={2}
                options={optionList}
                onChange={handleChangeSelect}
                onInputChange={handleChangeInput}
                inputProps={{ name: "username" }}
                loading={loading}
              />
            </div>
            <p style={{ fontSize: "14px", color: "#3a3a3a" }}>
              <i>
                If users have requests, their requests will be changed to
                accepted.
              </i>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions id="alert-dialog-actions">
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            className="btn-default"
          >
            Cancel
          </Button>
          <ButtonWithLoading
            variant="contained"
            loading={loadingMutation}
            type="submit"
            onClick={handleAddUser}
            disabled={loadingMutation || selectedUser.length === 0}
          >
            Save
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddBetaUserModal;

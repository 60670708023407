import React from "react";
import style from "./NoImage.module.scss";
import ImageNotSupportedRounded from "@mui/icons-material/ImageNotSupportedRounded";

const NoImage = () => {
  return (
    <div className={style["NoImage"] + " NoImage"}>
      <ImageNotSupportedRounded />
    </div>
  );
};

export default NoImage;

import { GlobalAction } from "./globals/global.action";
import { AdminAction } from "./admins/admin.action";
import { SubscriptionAction } from "./subscriptions/subscription.action";
import { MachineAction } from "./machines/machine.action";
import { UserAction } from "./users/user.action";
import { ExperienceAction } from "./experiences/experience.action";
import { BlogAction } from "./blogs/blog.action";
import { PostAction } from "./posts/post.action";
import { GameLevelsAction } from "./gameLevels/gameLevels.action";
import { ExperienceCategoryAction } from "./experienceCategories/experienceCategory.action";
import { DealerLocationAction } from './dealerLocations/dealerLocation.action';
import { ChallengeAction } from "./challenges/challenge.action";
import { SupportAction } from "./supportCategory/supportCategory.action";
import { SupportPostAction } from "./supportPost/supportPost.action";

class Action {
    static global = new GlobalAction();
    static admin = new AdminAction();
    static subscription = new SubscriptionAction();
    static machine = new MachineAction();
    static user = new UserAction();
    static experience = new ExperienceAction();
    static blog = new BlogAction();
    static post = new PostAction();
    static gameLevels = new GameLevelsAction();
    static experienceCategory = new ExperienceCategoryAction();
    static dealerLocation = new DealerLocationAction();
    static challenge = new ChallengeAction();
    static supportCategory = new SupportAction();
    static supportPost = new SupportPostAction();
}
export default Action;

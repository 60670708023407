import { makeStyles } from "@mui/styles";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import GameTypeSelect from "Components/GameShopProduct/GameTypeSelect";
import { ImageUploadInput } from "Components/ImageUploadInput/ImageUploadInput";
import AppSelect from "Components/MonthlyChallenge/AppSelect";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Button,
} from "Components/base";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIconFilled.svg";
import { ReactComponent as PublicIcon } from "Components/base/Icons/PublicIcon.svg";
import lodash from "lodash";
import React from "react";
import { torem } from "styles/theme/toRem";
import Collapse from "../../../../Components/DynamicContionForm/Collapse";
import ConditionForm from "../../../../Components/DynamicContionForm/Condition";
import useDetailHook from "./Detail.hook";
import style from "./Detail.module.scss";
import TargetTypeSelect from "Components/MonthlyChallenge/TargetTypeSelect";
import { RingLoading } from "Components/RingLoading/RingLoading";
import AchivementStatus, { ACHIEVENT_STATUS } from "../Main/AchivementStatus";
import TrashIcon from "Components/base/Icons/TrashIcon";
import JsonEditor from "Components/base/JsonEditorFormField";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "12px",
    margin: "12px",
  },
  buttonGroup: {
    margin: "12px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "48%",
  },
}));

const AchievementDetail = () => {
  const {
    loading,
    setBack,
    formik,
    handleUploadIconGray,
    handleUploadIcon,
    conditionFormik,
    handleSubmitForms,
    initConditionFormik,
    achievement,
    handleUnPublic,
    handlePublic,
    handleDeleteAchievement,
  } = useDetailHook();
  const achievementStaus = achievement?.adminAchievement?.status;
  const isUpdateField = achievementStaus===ACHIEVENT_STATUS.active;
  return (
    <div className={style["AddPostWrapper"]}>
      <Grid
        container
        spacing={torem(16)}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-sub-title pointer" onClick={setBack}>
            Achievement Management &gt; Edit Achievement
          </div>
          <div
            className="aviron-title"
            role="presentation"
            style={{ display: "flex", gap: torem(16), alignItems: "center" }}
          >
            &nbsp; Edit Achievement{" "}
            <AchivementStatus status={achievementStaus} />
          </div>
        </Grid>
        <Grid item className={"buttonGroup"}>
          {achievementStaus === ACHIEVENT_STATUS.active && (
            <Button
              onClick={handleUnPublic}
              style={{ minWidth: torem(120), marginRight: torem(16) }}
              variant="outlined"
              color="error"
            >
              UnPublic
            </Button>
          )}

          {achievementStaus === ACHIEVENT_STATUS.inactive && (
            <>
              <ButtonWithLoading
                variant="outlined"
                color="error"
                onClick={handleDeleteAchievement}
                // loading={loading.delete}
                startIcon={<TrashIcon />}
                style={{ minWidth: torem(120), marginRight: torem(16) }}
              >
                Delete
              </ButtonWithLoading>
              <ButtonWithLoading
                variant="outlined"
                loading={loading}
                type="submit"
                onClick={handleSubmitForms}
                startIcon={<SaveIcon />}
                style={{ minWidth: torem(120) }}
              >
                Update
              </ButtonWithLoading>
              <Button
                onClick={handlePublic}
                startIcon={<PublicIcon />}
                style={{ minWidth: torem(120), marginLeft: torem(16) }}
                variant="contained"
              >
                Public
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <div className={style["addBody"]}>
        {!lodash.isEmpty(formik.errors) && (
          <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
            <FormHelperText>Error in Profile Form</FormHelperText>
          </div>
        )}
        <Collapse headerText="Profile" defaultOpen={true}>
          <form>
            <div className={style["profileContent"]}>
              <Grid
                container
                spacing={torem(16)}
                className="inputSelectFormGroup"
              >
                <Grid item xs={12}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="name"
                      label="Name"
                      placeholder="Enter Name"
                      fullWidth
                      name="name"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.name ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      helperText={
                        formik.errors.name && formik.touched.name
                          ? formik.errors.name
                          : null
                      }
                      disabled={isUpdateField}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={torem(16)}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-machine-type"
                      required
                    >
                      App
                    </InputLabel>
                    <AppSelect
                      onChange={formik.handleChange}
                      value={formik.values?.app}
                      otherProps={{
                        // disabled: isUpdateField,
                        disabled: true,
                        errors: formik.errors.app,
                      }}
                    />
                    <FormHelperText error={formik.errors.app ? true : false}>
                      {formik.errors.app && formik.touched.app
                        ? formik.errors.app
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-machine-type"
                      required
                    >
                      Type
                    </InputLabel>
                    <TargetTypeSelect
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      otherProps={{
                        disabled: isUpdateField,
                        errors: formik?.errors?.type,
                      }}
                    />
                    <FormHelperText error={formik.errors.type ? true : false}>
                      {formik.errors.type && formik.touched.type
                        ? formik.errors.type
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  required
                  id="description"
                  label="Description"
                  placeholder="Enter Description"
                  fullWidth
                  name="description"
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                      ? true
                      : false
                  }
                  // required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.description}
                  helperText={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null
                  }
                  disabled={isUpdateField}
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="explanation"
                  label="Explanation"
                  placeholder="Enter Explanation"
                  fullWidth
                  name="explanation"
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={formik.values.explanation}
                  error={
                    formik.errors.explanation && formik.touched.explanation
                      ? true
                      : false
                  }
                  // required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.explanation}
                  helperText={
                    formik.errors.explanation && formik.touched.explanation
                      ? formik.errors.explanation
                      : null
                  }
                  disabled={isUpdateField}
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron">
                <Grid
                  container
                  spacing={torem(16)}
                  className={style["IconFieldWrapper"]}
                >
                  <Grid item xs={6}>
                    <ImageUploadInput
                      label="Icon Gray"
                      required={true}
                      uploadImage={handleUploadIconGray}
                      thumbValue={formik.values.iconGray}
                      onChangeValue={formik.handleChange}
                      setFieldValue={formik.setFieldValue}
                      nameFieldForm="iconGray"
                      isHideUploadFile
                      disabled={isUpdateField}
                    />
                    <FormHelperText
                      error={formik.errors.iconGray ? true : false}
                    >
                      {formik.errors.iconGray && formik.touched.iconGray
                        ? formik.errors.iconGray
                        : null}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <ImageUploadInput
                      label="Icon"
                      required={true}
                      uploadImage={handleUploadIcon}
                      thumbValue={formik.values.icon}
                      onChangeValue={formik.handleChange}
                      setFieldValue={formik.setFieldValue}
                      nameFieldForm="icon"
                      isHideUploadFile
                      disabled={isUpdateField}
                    />
                    <FormHelperText error={formik.errors.icon ? true : false}>
                      {formik.errors.icon && formik.touched.icon
                        ? formik.errors.icon
                        : null}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </FormControl>
              <FormControl
                  fullWidth
                  className={"form-aviron"}
                  style={{ marginTop: torem(16) }}
                >
                  <JsonEditor
                    name="metadata"
                    value={formik.values.metadata}
                    formik={formik}
                    disabled={isUpdateField}
                  />
                </FormControl>
            </div>
          </form>
        </Collapse>
        <div className={style["conditionWrapper"]}>
          <Collapse headerText="Condition" defaultOpen={true}>
            <form>
              {initConditionFormik && (
                <ConditionForm
                  initValues={initConditionFormik}
                  formik={conditionFormik}
                  disabled={isUpdateField}
                />
              )}
            </form>
          </Collapse>
        </div>
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default AchievementDetail;

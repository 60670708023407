import ProductStatus from "Components/GameShopProduct/ProductStatus";
import NoImage from "Components/base/Icons/NoImage";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAdminGameProductCategoryGroupsQuery, useAdminProductsQuery } from "services";


const useMainHook = () => {
  const {
    global,
    supportCategoryStore,
    globalDispatch,
    supportCategoryDispatch,
  } = React.useContext(AdminAppContext);

  React.useLayoutEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const params: any = useParams();

  const {
    data: ProductCategoryGroups,
    loading: loadingProductCategoryGroups,
    refetch: refetchProductCategoryGroups,
  } = useAdminGameProductCategoryGroupsQuery();

  const [state, setState] = React.useState({
    columns: [
      {
        numeric: false,
        disablePadding: false,
        label: "Image",
        id: "image",
        className: "productShopImageCell",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Name",
        id: "name",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Description",
        id: "description",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Category Name",
        id: "categoryName",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Status",
        id: "productStatus",
        className: "",
      },
      // {
      //   numeric: false,
      //   disablePadding: false,
      //   label: "Group",
      //   id: "groupId",
      //   hasSub: false,
      // },
      // {
      //   numeric: true,
      //   disablePadding: false,
      //   label: "Game Type",
      //   id: "gameType",
      //   hasSub: false,
      // },
    ],
    rows: [],

    totalCount: 0,

    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchParam = searchParams.get("seach");
  const pageSizeParam = searchParams.get("pageSize") || 10;
  const pageParam = searchParams.get("page") || 0;
  const categoryIdParam = searchParams.get('categoryId') || "";
  const categoryNameParam = searchParams.get('categoryName') || "";
  const statusParam = searchParams.get("status") || "";

  const [queryParameter, setQueryParameter] = React.useState<any>({
    pageSize: Number(pageSizeParam) || 10,
    page: Number(pageParam) || 0,
    name: searchParam || "",
    categoryId: categoryIdParam || "",
    categoryName: categoryNameParam || "",
    status: statusParam || ""
  });

  React.useEffect(() => {
    let urlParams = `?page=${queryParameter.page}&pageSize=${queryParameter.pageSize}`;
    if (queryParameter.name) {
      urlParams = urlParams + `&search=${queryParameter.name}`;
    }
    if (queryParameter.sortOrder) {
      urlParams = urlParams + `&sortOrder=${queryParameter.sortOrder}`;
    }
    if (queryParameter?.categoryId) {
      urlParams =
        urlParams + `&categoryId=${queryParameter?.categoryId}`;
    }
    if (queryParameter?.categoryName) {
      urlParams =
        urlParams + `&categoryName=${queryParameter?.categoryName}`;
    }
    if (queryParameter?.status) {
      urlParams =
        urlParams + `&status=${queryParameter?.status}`;
    }
    history.replace(urlParams);
  }, [queryParameter]);

  const { data, loading, refetch } = useAdminProductsQuery({
    variables: {
     input: {
      name: queryParameter.name,
      page: queryParameter.page + 1,
      pageSize: queryParameter.pageSize,
      categoryId: Number(queryParameter.categoryId),
      status: queryParameter?.status || '',
     }
    },
    fetchPolicy: "no-cache",
  });

  // React.useEffect(() => {
  //     if (history.action === "PUSH") {
  //         supportCategoryDispatch(
  //             Action.supportCategory.setState(
  //                 set(supportCategoryStore, `search`, '')
  //             )
  //         );
  //         supportCategoryDispatch(
  //             Action.supportCategory.setState(
  //                 set(supportCategoryStore, `orderBy`, 'ORDER_DESC')
  //             )
  //         );
  //     }
  // }, [history.action]);

  React.useEffect(() => {
    if (data && data?.adminProducts?.products && ProductCategoryGroups) {
      setState({
        ...state,
        rows: data.adminProducts.products.map((item: any) => {
          const GroupCategory =
          ProductCategoryGroups?.adminGameProductCategoryGroups?.filter(
            (el: any) => el.id == item.groupId
          ) || [];
          return {
            id: item.id,
            image: item.imageUrl ? (
              <img src={item.imageUrl} alt="product image" />
            ) : (
              <NoImage />
            ),
            name: item?.name,
            description: item?.description,
            categoryName: item?.categoryName,
            groupId: GroupCategory[0]?.name || "",
            gameType: item?.gameType,
            type: item?.type,
            productStatus: item?.status ? (
              <ProductStatus status={item.status} />
            ) : (
              ""
            ),
          };
        }),
        totalCount: data.adminProducts.metadata.totalRecords || 0,
      });
    } else {
      setState({
        ...state,
        rows: [],
        totalCount: 0,
      })
    }
  }, [data, ProductCategoryGroups]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({ ...queryParameter, page: newPage });
  };
  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      page: 0,
      name: newValue,
    });
  };

  const handleViewDetail = (event: any, item: any) => {
    history.push(`${CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}/detail/${item.id}`);
  };

  const goToSub = (even: any, item: any) => {
    const path = `${CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}/detail/${item.id}`;
    history.push({ pathname: path });
  };

  const handleAdd = () => {
    history.push(CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT_ADD);
  };

  const handleChangeParentProductCategory = (name: any, value: any) => {
    setQueryParameter({
      ...queryParameter,
      categoryName: value?.name,
      categoryId: value?.id,
      page: 0,
    });
  };

  const handleChangeStatus = (event: any) => {
    const value = event.target.value;
    setQueryParameter({ ...queryParameter, status: value });
  }

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    goToSub,
    action: {
      detail: true,
    },
    handleChangePage,
    handleViewDetail,
    loading: loading,
  };
  return {
    supportCategoryStore,
    propsTable,
    state,
    loading,
    handleAdd,
    handleSearch,
    queryParameter,
    handleChangeParentProductCategory,
    handleChangeStatus,
  };
};

export default useMainHook;
import { BaseReducer } from "../base";

class SupportCategoryReducer extends BaseReducer {
    constructor() {
        super(
            "SUPPORT_CATEGORY",
            {
                search: "",
                currentPage: 0,
                orderBy: ['ORDER_DESC'],
                advancedFilter: {
                    
                },
            },
            {}
        );
    }
}

export const supportCategoryReducer = new SupportCategoryReducer();

import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  api,
  useAdminDeleteProductMutation,
  useAdminProductQuery,
  useAdminUpdateProductMutation,
  useCreateUploadPhotoUrlMutation
} from "services";
import { formSchema } from "../Add/formSchemal";
import { formatAppForm, formatAppRequest } from "./formatData";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { errorHandle } from "utils";
import TrashIcon from "Components/base/Icons/TrashDelIcon.svg";
import { STATUS_GAME_SHOP_PRODUCT } from "consts/gameShop";

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  const params = useParams<any>();
  // console.log('supportCategories', supportCategories)
  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const [updateProduct] = useAdminUpdateProductMutation();
  const [deleteProduct] = useAdminDeleteProductMutation();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();

  const {
    data: product,
    loading: loadingProduct,
    refetch: refetchProduct,
  } = useAdminProductQuery({
    variables: {
      input: {
        id: params?.id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const [state, setState] = React.useState<any>({
    selectedCategoryId: null,
    thumb: null,
    selectedFile: null,
  });

  const handleCreateSupport = async (values: any) => {
  };

  const appFormik = useRef<any>();
  const [initAppFormik, setInitAppFormik] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      currency: "",
      category: {id: 0, name: ""},
      gameType: "",
      store: "",
      icon: "",
      status: "",
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await appFormik?.current?.submitForm();
    },
  });
  // console.log('opopppopopop',formik);
  useEffect(() => {
    if (product && product?.adminProduct) {
      const detail = product?.adminProduct;
      formik.setValues({
        id: detail?.id,
        name: detail?.name,
        price: detail?.price,
        description: detail?.description,
        currency: detail?.currencyId || "",
        category: {id: detail?.categoryId, name: detail.categoryName},
        gameType: detail?.gameType,
        store: detail?.storeId,
        icon: detail?.imageUrl,
        status: detail?.status
      } as any);
      const appData = formatAppForm(detail.app);
      setInitAppFormik({ lv1FormFields: appData });
    }
  }, [product]);

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const uploadImage = async (file: any) => {
    const uploadUrlData = await createUploadUrl({
      variables: {
        input: {
          contentType: "image/png",
          name: file.name.slice(file.name, file.name.lastIndexOf(".")),
        },
      },
    });
    await api.util.uploadImage(
      get(uploadUrlData, "data.createUploadPhotoUrl.uploadUrl"),
      {
        key: get(uploadUrlData, "data.createUploadPhotoUrl.fields.key"),
        policy: get(uploadUrlData, "data.createUploadPhotoUrl.fields.policy"),
        "x-goog-algorithm": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_algorithm"
        ),
        "x-goog-credential": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_credential"
        ),
        "x-goog-signature": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_signature"
        ),
        "x-goog-date": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_date"
        ),
        "content-type": "image/png",
        acl: "public-read",
        file: file,
      }
    );
    return get(uploadUrlData, "data.createUploadPhotoUrl.publicUrl");
  };

  const handleUploadThumb = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("icon", "");
    } else {
      formik.setFieldValue("icon", file);
    }
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    // console.log({ formik, appFormik });
    if (formik.values.name.trim() !== "") {
      if (formik.isValid && appFormik?.current?.isValid) {
        // console.log({formik, appFormik});
        setLoading(false);
        const result = await Swal.fire({
          title: 'Are you sure?',
          icon: "warning",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonText: "Yes, save it!",
        })
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        const appRequest = formatAppRequest(appFormik?.current?.values.lv1FormFields);
        const updateParams = {
          id: params.id,
          name: formik.values.name,
          price: Number(formik.values.price),
          description: formik.values.description,
          currencyId: Number(formik.values.currency),
          storeId: Number(formik.values.store),
          categoryId: Number(formik.values.category.id),
          imageUrl: formik.values.icon,
          app:
            formik?.values?.status !== STATUS_GAME_SHOP_PRODUCT.active
              ? appRequest
              : undefined,
        };
        // console.log({updateParams});
        // setLoading(false);
        updateProduct({
          variables: {
            input: {
              ...updateParams,
            },
          },
        }).then((res)=>{
          setLoading(false);
          toast.success("Update Game Shop Product Success",{ autoClose: 3000 });
        }).catch((err)=>{
          errorHandle(err)
          setLoading(false)
        }).finally(()=>{
          setLoading(false);
        });
      } else if (!formik.isValid) {
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else if (!appFormik?.current?.isValid) {
        const el = document.getElementById("appFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    }
  };

  const handleDeleteProduct = async() => {
        //@ts-ignore
        const result = await Swal.fire({
          title: "Remove Product",
          html: `<div>
                  Do you want to remove Product <br /><b>${formik.values.name}</b></div>`,
          showCancelButton: true,
          confirmButtonText: "Delete",
          customClass: "errorCustom",
          imageUrl: TrashIcon,
        });
        if (!result.isConfirmed) {
          return;
        }
        setLoading(true);
        deleteProduct({
          variables: {
            input: {
              id: params.id,
            }
          }
        }).then(()=>{
          setLoading(false)
          toast.success('Delete Product success !',{ autoClose: 3000 });
          history.push(CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT);
        }).catch((err)=>{
          errorHandle(err);
          setLoading(false)
        }).finally(()=>{
          setLoading(false)
        })
  }

  return {
    loading: loading || loadingProduct,
    setBack,
    formik,
    handleUploadThumb,
    appFormik,
    handleSubmitForms,
    initAppFormik,
    handleDeleteProduct,
  };
};

export default useAddHook;

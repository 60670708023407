import React, { useEffect, useState } from "react";
import style from "./InputSearchDelay.module.scss";
import { ReactComponent as SearchIcon} from "./searchIcon.svg";
import { useLocation } from "react-router-dom";

interface InputProps {
    props?: any;
    label?: any;
    name?: string;
    value?: any;
    multiline?: boolean;
    fullWidth?: boolean;
    onChange?: Function;
    onKeyDown?: Function
    handleSearch?: Function
}
const InputDelay = ({
    props,
    label,
    name,
    value,
    multiline,
    fullWidth,
    handleSearch,
    onKeyDown,
}: InputProps) => {
    const location = useLocation();
    const search = location.search;
    const searchParams = new URLSearchParams(search);
    const searchParam = searchParams.get("search") || "";
    useEffect(()=>{
        setQuery(searchParam)
    }, [location])
    const [query, setQuery] = useState(searchParam);
    // useEffect(() => {
    //     //@ts-ignore
    //     const timeOutId = setTimeout(() => onChange && onChange(query), 500);
    //     return () => clearTimeout(timeOutId);
    // }, [query]);
    //@ts-ignore
    const onChangeInput = (event) => {
        setQuery(event.target.value);
    };
    //@ts-ignore
    const onKeyDownSearch = (e) => {
        if(onKeyDown) {
            onKeyDown(e)
        } else {
            if (e.keyCode === 13) {
                handleSearch && handleSearch(query);
            }
        }
    };
    const handleClickSearch = () => {
        handleSearch && handleSearch(query)
    };
    return (
      <div
        className={style["InputDelayWrapper"] + " InputDelayWrapper"}>
        <input
          {...props}
          label={label}
          name={name}
          multiline={multiline}
          fullWidth={fullWidth}
          onChange={onChangeInput}
          value={query}
          onKeyDown={onKeyDownSearch}
        />
        <div
          className="pointer"
          role="presentation"
          onClick={handleClickSearch}
        >
          <SearchIcon />
        </div>
      </div>
    );
};

export default InputDelay;

import moment from "moment";
import { BaseReducer } from "../base";

class ChallengeReducer extends BaseReducer {
    constructor() {
        super(
            "CHALLENGE",
            {
                advancedFilter: {
                    upToYear: moment().year(),
                    upToMonth: 12,
                    numMonths: 12
                },
            },
            {}
        );
    }
}

export const challengeReducer = new ChallengeReducer();

import React from 'react';
import style from './ProductStatus.module.scss';
import StatusLabel from 'Components/StatusLabel';

interface ProductStatus {
  status: string
}

const getStatusOption = (status: any) => {
  if(status==='active') {
    return {
      text: 'Public',
      color: '0, 188, 60',
      colorBg: 'rgb(230, 248, 236)',
    }
  } else {
    return {
      text: 'Not Active',
      color: '102, 102, 102',
      colorBg: 'rgb(230, 230, 230)',
    }
  }
}

const ProductStatus = ({status}: ProductStatus) => {
  return (
    <div className={style['ProductStatus']}>
      <StatusLabel {...getStatusOption(status)} />
    </div>
  )
}

export default ProductStatus;
import React from 'react';
import style from './TrashIcon.module.scss';
import { ReactComponent as TrashCanIcon} from './TrashCan.svg';
const TrashIcon = () => {
  return (
    <div className={style['TrashIcon'] + ' TrashIcon'}>
      <TrashCanIcon />
    </div>
  );
}

export default TrashIcon;
import React, { useEffect, useRef, useState, Suspense } from "react";
import { Formik, Field, Form, FieldArray, useFormik } from "formik";
import style from "./ConditionForm.module.scss";
import AddIcon from "Components/base/Icons/AddIcon";
import { ReactComponent as CloseIcon } from "Components/base/Icons/CloseIcon2.svg";
import { MenuItem, Select, TextField } from "Components/base";
import InputPositiveNumber from "Components/base/InputPositiveNumber";
import { torem } from "styles/theme/toRem";
import { ACCHIVEMENT_OPERATOR_TYPE, ACCHIVEMENT_TYPE } from "consts/achivement";
import { RingLoading } from "Components/RingLoading/RingLoading";
import loadable from "@react-loadable/revised";
import TargetTypeSelect from "Components/MonthlyChallenge/TargetTypeSelect";
import Lv3Form from "./Lv3Form";

interface Lv2FormProps {
  parentValues: any;
  setParentValues: any;
  parentIndex: any;
  initValue: any;
  disabled?: any;
}

const Lv2Form = ({
  parentValues,
  setParentValues,
  parentIndex,
  initValue,
  disabled,
}: Lv2FormProps) => {
  const [lv2Formik, setLv2Formik] = useState({ lv2FormFields: null });
  useEffect(() => {
    if (lv2Formik) {
      let lv1FormFields = parentValues.lv1FormFields;
      lv1FormFields[parentIndex].lv2FormFields = lv2Formik?.lv2FormFields;
      setParentValues({
        lv1FormFields,
      });
    }
  }, [lv2Formik]);
  return (
    <Suspense fallback={<RingLoading />}>
      <Formik
        onSubmit={(values) => {
          // console.log("values", values);
        }}
        initialValues={{
          lv2FormFields: initValue,
        }}
        innerRef={(formikActions: any) => {
          formikActions && setLv2Formik(formikActions?.values);
        }}
      >
        {({ values, handleSubmit, setValues }) => (
          <>
            {values.lv2FormFields ? (
              <Form>
                <FieldArray
                  name="lv2FormFields"
                  render={(arrayHelpers) => (
                    <>
                      {values.lv2FormFields.length > 0 &&
                        values.lv2FormFields.map(
                          (lv2Field: any, index: any) => {
                            return (
                              <>
                                <div className={style["primaryConditionRow"]}>
                                  <div className={style["title"]}>
                                    {index === 0
                                      ? "Primary Condition"
                                      : "Condition " + index}
                                  </div>
                                  <div className={style["typeCondition"]}>
                                    <Field
                                      name={`lv2FormFields.${index}.type`}
                                      render={({ field }: any) => (
                                        <TargetTypeSelect
                                          otherProps={{
                                            style: {
                                              width: torem(366),
                                            },
                                            inputProps: {
                                              ...field,
                                              disabled,
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className={style["operatorWrapper"]}>
                                    <div className={style["operator"]}>
                                      <div className={style["operatorSelect"]}>
                                        <Field
                                          name={`lv2FormFields.${index}.operator`}
                                          render={({ field }: any) => (
                                            <Select
                                              style={{
                                                width: torem(156),
                                              }}
                                              inputProps={{
                                                ...field,
                                                disabled,
                                              }}
                                            >
                                              {ACCHIVEMENT_OPERATOR_TYPE.map(
                                                (item, key) => {
                                                  return (
                                                    <MenuItem
                                                      key={key}
                                                      value={item.key}
                                                    >
                                                      {item.label}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          )}
                                        />
                                      </div>
                                      <div className={style["operatorInput"]}>
                                        <Field
                                          name={`lv2FormFields.${index}.operator_value`}
                                          render={({ field }: any) => (
                                            <InputPositiveNumber
                                              style={{
                                                width: torem(156),
                                              }}
                                              inputProps={{
                                                ...field,
                                                disabled,
                                              }}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                    {index !== 0 && !disabled && (
                                      <div
                                        className={style["removeField"]}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <CloseIcon />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <Lv3Form
                                  setParentValues={setValues}
                                  parentValues={values}
                                  parentIndex={index}
                                  initValue={lv2Field.lv3FormFields}
                                  disabled={disabled}
                                />
                              </>
                            );
                          }
                        )}
                      {values.lv2FormFields.length <= 1 && !disabled && (
                        <div>
                          <div
                            className={style["addButtonText"]}
                            onClick={() =>
                              arrayHelpers.push({
                                type: "rank_in_game",
                                operator: "eq",
                                operator_value: 1,
                              })
                            }
                          >
                            <AddIcon />
                            Add Condition
                          </div>
                        </div>
                      )}
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </>
                  )}
                />
              </Form>
            ) : null}
          </>
        )}
      </Formik>
    </Suspense>
  );
};

export default Lv2Form;

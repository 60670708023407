import { setTimeWithTimeZone } from "utils";
import { BaseReducer } from "../base";

class BlogReducer extends BaseReducer {
    constructor() {
        super(
            "BLOG",
            {
                currentPage: 0,
                search: "",
                advancedFilter: {
                    state: {
                        value: "",
                        label: "All",
                    },
                    planType: {
                        value: "",
                        label: "All",
                    },
                    customerType: {
                        value: "",
                        label: "All",
                    },
                    startTime: setTimeWithTimeZone().subtract(1, "years").format(),
                    endTime: setTimeWithTimeZone().endOf("days").format(),
                },
            },
            {}
        );
    }
}

export const blogReducer = new BlogReducer();

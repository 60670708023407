import React, { ReactChildren } from 'react'
import style from './style.module.scss';
import { OutlinedInput as ComponentMui, OutlinedInputProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps extends ComponentMuiProps {};
const OutlinedInput = (props:ComponentProps) => {
    return (
            <ComponentMui {...props} className={style['InputLabel-Wraper']} />
    )
}

export default OutlinedInput;
import { RELOAD_DATA_REQUEST } from "./reloadData.type";

import produce from "immer";

export const initialState: any = {
    isReload: false,
};

type actionType = {
    type: string;
    payload: any;
};
const reducer = produce((state, action: actionType) => {
    switch (action.type) {
        case RELOAD_DATA_REQUEST: {
            return {...state, isReload: action.payload};
        }

        default:
            return state;
    }
}, initialState);

export const reloadDateReducer = { initialState, reducer };

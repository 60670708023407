import * as React from "react";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useHistory } from "react-router-dom";
import {
  useAcceptMutation,
  useSearchBetaRequestAdvanceQuery,
  useCountSearchBetaRequestAdvanceQuery,
} from "services";
import { getObjectInArrayByValueKey, setTimeWithTimeZone } from "utils";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import CircleCheckedSuccess from "Components/base/Icons/CircleCheckedSuccess.svg";
import CircleCrossError from "Components/base/Icons/CircleCrossError.png";
import BetaRequestStatusLabel from "./GameBetaRequests.actions";
import ApproveIcon from "Components/CmsTables/icons/approveIcon";
import styles from "./GameBetaRequest.module.scss";
import RejectIcon from "Components/CmsTables/icons/rejectIcon";
import classNames from "classnames";
import { handleRenderNotification } from "utils/handleRenderNoti";
import { handleRenderTitleLoading } from "utils/handleRenderTitleLoading";
import {
  BetaRequestColumns,
  selectOptionsFilterDate,
  stateOptions,
} from "./GameBetaRequest.constant";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 200,
    maxWidth: 450,
    border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
}));

export const useActiveSidebarBetaRequest = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST,
        })
      );
    }
  }, [global.current_page, globalDispatch]);
};

const useMainHook = () => {
  const history = useHistory();

  const [state, setState] = React.useState({
    columns: BetaRequestColumns,
    rows: [] as any,
    totalCount: 0,
    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });
  const [isOpenTooltip, setIsOpenTooltip] = React.useState("");

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchParam = searchParams.get("search") || "";
  const offsetParam = searchParams.get("offset") || 0;
  const firstParam = searchParams.get("first") || 10;
  const pageParam = searchParams.get("page") || 0;
  const stateParam = searchParams.get("state") || 0;
  const orderByParam = searchParams.get("orderBy") || "";

  const [queryParameter, setQueryParameter] = React.useState<any>({
    first: Number(firstParam) || 10,
    offset: Number(offsetParam) || 0,
    page: Number(pageParam) || 0,
    title: searchParam || "",
    orderBy:
      getObjectInArrayByValueKey(orderByParam, selectOptionsFilterDate) ||
      selectOptionsFilterDate[0],
    state:
      stateParam === ""
        ? stateOptions[0]
        : getObjectInArrayByValueKey(stateParam, stateOptions),
  });
  const { data, loading, refetch } = useSearchBetaRequestAdvanceQuery({
    variables: {
      offset: queryParameter.offset,
      first: queryParameter.first,
      status: queryParameter?.state?.value || "",
      orderBy: queryParameter.orderBy?.value || "CREATED_AT_DESC",
      search: queryParameter?.title || "",
    },
    fetchPolicy:"no-cache"
  });

  const { data: dataTotalPage, refetch: refetchTotalPage } =
    useCountSearchBetaRequestAdvanceQuery({
      variables: {
        search: queryParameter?.title || "",
        status: queryParameter?.state?.value || "",
      },
      fetchPolicy:"no-cache"
    });

  const [changeStatusMutation, { loading: loadingChangeStatus }] =
    useAcceptMutation();

  const handleRenderActions = (item: any) => {
    const { status, userId, id, user, cancelReason, canceledAt } = item;
    const { userEmails: { nodes = [] } = {} } = user || {};
    const email = nodes.length > 0 ? nodes[0].email : "";

    switch (status) {
      case "accepted":
      case "rejected":
        return <p>--</p>;
      case "canceled":
        return (
          <HtmlTooltip
            title={
              <React.Fragment>
                <h4 className={styles["tootip-title"]}>Reason</h4>
                <p className={styles["tootip-content"]}>{cancelReason}</p>
                <h4 className={styles["tootip-title"]}>Time Canceled</h4>
                <p className={styles["tootip-content"]}>
                  {setTimeWithTimeZone(canceledAt).format("MM/DD/YYYY")}
                </p>
              </React.Fragment>
            }
            open={id === isOpenTooltip}
            onClose={() => setIsOpenTooltip("")}
            placement="left"
            id={userId}
            arrow
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpenTooltip(id)}
            >
              <InfoIcon className={styles["info-icon"]} />
            </div>
          </HtmlTooltip>
        );
      case "waiting":
        return (
          <div className={styles["actions"]}>
            <div
              onClick={() =>
                handleConfirmChangeStatusUser("accept", userId, email)
              }
            >
              <ApproveIcon
                className={classNames(styles["actions-icon"], {
                  [styles["actions-icon-accepted"]]: true,
                  [styles["actions-icon-loading"]]: loadingChangeStatus,
                })}
              />
            </div>
            <div
              onClick={() =>
                handleConfirmChangeStatusUser("reject", userId, email)
              }
            >
              <RejectIcon
                className={classNames(styles["actions-icon"], {
                  [styles["actions-icon-loading"]]: loadingChangeStatus,
                })}
              />
            </div>
          </div>
        );
      default:
        return <p>--</p>;
    }
  };

  const onChangeSelect = (name: any, values: any, reason: string) => {
    setQueryParameter({
      ...queryParameter,
      [name]: values,
    });
  };

  React.useEffect(() => {
    let urlParams = `?offset=${queryParameter.offset}&first=${queryParameter.first}`;
    if (queryParameter.page >= 0) {
      urlParams = urlParams + `&page=${queryParameter.page}`;
    }
    if (queryParameter.title) {
      urlParams = urlParams + `&search=${queryParameter.title}`;
    }
    if (queryParameter?.state?.value) {
      urlParams += `&state=${queryParameter?.state?.value}`;
    }
    if (queryParameter?.orderBy?.value) {
      urlParams += `&orderBy=${queryParameter?.orderBy?.value}`;
    }

    history.replace(urlParams);

    refetch({
      first: queryParameter.first ,
      offset: queryParameter.offset,
      status: queryParameter.state?.value || "",
      orderBy: queryParameter.orderBy?.value || "CREATED_AT_DESC",
      search: queryParameter?.title || "",
    });
    refetchTotalPage({
      status: queryParameter?.state?.value || "",
      search: queryParameter?.title || "",
    });
  }, [queryParameter]);

  React.useEffect(() => {
    if (
      data &&
      data.searchBetaRequestAdvance &&
      data.searchBetaRequestAdvance.nodes &&
      dataTotalPage
    ) {
      setState({
        ...state,
        rows: data.searchBetaRequestAdvance.nodes.map((item) => {
          const {
            id,
            user,
            machineSerial,
            createdAt,
            discord,
            workoutPerWeek,
            status,
            userId,
            updatedAt
          } = item;
          const { username, userEmails: { nodes = [] } = {} } = user || {};
          const email = nodes.length > 0 ? nodes[0].email : "";

          return {
            id,
            username,
            email,
            discord,
            workoutPerWeek,
            machineId: machineSerial,
            created_at: setTimeWithTimeZone(createdAt).format("MM/DD/YYYY"),
            updated_at: setTimeWithTimeZone(updatedAt).format("MM/DD/YYYY"),
            status_tab: (
              <>
                <BetaRequestStatusLabel status={status as any} />
              </>
            ),

            action: <>{handleRenderActions(item)}</>,
          };
        }),
        totalCount:
          dataTotalPage?.countSearchBetaRequestAdvance?.totalCount || 0,
      });
    }
  }, [data, dataTotalPage, isOpenTooltip]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({
      ...queryParameter,
      offset: queryParameter.first * newPage,
      page: newPage,
    });
  };

  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      title: newValue,
      page: 0,
      offset: 0,
      first: 10,
    });
  };

  const handleChangeTab = (name: string, data: any) => {
    setQueryParameter({
      ...queryParameter,
      state: data,
      offset: 0,
      page: 0,
    });
  };

  const handleClickRefresh = () => {
    setQueryParameter({
      ...queryParameter,
      first: 10,
      offset: 0,
      page: 0,
      title: "",
      // status: queryParameter?.state?.value || "",
      orderBy: queryParameter.orderBy?.value || "CREATED_AT_DESC",
    });
  };

  const handleConfirmChangeStatusUser = async (
    status: any,
    userId: string,
    email: string
  ) => {
    const isAccept = status === "accept";
    const result = await Swal.fire({
      title: `${isAccept ? "Accept" : "Reject"} Beta User`,
      html: `<div>
            Do you want to ${isAccept ? "accept" : "reject"
        } user with Email<br />
            <b>${email}</b> to join the beta</div> `,
      showCancelButton: true,
      confirmButtonText: handleRenderTitleLoading(
        isAccept ? "Accept" : "Reject",
        loadingChangeStatus
      ),
      imageUrl: isAccept ? CircleCheckedSuccess : CircleCrossError,
      confirmButtonColor: isAccept ? "#00BC3C" : "#E60A32",
    });
    if (!result.isConfirmed) {
      return;
    }

    console.log("query : ", queryParameter)
    try {
      changeStatusMutation({
        variables: {
          userId,
          status,
        },
      }).then((res) => {
        const { errors } = res || {};
        if (Boolean(!errors)) {
          refetch({
            offset: queryParameter.offset,
            first: queryParameter.first,
            status: queryParameter?.state?.value || "",
            orderBy: queryParameter.orderBy?.value || "CREATED_AT_DESC",
            search: queryParameter.title,
          });
          handleRenderNotification({
            message: "Update status successfully",
            status: "success",
          });
        } else {
          handleRenderNotification({
            message: "Update status failed",
            status: "error",
          });
        }
      });
    } catch (error) {
      handleRenderNotification({
        message: "Update status failed",
        status: "error",
      });
    }
  };

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    handleChangePage,
    statusTab: {
      options: stateOptions,
      itemActive: queryParameter.state,
      handleChange: handleChangeTab,
    },
    orderBy: queryParameter?.orderBy,
    loading
  };
  return {
    propsTable,
    state,
    loading: loading || loadingChangeStatus,
    handleSearch,
    onChangeSelect,
    handleClickRefresh,
  };
};

export default useMainHook;

import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";
import ListTable from "Components/ListTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import {
  Button,
  Grid,
  MenuItem,
  Select
} from "Components/base";
import AddIcon from "Components/base/Icons/AddIcon";
import InputDelay from "Components/base/Input/InputSearchDelay";
import React from "react";
import { torem } from "styles/theme/toRem";
import useMainHook from "./Main.hook";
import style from "./Main.module.scss";
import { ACHIEVENT_STATUS } from "Admin/Screens/Achievement/Main/AchivementStatus";

const Main: React.FunctionComponent = () => {
  const {
    propsTable,
    loading,
    handleAdd,
    handleSearch,
    queryParameter,
    handleChangeParentProductCategory,
    handleChangeStatus,
  } = useMainHook();
  return (
    <div className={style["ProductMainWrapper"]}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-title">Product Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          <Button
            variant="contained"
            onClick={handleAdd}
            startIcon={<AddIcon />}
            style={{ minWidth: torem(120) }}
          >
            Add Product
          </Button>
        </Grid>
      </Grid>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: "Product List",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by name",
                  type: "text",
                }}
              />
            ),
            selectFilter: (
              <>
                <Select
                  displayEmpty
                  name="status"
                  value={queryParameter.status}
                  onChange={handleChangeStatus}
                  fullWidth
                  placeholder="All Status"
                  style={{ width: "100%" }}
                >
                  <MenuItem value={ACHIEVENT_STATUS.active}>Public</MenuItem>
                  <MenuItem value={ACHIEVENT_STATUS.inactive}>Not Active</MenuItem>
                </Select>
                <div style={{ width: torem(200) }}>
                  <ParentProductCategorySelect
                    placeholder={"All Parent Category"}
                    onChange={handleChangeParentProductCategory}
                    initValue={{
                      name: queryParameter?.categoryName,
                      id: queryParameter?.categoryId,
                    }}
                  />
                </div>
              </>
            ),
          }}
        />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Main;

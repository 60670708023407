import palette from './palette';
import {torem} from './toRem';
export default {
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(35),
    letterSpacing: torem(-0.24),
    lineHeight: torem(40)
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(29),
    letterSpacing: torem(-0.24),
    lineHeight: torem(32)
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(24),
    letterSpacing: torem(-0.06),
    lineHeight: torem(28)
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(20),
    letterSpacing: torem(-0.06),
    lineHeight: torem(24)
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(16),
    letterSpacing: torem(-0.05),
    lineHeight: torem(20)
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: torem(14),
    letterSpacing: torem(-0.05),
    lineHeight: torem(20)
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: torem(16),
    letterSpacing: torem(-0.05),
    lineHeight: torem(25)
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: torem(14),
    letterSpacing: torem(-0.05),
    lineHeight: torem(21)
  },
  body1: {
    color: palette.text.primary,
    fontSize: torem(14),
    letterSpacing: torem(-0.05),
    lineHeight: torem(21)
  },
  body2: {
    color: palette.text.secondary,
    fontSize: torem(12),
    letterSpacing: torem(-0.04),
    lineHeight: torem(18)
  },
  button: {
    color: palette.text.primary,
    fontSize: torem(14)
  },
  caption: {
    color: palette.text.secondary,
    fontSize: torem(11),
    letterSpacing: torem(0.33),
    lineHeight: torem(13)
  },
  overline: {
    color: palette.text.secondary,
    fontSize: torem(11),
    fontWeight: 500,
    letterSpacing: torem(0.33),
    lineHeight: torem(13),
    textTransform: 'uppercase'
  }
};

import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useCreatePressMutation,
  useSharedQuery,
  usePressesQuery,
  useAdminCreateProductCategoryMutation,
} from "services";
import { errorHandle } from "utils";
import { errorHandleWithFormik } from "utils/errorHandleWithFormik";
import { formSchema } from "./formSchemal";

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  const { data: userLogged } = useSharedQuery();
  // console.log('supportCategories', supportCategories)
  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      categoryGroupId: "",
      sortOrder: 1,
      description: "",
      parentCategory: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleCreateSupport(values);
    },
  });

  const history = useHistory();
  const [createCategory] = useAdminCreateProductCategoryMutation();

  const handleCreateSupport = async (values: any) => {
    setLoading(true);
    createCategory({
      variables: {
        input: {
          name: values?.name,
          description: values?.description,
          sortOrder: Number(values?.sortOrder),
          categoryGroupId: Number(values?.categoryGroupId),
          categoryParentId: Number(values?.parentCategory?.id),
        },
      },
    })
      .then((res) => {
        const newCategory= res?.data?.adminCreateProductCategory
        toast.success("Create Product Category success",{ autoClose: 3000 });
        setTimeout(()=>{
          history.replace(CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY + `/detail/${newCategory?.id}`);
        }, 500)
      })
      .catch((err: any) => {
        errorHandle(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  return {
    loading,
    setBack,
    formik,
  };
};

export default useAddHook;

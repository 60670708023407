import React, { ReactChildren } from 'react'
import style from './style.module.scss';
import { Button as ComponentMui, ButtonProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps {
    children?: any;
    className?: string
};
const Button = (props:ComponentProps | ComponentMuiProps) => {
    const {children, className } = props;
    return (
            <ComponentMui {...props} className={style['Button-Wrapper'] + ` ${className}`}>
                {children}
            </ComponentMui>
    )
}

export default Button;
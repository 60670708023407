import { BaseReducer } from "../base";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";

class SupportPostReducer extends BaseReducer {
    constructor() {
        super(
            "SUPPORT_POST",
            {
                currentPage: 0,
                title: '',
                advancedFilter: {
                    type: {value: '', label: 'All', query: ''},
                    supportCategoryId: {value: "", label: 'All', query: {isNull: false}}
                },
            },
            {
                reloadData: `SUPPORT_POST_RELOAD_DATA`,
            }
        );
    }
    reducer = (state: any = this.initState, action: {
        type: string
        payload: any
    }) => {
        switch (action.type) {
            case this.actions.reloadData:
                state = new Date();
                break;
            case this.actions.setState:
                state = GLOBAL_FUNCTIONS.SetObject(state, action.payload);
                break;
        }

        return state
    }
}

export const supportPostReducer = new SupportPostReducer();

import React from "react";
import style from "./Radio.module.scss";
import {
    Radio as ComponentMui,
    RadioProps as ComponentMuiProps
} from "@mui/material";

//@ts-ignore
const Container = (props: ComponentMuiProps) => {
    return (
        <div className={style["Radio-Wrapper"]}>
            <ComponentMui {...props} />
        </div>
    );
};

export default Container;

import React from 'react'
import style from './style.module.scss';
import { Container as ComponentMui, ContainerProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps {
    children?: any;
    className?: any
};
const Container = (props:ComponentProps | ComponentMuiProps) => {
    const {children, className} = props;
    return (
            <ComponentMui {...props} className={style['Container-Wraper']  + ` ${className}`}>
                {children}
            </ComponentMui>
    )
}

export default Container;
import { toast } from "react-toastify";

export const errorHandle = (error: any, formik?: any, nameSpace?: string) => {
    try {
        let errorParsed = JSON.parse(error.message);
        toast.error(errorParsed?.message || "Unknow error");
    } catch (err) {
        // console.log('error===', typeof error.message)
        if (error.message.toLowerCase().includes("username is not allowed")) {
            toast.error("Email address is invalid");
        } else if (
            error.message.includes("aviron_subscriptions_machine_id_fkey") || error.message.includes("machine id not found")
        ) {
            toast.error("Machine ID not exists");
        }
        else if(error.message.includes('Challenge has already existed')) {
            toast.error('Challenge has already existed');
        }
        else if(error.message.includes('The year and the month are must be greater than now')) {
            toast.error('The year and the month are must be greater than now');
            setTimeout(()=>{
                formik && formik.setFieldError('month', 'The year and the month are must be greater than now')
                formik && formik.setFieldError('year', 'The year and the month are must be greater than now')
            }, 500)
        }
        else if(String(nameSpace) === String('updateCategory')) {
            if (error.message.includes('no input allowed')) {
                toast.error('Can not delete Category already has a Child Category');
            } else {
              toast.error(error.message);
            }
        }
        else if (error.message.includes("GraphQL error: ")) {
            toast.error(error.message.replace("GraphQL error: ", ""));
        } 
        else {
            toast.error(error.message);
        }
    }
};
import { MenuItem, Select } from "Components/base";
import style from "./GroupCategory.module.scss";
import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { torem } from "styles/theme/toRem";
import { useAdminGameProductCategoryGroupsQuery } from "services";

interface GroupCategoryProps {
  onChange?: Function;
  value?: any;
  otherProps?: any;
}
const GroupCategory = ({
  onChange,
  value,
  otherProps,
}: GroupCategoryProps) => {
  const {
    data,
    loading,
    refetch,
  } = useAdminGameProductCategoryGroupsQuery();

  // console.log('adsadsad', apps)

  return (
    <div className={style["GroupCategory"] + " GroupCategory"}>
      <Select
        name="categoryGroupId"
        placeholder={"All Group"}
        value={value}
        onChange={onChange}
        fullWidth
        {...otherProps}
      >
        {data?.adminGameProductCategoryGroups?.map((item: any, key: any) => {
          return <MenuItem value={item.id}>{item.name}</MenuItem>;
        })}
      </Select>
      {loading && (
        <div className={style["loading"]}>
          <CircularProgress size={torem(30)} />
        </div>
      )}
    </div>
  );
};

export default GroupCategory;

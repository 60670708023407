import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { useFormik } from "formik";
import { get } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  api,
  useCreateSupportMutation,
  useSharedQuery,
  useSupportCategoriesQuery,
  useCreateUploadPhotoUrlMutation,
  useGetSupportOrderMaxQuery,
  useAdminCreateProductMutation,
} from "services";
import { errorHandle } from "utils";
import { errorHandleWithFormik } from "utils/errorHandleWithFormik";
import { formSchema } from "../Add/formSchemal";
import { formatAppRequest } from "../Detail/formatData";
import Swal from "sweetalert2";

const useAddHook = () => {
  const { globalDispatch, global } = React.useContext(AdminAppContext);
  const [supportCategories, setSupportCategories] = useState(null);
  const { data: userLogged } = useSharedQuery();
  const { data: categoriesData } = useSupportCategoriesQuery({
    variables: {
      first: 100,
      offset: 0,
      name: "",
    },
  });
  useEffect(() => {
    if (categoriesData && categoriesData.supportCategories) {
      //@ts-ignore
      setSupportCategories(categoriesData?.supportCategories?.nodes);
    }
  }, [categoriesData]);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const [createProduct] = useAdminCreateProductMutation();
  const [createUploadUrl] = useCreateUploadPhotoUrlMutation();

  const [state, setState] = React.useState<any>({
    selectedCategoryId: null,
    thumb: null,
    selectedFile: null,
  });

  const appFormik = useRef<any>();
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      currency: "",
      category: {id: 0, name: ""},
      gameType: "",
      store: "",
      icon: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      appFormik?.current?.submitForm();
    },
  });
  // console.log({ formikpp: formik });
  // console.log({ levelConditionFormik });

  // console.log('dataCategoriesOrderMax', dataCategoriesOrderMax);

  const [loading, setLoading] = React.useState(false);

  const setBack = () => {
    history.goBack();
  };

  const uploadImage = async (file: any) => {
    const uploadUrlData = await createUploadUrl({
      variables: {
        input: {
          contentType: "image/png",
          name: file.name.slice(file.name, file.name.lastIndexOf(".")),
        },
      },
    });
    await api.util.uploadImage(
      get(uploadUrlData, "data.createUploadPhotoUrl.uploadUrl"),
      {
        key: get(uploadUrlData, "data.createUploadPhotoUrl.fields.key"),
        policy: get(uploadUrlData, "data.createUploadPhotoUrl.fields.policy"),
        "x-goog-algorithm": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_algorithm"
        ),
        "x-goog-credential": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_credential"
        ),
        "x-goog-signature": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_signature"
        ),
        "x-goog-date": get(
          uploadUrlData,
          "data.createUploadPhotoUrl.fields.x_goog_date"
        ),
        "content-type": "image/png",
        acl: "public-read",
        file: file,
      }
    );
    return get(uploadUrlData, "data.createUploadPhotoUrl.publicUrl");
  };

  const handleUploadThumb = (file: File | null) => {
    if (file === null) {
      formik.setFieldValue("icon", "");
    } else {
      formik.setFieldValue("icon", file);
    }
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    await formik.submitForm();
    console.log({ formik, appFormik });
    if (formik.values.name.trim() !== "") {
      if (
        formik.isValid &&
        appFormik?.current?.isValid
      ) {
         // console.log({formik, appFormik});
         setLoading(false);
         const result = await Swal.fire({
           title: 'Are you sure?',
           icon: "warning",
           text: `You won't be able to revert this!`,
           showCancelButton: true,
           confirmButtonText: "Yes, save it!",
         })
         if (!result.isConfirmed) {
           return;
         }
         setLoading(true);
         const appRequest = formatAppRequest(appFormik?.current?.values.lv1FormFields);
         const updateParams = {
           name: formik.values.name,
           price: Number(formik.values.price),
           description: formik.values.description,
           currencyId: Number(formik.values.currency),
           storeId: Number(formik.values.store),
           categoryId: Number(formik.values.category.id),
           imageUrl: formik.values.icon,
           app: appRequest,
         };
        //  console.log({updateParams});
        createProduct({
          variables: {
            input: {
              ...updateParams,
            },
          },
        }).then((res)=>{
          setLoading(false);
          toast.success("Create Game Shop Product Success",{ autoClose: 3000 });
          setTimeout(()=>{
            const path = `${CONSTANTS.NAVIGATION_URL.GAME_SHOP_PRODUCT}/detail/${res?.data?.adminCreateProduct?.id}`;
            history.push({ pathname: path });
          }, 500)
        }).catch((err)=>{
          errorHandle(err)
          setLoading(false)
        }).finally(()=>{
          setLoading(false);
        });
      } else if (!formik.isValid) {
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else if (!appFormik?.current?.isValid) {
        const el = document.getElementById("appFormId");
        el?.scrollIntoView();
        setLoading(false);
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };

  return {
    loading,
    setBack,
    formik,
    supportCategories,
    handleUploadThumb,
    appFormik,
    handleSubmitForms,
  };
};

export default useAddHook;

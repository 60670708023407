import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Typography,
} from "Components/base";
import { makeStyles } from "@mui/styles";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./Add.hook";
import { torem } from "styles/theme/toRem";
import style from "./Add.module.scss";
import { CATEGORY_TYPE } from "consts/gameShop";
import GroupCategory from "Components/GameShopProduct/GroupCategorySelect";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";
import { RingLoading } from "Components/RingLoading/RingLoading";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "12px",
    margin: "12px",
  },
  buttonGroup: {
    margin: "12px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "48%",
  },
}));

const Add = () => {
  const inputLabel = React.useRef(null);
  const classes = useStyles();
  const { loading, setBack, formik } = useAddHook();

  return (
    <div className={style["AddGameShopCategoryWrapper"]}>
      <form>
        <Grid
          container
          spacing={torem(16)}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item>
            <div className="aviron-sub-title pointer" onClick={setBack}>
              Product Category Management &gt; Add Product Category
            </div>
            <div className="aviron-title" role="presentation">
              &nbsp; Add Product Category
            </div>
          </Grid>
          <Grid item className={"buttonGroup"}>
            <ButtonWithLoading
              variant="contained"
              loading={loading}
              type="submit"
              onClick={formik.handleSubmit}
              // startIcon={<SaveIcon />}
              style={{ minWidth: torem(120) }}
            >
              Create
            </ButtonWithLoading>
          </Grid>
        </Grid>
        <div className={style["formWrapper"]}>
          <div className={style["formContainer"]}>
            <div>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  required
                  id="name"
                  label="Name"
                  placeholder="Enter Title"
                  fullWidth
                  name="name"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.name ? true : false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  helperText={
                    // @ts-ignore
                    formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null
                  }
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron select">
                <InputLabel required>Group</InputLabel>
                <GroupCategory
                  value={formik.values.categoryGroupId}
                  onChange={formik.handleChange}
                  otherProps={{
                    placeholder: "Choose Group",
                  }}
                />
                <FormHelperText
                  error={formik.errors.categoryGroupId ? true : false}
                >
                  {formik.errors.categoryGroupId &&
                  formik.touched.categoryGroupId
                    ? formik.errors.categoryGroupId
                    : null}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="form-aviron select">
                <InputLabel>Parent Product Category</InputLabel>
                <ParentProductCategorySelect
                  formik={formik}
                  menuProps={{ width: torem(500) }}
                  ignoreProduct = {true}
                />
                {/* <FormHelperText
                  error={formik.errors.categoryParentId ? true : false}
                >
                  {formik.errors.categoryParentId &&
                  formik.touched.categoryParentId
                    ? formik.errors.categoryParentId
                    : null}
                </FormHelperText> */}
              </FormControl>
            </div>
            <div className={style["rightContent"]}>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="sortOrder"
                  label="Sort Order"
                  placeholder="Enter Sort Order"
                  fullWidth
                  name="sortOrder"
                  margin="normal"
                  variant="outlined"
                  value={formik.values.sortOrder}
                  error={
                    formik.errors.sortOrder && formik.touched.sortOrder
                      ? true
                      : false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.sortOrder}
                  helperText={
                    formik.errors.sortOrder && formik.touched.sortOrder
                      ? formik.errors.sortOrder
                      : null
                  }
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="description"
                  required
                  label="Description"
                  placeholder="Enter Description"
                  fullWidth
                  name="description"
                  margin="normal"
                  variant="outlined"
                  multiline
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                      ? true
                      : false
                  }
                  // required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.description}
                  helperText={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
      </form>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Add;

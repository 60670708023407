export class BaseAction {
    constructor(name: string) {
        this.name = name.toUpperCase()
    }
    name: string
    setState(data: any) {
        return {
            type: `${this.name}_SET_STATE`,
            payload: data
        };
    };
}
import { BaseReducer } from '../base'

class AdminReducer extends BaseReducer {
    constructor() {
        super("ADMIN", {
            current_page: 0
        })
    }
}


const adminReducer = new AdminReducer()

export {
    adminReducer
}
import { UtilAPI } from "./util";
import { MachineAPI } from './machine'
import { ExperienceAPI } from './experience'
import { GameEnviroment, MonthlyChallengeAPI } from './gameServer/monthlyChallenge'
import { getUrlVars } from "utils";
import { spIconUploadAPI } from './spIconUpload'

interface GameServerAPI {
    monthlyChallenge?: MonthlyChallengeAPI
}

class API {
    constructor() {
        // const localGameEnv = localStorage.getItem("gameEnv") as GameEnviroment
        let gameEnv: GameEnviroment = localStorage.getItem("gameEnv") as GameEnviroment;
        const urlVars = getUrlVars()
        if (urlVars.env) {
            switch (urlVars.env) {
                case "dev": case "alpha":
                    gameEnv = urlVars.env;
                    break;
                default:
                    break
            }
            localStorage.setItem("gameEnv", gameEnv)
        } 

        this.util = new UtilAPI()
        this.machine = new MachineAPI()
        this.experience = new ExperienceAPI()
        this.gameServer.monthlyChallenge = new MonthlyChallengeAPI(gameEnv);
        this.spIconUploadAPI = new spIconUploadAPI()
    }
    util: UtilAPI
    machine: MachineAPI
    experience: ExperienceAPI
    gameServer: GameServerAPI = {}
    spIconUploadAPI: spIconUploadAPI
}

export const api = new API()
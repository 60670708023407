import { MenuItem, Select } from "Components/base";
import style from "./AppSelect.module.scss";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { torem } from "styles/theme/toRem";
import { useGetAppsQuery } from "services";
import { mapAppToGroup } from "consts/app";
interface AppSelectProps {
  onChange?: Function;
  value?: any;
  otherProps?: any;
  hidePlaceholder?: any;
}

const AppSelect = ({
  onChange,
  value,
  otherProps,
  hidePlaceholder,
}: AppSelectProps) => {
  const {
    data: apps,
    loading: loadingApps,
    refetch: refetchApps,
  } = useGetAppsQuery();

  // console.log("App Select value", value);

  return (
    <div className={style["AppSelect"] + " AppSelect"}>
      <Select
        name="app"
        // placeholder={hidePlaceholder ? null : "Select a App"}
        value={value}
        onChange={onChange}
        fullWidth
        {...otherProps}
      >
        {apps?.adminGameApps?.map((item: any, key: any) => {
          if (item.image !== "") {
            return (
              //@ts-ignore
              <MenuItem value={ mapAppToGroup[item.id] ? mapAppToGroup[item.id] : item.id}>{`${
                item.groupName ? item.groupName.toUpperCase() + " - " : ""
              }${item.name}`}</MenuItem>
            );
          }
        })}
      </Select>
      {loadingApps && (
        <div className={style["loading"]}>
          <CircularProgress size={torem(30)} />
        </div>
      )}
    </div>
  );
};

export default AppSelect;

import { makeStyles } from "@mui/styles";
const useStyles: any = makeStyles(
    (theme: any) =>
        ({
            root: {
                backgroundColor: theme.palette.background.default,
                height: "100vh",
            },
            grid: {
                height: "100%",
            },
            backgroundContainer: {
                [theme.breakpoints.down("md")]: {
                    display: "none",
                },
            },
            background: {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "url(/login/img/background.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            },
            quoteInner: {
                textAlign: "center",
                flexBasis: "600px",
            },
            quoteText: {
                color: theme.palette.white,
                fontWeight: 300,
            },
            name: {
                marginTop: theme.spacing(3),
                color: theme.palette.white,
            },
            bio: {
                color: theme.palette.white,
            },
            contentContainer: {},
            content: {
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            },
            contentHeader: {
                display: "flex",
                alignItems: "center",
                paddingTop: theme.spacing(5),
                paddingBototm: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            logoImage: {
                marginLeft: theme.spacing(4),
            },
            contentBody: {
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                    justifyContent: "center",
                },
            },
            form: {
                paddingLeft: 100,
                paddingRight: 100,
                paddingBottom: 125,
                flexBasis: 700,
                [theme.breakpoints.down("sm")]: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                },
            },
            title: {
                marginTop: theme.spacing(3),
                marginBottom: "24px !important",
            },
            socialButtons: {
                marginTop: theme.spacing(3),
                width: "100%",
            },
            socialButon: {
                width: "100%",
            },
            socialIcon: {
                marginRight: theme.spacing(1),
            },
            sugestion: {
                marginTop: theme.spacing(2),
            },
            textField: {
                marginTop: theme.spacing(2),
            },
            signInButton: {
                marginTop: "0px !important",
            },
        } as any)
);

export default useStyles;

import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useHistory } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import { useAdminAchievementsQuery, useGetAppsQuery } from "services";
import { useLocation } from "react-router-dom";
import AchivementStatus from "./AchivementStatus";
import exportCsv from "scripts/apps/exportCsv";
import { toast } from "react-toastify";
import { errorHandle, setTimeWithTimeZone } from "utils";
import { APP_ID_ROWER_GROUP, formatAppData } from "consts/app";

const useMainHook = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();

  const machineTypeOptions: {
    value: string;
    label: string;
    name: string;
    active: number;
  }[] = [
    { label: "All", value: "", name: "status", active: 0 },
    { label: "APK", value: "apk", name: "status", active: 1 },
    { label: "C2", value: "c2", name: "status", active: 2 },
  ];

  const [state, setState] = React.useState({
    columns: [
      {
        numeric: false,
        disablePadding: false,
        label: "Name",
        id: "name",
        hasSub: false,
      }, 
      {
        numeric: false,
        disablePadding: false,
        label: 'App',
        id: "appName",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Type",
        id: "type",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Icon Gray",
        id: "iconGray",
        hasSub: false,
        className: "iconCell",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Icon",
        id: "icon",
        hasSub: false,
        className: "iconCell",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Description",
        id: "description",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Create Time",
        id: "createTime",
        type: "date",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Update Time",
        id: "updateTime",
        type: "date",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Status",
        id: "state",
      },
    ],
    rows: [],

    totalCount: 0,

    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });
  const [loading, setLoading] = React.useState(false);

  const getSortParams = (value: string) => {
    if (value === "createAsc") {
      return({
        sortValue: "asc",
        sortBy: "createTime",
      });
    } else if (value === "createDesc") {
      return({
        sortValue: "desc",
        sortBy: "createTime",
      });
    } else if (value === "updateAsc") {
      return({
        sortValue: "asc",
        sortBy: "updateTime",
      });
    } else if (value === "updateDesc") {
      return({
        sortValue: "desc",
        sortBy: "updateTime",
      });
    } else {
      return {
        sortValue: undefined,
        sortBy: undefined,
      }
    }
  }

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchParam = searchParams.get("seach");
  const pageSizeParam = searchParams.get("pageSize") || 10;
  const typeParm = searchParams.get("type") || "";
  const pageParam = searchParams.get("page") || 0;
  const sortParam = searchParams.get("sort") || "updateDesc";
  const appIdParam = searchParams.get("appId") || "";
  const statusParam = searchParams.get("status") || "";
  // console.log({categoryId});
  const [queryParameter, setQueryParameter] = React.useState<any>({
    pageSize: Number(pageSizeParam) || 10,
    page: Number(pageParam) || 0,
    name: searchParam || "",
    type: typeParm || "",
    sort: sortParam,
    appId: appIdParam || APP_ID_ROWER_GROUP,
    status: statusParam || ""
  });
  let {
    data: achivements,
    loading: loadingAchivements,
    refetch: refetchAchivements,
  } = useAdminAchievementsQuery({
    variables: {
      input: {
        page: Number(queryParameter?.page) + 1,
        pageSize: Number(queryParameter?.pageSize),
        appId: Number(queryParameter?.appId) || "",
        sortValue: getSortParams(queryParameter.sort)?.sortValue,
        sortBy: getSortParams(queryParameter.sort)?.sortBy,
        name: queryParameter?.name,
        type: queryParameter?.type,
        status: queryParameter?.status || 'all',
      },
    } as any,
    fetchPolicy: "no-cache",
  });

  const {
    data: apps,
    loading: loadingApps,
    refetch: refetchApps,
  } = useGetAppsQuery({
    variables: {},
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    let urlParams = `?page=${queryParameter.page}&pageSize=${queryParameter.pageSize}`;
    if (queryParameter.appId) {
      urlParams = urlParams + `&appId=${queryParameter.appId}`;
    }
    if (queryParameter.type) {
      urlParams = urlParams + `&type=${queryParameter.type}`;
    }
    if (queryParameter.name) {
      urlParams = urlParams + `&search=${queryParameter.name}`;
    }
    if (queryParameter.sort) {
      urlParams = urlParams + `&sort=${queryParameter.sort}`;
    }
    if (queryParameter.status) {
      urlParams = urlParams + `&status=${queryParameter.status}`;
    }
    history.replace(urlParams);
  }, [queryParameter]);

  React.useEffect(() => {
    if (achivements && achivements?.adminAchievements?.achievements) {
      setState({
        ...state,
        //@ts-ignore
        rows: achivements?.adminAchievements?.achievements.map((item) => {
          return {
            id: item.id,
            name: item?.name || "",
            description: item.description,
            content: item.content,
            explanation: item?.explanation || "",
            type: item?.type,
            icon: <img src={item.icon} alt="Icon" />,
            iconGray: <img src={item.iconGrey} alt="Icon Gray" />,
            state: (
                item?.status ? <AchivementStatus status={item.status} /> : ""
            ),
            createTime: setTimeWithTimeZone(item.createTime).format('MM-DD-YYYY'),
            updateTime: setTimeWithTimeZone(item.updateTime).format('MM-DD-YYYY'),
            appId: item.appId,
            appName: formatAppData(apps?.adminGameApps)?.filter(
              (el: any) => el.id === item.appId
            )[0]?.name || "",
          };
        }),
        totalCount: achivements?.adminAchievements?.metadata?.totalRecords,
      });
    } else {
        setState({
            ...state,
            rows: [],
            totalCount: 0,
        })
    }
  }, [achivements, apps]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({
      ...queryParameter,
      page: Number(newPage),
    });
  };

  const handleChangeApp = async (event: any) => {
    //@ts-ignore
    setQueryParameter({
      ...queryParameter,
      appId: event?.target?.value,
      page: 0,
    });
  };

  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      name: newValue,
      page: 0,
    });
  };

  const handleChangeSort = (event: any) => {
    const value = event.target.value;
    setQueryParameter({
      ...queryParameter,
      sort: value,
    })
  };
  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const onChangeAdvanceFilter = (data: any) => {
    // console.log({data})
    setQueryParameter({
      ...queryParameter,
      type: data.type.value,
      supportCategoryId: data.supportCategoryId.value,
      offset: 0,
      page: 0,
    });
  };

  const onChangeInput = (event: any) => {
    const { value, name } = event.target;
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        [name]: value,
      })
    );
  };

  const handleExportCSV = async() => {
    try {
      setLoading(true);
      await refetchAchivements({
          input: {
            ...queryParameter,
            appId: Number(queryParameter?.appId) || 0,
            page: 1,
            pageSize: 0,
          }
      })
          .then((result) => {
            // console.log({'opopllpl': result});
              const rows: any[] =
                  result?.data?.adminAchievements?.achievements.map((item: any) => {
                      return {
                        id: item.id,
                        name: item?.name || "",
                        description: item.description,
                        content: item.content,
                        explanation: item?.explanation || "",
                        type: item?.type,
                        icon: item.icon,
                        iconGray: item.iconGrey,
                        state: item?.status?.toUpperCase(),
                        appName:
                        formatAppData(apps?.adminGameApps)?.filter(
                          (el: any) => el.id === item.appId
                        )[0]?.name || "",
                      };
                  }) || [];
              exportCsv("achivements", rows);
              toast.success("Export CSV success");
          })
          .catch((err) => {
              errorHandle(err);
              setLoading(false);
          });
  } catch (err) {
      errorHandle(err);
      setLoading(false);
  } finally {
      await refetchAchivements({
        input: {
          ...queryParameter,
          page: 1,
          pageSize: 10,
          appId: Number(queryParameter?.appId) || 0,
        }
      });
      setLoading(false);
  }
  }

  const handleCloseAction = (event: any, id: any) => {
    const products = state.rows.map((x: any) => {
      const item: any = { ...x };
      if (item.id === id) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    });
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows: products,
      })
    );
  };

  const handleViewDetail = (event: any, item: any) => {
    history.push(`${CONSTANTS.NAVIGATION_URL.ACHIEVEMENT}/detail/${item.id}/${item.appId}`);
  };

  const goToSub = (even: any, item: any) => {
    const path = `${CONSTANTS.NAVIGATION_URL.ACHIEVEMENT}/detail/${item.id}/${item.appId}`;
    history.push({ pathname: path });
  };

  const handleAddPost = () => {
    history.push(CONSTANTS.NAVIGATION_URL.ACHIEVEMENT_ADD);
  };

  const handleChangeType = (e: any) => {
    const value = e.target.value;
    setQueryParameter({ ...queryParameter, type: value });
  };

  const handleChangeStatus = (event: any) => {
    const value = event.target.value;
    setQueryParameter({ ...queryParameter, status: value });
  }

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    goToSub,
    action: {
      detail: true,
    },
    handleClose: handleCloseAction,
    handleChangePage,
    handleViewDetail,
    statusTab: {
      options: machineTypeOptions,
      itemActive: machineTypeOptions[0],
    },
    loading: loadingAchivements || loadingApps,
  };
  return {
    propsTable,
    state,
    loading: loadingAchivements || loading,
    handleAddPost,
    handleSearch,
    onSearchKeyDown,
    onChangeInput,
    // onChangeSelect,
    onChangeAdvanceFilter,
    queryParameter,
    handleChangeSort,
    handleChangeType,
    handleChangeApp,
    handleExportCSV,
    handleChangeStatus,
  };
};

export default useMainHook;

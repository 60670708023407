import React from 'react';
import { torem } from 'styles/theme/toRem';
import { isJsonString } from 'utils/isJsonString';
import { FormHelperText, InputLabel } from 'Components/base';
import style from './JsonEditorFormField.module.scss'
//@ts-ignore
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';

interface JsonEditorProps {
  name?: string
  width?:any
  height?:any
  value?: any
  formik: any
  disabled?: boolean
}

const JsonEditor = ({
  name = "metadata",
  width = "100%",
  height = torem(150),
  value,
  formik,
  disabled
}: JsonEditorProps) => {
  const handleChange = (
    plainText : any,
    markupText: any,
    json      : any,
    jsObject  : any,
    lines     : any,
    error     : any
  ) => {
    if (plainText?.jsObject) {
      formik.setFieldValue([name], plainText?.jsObject, true);
    }
  };

  const colorsEditorConf = {
    default: "#000",
    background: "#fcfdfd",
    background_warning: "#feeceb",
    string: "#59a5d8",
    number: "green",
    colon: "black",
    keys: "#fa7921",
    keys_whiteSpace: "#fa7921",
    primitive: "blue"
  }
  return (
    <div className={style["JsonEditorFormField"]}>
      <InputLabel>Metadata field</InputLabel>
      {disabled && <div className={style["disableEditor"]}></div>}
      <JSONInput
        id={name}
        colors={colorsEditorConf}
        locale={locale}
        height={height}
        width={width}
        placeholder={value}
        onChange={handleChange}
        style={{ body: { fontSize: 14 } }}
      />
    </div>
  );
};

export default JsonEditor;
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useHistory } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import {
  useAdminBetaUsersQuery,
  useAdminDeleteBetaUserMutation,
} from "services";
import { gameBetaUserConstant } from "./GameBetaUser.constant";
import { setTimeWithTimeZone } from "utils";
import { ReactComponent as TrashIcon } from "Components/base/Icons/Trash-icon.svg";
import { handleRenderNotification } from "utils/handleRenderNoti";
import exportCsv from "scripts/apps/exportCsv";
import Swal from "sweetalert2";
import TrashDelIcon from "Components/base/Icons/TrashDelIcon.svg";
import useQueryParams from "utils/useQueryParams";

interface BetaUserItem {
  userId: string;
  customId: string;
  username: string;
  email: string;
  role: string;
  updateTime: Date;
}

const handleFormatDataBetaUsers = (value: any) => {
  const { betaUsers = [], metaData = {} } = value?.adminBetaUsers || {};
  return {
    totalCount: metaData?.totalRecords,
    dataFormat: betaUsers.map((item: BetaUserItem) => ({
      userId: item?.userId || "",
      customId: item?.customId || "",
      username: item?.username || "",
      email: item?.email || "",
      role: item?.role || "",
      updateTime: item?.updateTime || "",
    })),
  };
};

export const useActiveSidebarBetaUser = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER,
        })
      );
    }
  }, [global.current_page, globalDispatch]);
};

const useMainHook = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);

  const [deleteBetaUserMutation, { loading: loadingMutation }] =
    useAdminDeleteBetaUserMutation();
  const [isLoadingExportData, setIsLoadingExportData] = React.useState(false);

  const history = useHistory();

  const [state, setState] = React.useState({
    columns: gameBetaUserConstant,
    rows: [],
    totalCount: 0,
    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });

  const query = useQueryParams();
  const { page = 0, first = 10, offset = 0, search } = query;

  const [queryParameter, setQueryParameter] = React.useState<any>({
    first: Number(first) || 10,
    offset: Number(offset) || 0,
    page: Number(page) || 0,
    title: search?.trim() || "",
  });

  const { data, loading, refetch } = useAdminBetaUsersQuery({
    variables: {
      page: queryParameter.page + 1,
      pageSize: queryParameter.first,
      search: queryParameter.title || "",
    },
    fetchPolicy: "no-cache",
  });

  const dataFormatted = React.useMemo(() => {
    return handleFormatDataBetaUsers(data);
  }, [data]);

  React.useEffect(() => {
    let urlParams = `?offset=${queryParameter.offset}&first=${queryParameter.first}`;
    if (queryParameter.page >= 0) {
      urlParams = urlParams + `&page=${queryParameter.page}`;
    }
    if (queryParameter.title) {
      urlParams = urlParams + `&search=${queryParameter.title}`;
    }
    history.replace(urlParams);
    refetch({
      page: queryParameter.page + 1,
      pageSize: queryParameter.first,
      search: queryParameter.title || "",
    });
  }, [queryParameter]);

  React.useEffect(() => {
    if (dataFormatted) {
      const { dataFormat, totalCount } = dataFormatted;
      setState({
        ...state,
        rows: dataFormat.map((item: BetaUserItem) => {
          const { customId, username, email = "--", updateTime } = item || {};
          return {
            id: customId,
            username,
            email,
            created_at: setTimeWithTimeZone(updateTime).format("MM/DD/YYYY"),
            action: (
              <>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleConfirmDeleteUser(item)}
                >
                  <TrashIcon />
                </div>
              </>
            ),
          };
        }),
        totalCount,
      });
    }
  }, [dataFormatted]);

  // React.useEffect(() => {
  //   if (!openAddModal) {
  //     handleClickReset();
  //   }
  // }, [openAddModal]);

  const handleExportCsv = async () => {
    try {
      setIsLoadingExportData(true);
      await refetch({
        page: 1,
        pageSize: 0,
        search: queryParameter.title.trim(),
      })
        .then((result: any) => {
          const dataExportFormatted = handleFormatDataBetaUsers(result.data);
          const { dataFormat } = dataExportFormatted;
          const rows = dataFormat.map((item: BetaUserItem) => {
            const {
              userId = "",
              username = "",
              email = "",
              updateTime,
            } = item || {};
            return {
              id: userId,
              username,
              email,
              joinAt: setTimeWithTimeZone(updateTime).format("MM/DD/YYYY"),
            };
          });
          exportCsv("beta-users", rows);
          handleRenderNotification({
            message: "Export CSV successfully",
            status: "success",
          });
          refetch({
            page: queryParameter.page + 1,
            pageSize: queryParameter.first,
            search: queryParameter.title.trim(),
          });
        })
        .catch((err: any) => {
          handleRenderNotification({
            message: "Export CSV failed",
            status: "error",
          });
          setIsLoadingExportData(false);
        });
    } catch (err) {
      handleRenderNotification({
        message: "Export CSV failed",
        status: "error",
      });
    } finally {
      setIsLoadingExportData(false);
    }
  };

  const handleChangePage = (newPage: number) => {
    setQueryParameter({
      ...queryParameter,
      offset: queryParameter.first * newPage,
      page: newPage,
    });
  };

  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      title: newValue,
      page: 0,
      offset: 0,
      first: 10,
    });
  };

  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const onChangeInput = (event: any) => {
    const { value, name } = event.target;
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        [name]: value,
      })
    );
  };

  const handleCloseAction = (event: any, id: any) => {
    const products = state.rows.map((x: any) => {
      const item: any = { ...x };
      if (item.id === id) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    });
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows: products,
      })
    );
  };

  const handleClickReset = () => {
    setQueryParameter({
      ...queryParameter,
      first: 10,
      offset: 0,
      page: 0,
      title: "",
    });
  };

  const handleConfirmDeleteUser = async (item: any) => {
    const { email = "", customId = "" } = item || {};
    const result = await Swal.fire({
      title: "Remove Beta User",
      html: `<div>
            Do you want to remove beta user with Email<br />
            <b>${email}</b></div>`,
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: "#E60A32",
      imageUrl: TrashDelIcon,
    });
    if (!result.isConfirmed) {
      return;
    }

    try {
      deleteBetaUserMutation({
        variables: {
          customId: customId,
        },
      }).then((res) => {
        const { errors } = res || {};
        if (errors) {
          handleRenderNotification({
            message: "Delete user failed",
            status: "error",
          });
          handleClickReset();

          return;
        }
        handleRenderNotification({
          message: "Delete user successfully",
          status: "success",
        });
        handleClickReset();
      });
    } catch (error) {
      handleRenderNotification({
        message: "Delete user failed",
        status: "error",
      });
    }
  };

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },

    handleClose: handleCloseAction,
    handleChangePage,
    loading,
  };
  return {
    propsTable,
    loading: loading || loadingMutation || isLoadingExportData,
    handleSearch,
    onSearchKeyDown,
    onChangeInput,
    queryParameter,
    openAddModal,
    setOpenAddModal,
    handleClickReset,
    handleExportCsv,
    refetch,
  };
};

export default useMainHook;

import React from "react";
interface Iprops {
  className?: string
}
const RejectIcon = ({className}: Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0002 8.21814L5.24692 3.46484L3.46484 5.24692L8.21814 10.0002L3.46484 14.7535L5.24692 16.5356L10.0002 11.7823L14.7535 16.5356L16.5356 14.7535L11.7823 10.0002L16.5356 5.24692L14.7535 3.46484L10.0002 8.21814Z"
        fill="#666666"
      />
    </svg>
  );
};

export default RejectIcon;

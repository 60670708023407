import React from "react";
import style from "./style.module.scss";
import {
    Checkbox as ComponentMui,
    CheckboxProps as ComponentMuiProps,
} from "@mui/material";

interface ComponentProps extends ComponentMuiProps {
    className?: any;
    label?: any
}
const Checkbox = (props: ComponentProps) => {
    const { className } = props;
    return (
        <div className={style["Checkbox-Wrapper"]}>
            <ComponentMui {...props} className={`${className}`}/>
            {props?.label && (
                <div className={style["label"]}>{props.label}</div>
            )}
        </div>
    );
};

export default Checkbox;

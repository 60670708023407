import { BaseReducer } from "../base";

class UserReducer extends BaseReducer {
    constructor() {
        super(
            "USER",
            {
                search: "",
                options: {
                    orderBy: {
                        value: "CREATED_AT_DESC",
                        label: "Joined At DESC",
                        name: "orderBy",
                    },
                },
                currentPage: 0,
            },
            {}
        );
    }
}

export const userReducer = new UserReducer();

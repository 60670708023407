import { BaseReducer } from "../base";

class ExperienceReducer extends BaseReducer {
    constructor() {
        super(
            "EXPERIENCE",
            {
                search: "",
                currentPage: 0,
                advancedFilter: {
                    category: {
                        value: "",
                        label: "All",
                    },
                    difficulty: {
                        value: "",
                        label: "All",
                    },
                    publicStatus: {
                        value: [true, false],
                        label: "All",
                    },
                },
            },
            {}
        );
    }
}

export const experienceReducer = new ExperienceReducer();

import { Button, FormControl, Grid, MenuItem, Select } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Main.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import { torem } from "styles/theme/toRem";
import { CATEGORY_GROUP, CATEGORY_TYPE } from "consts/gameShop";
import GroupCategory from "Components/GameShopProduct/GroupCategorySelect";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";

export const Main: React.FunctionComponent = () => {
    const {
        propsTable,
        state,
        loading,
        handleAddPost,
        handleSearch,
        handleChangeGroup,
        queryParameter,
        handleChangeParentProductCategory,
    } = useMainHook();
    return (
      <div>
        <Grid
          container
          spacing={2}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item xs={12} md={6} lg={3}>
            <div className="aviron-title">Product Category Management</div>
          </Grid>
          <Grid item xs={12} md={6} lg={9} className="buttonGroup">
            <Button
              variant="contained"
              color="secondary"
              className="btn-primary"
              onClick={handleAddPost}
              startIcon={<AddIcon />}
              style={{ minWidth: torem(120) }}
            >
              ADD CATEGORY
            </Button>
          </Grid>
        </Grid>
        <div className="bodyMainContentWrapper">
          <ListTable
            content={<CmsTableAdmin {...propsTable} />}
            header={{
              title: "Category List",
              inputSearch: (
                <InputDelay
                  name="input_search"
                  handleSearch={handleSearch}
                  props={{
                    placeholder: "Search by Title",
                    type: "text",
                  }}
                />
              ),
              selectFilter: (
                <>
                  <GroupCategory
                    value={queryParameter.categoryGroupId}
                    onChange={handleChangeGroup}
                  />
                  <div style={{width: torem(200)}}>
                    <ParentProductCategorySelect
                      placeholder={"All Parent Category"}
                      onChange={handleChangeParentProductCategory}
                      initValue={{
                        name: queryParameter.parentCategoryName,
                        id: queryParameter.parentCategory
                      }}
                    />
                  </div>
                </>
              ),
            }}
          />
        </div>
        {loading ? <RingLoading /> : null}
      </div>
    );
};

import { BaseReducer } from "../base";

class GameLevelsReducer extends BaseReducer {
    constructor() {
        super(
            "GAMELEVELS",
            {
                search: "",
                options: {
                    gameType: 0,
                },
                currentPage: 0,
            },
            {}
        );
    }
}

export const gameLevelsReducer = new GameLevelsReducer();

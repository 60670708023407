// export const torem = (size: number) => {
//   // const windowWidth = window.innerWidth;
//   // return Math.round(size * (windowWidth/1920)) + 'px';
//   const vwContext = 1920*.01;
//   const res = Math.ceil((size/vwContext)*1000000)/1000000
//   return res + 'vw';
// }
export const torem = (size: number) => {
  const baseSize = 14;
  return Math.round((size/baseSize)*100000)/100000 + 'rem';
}
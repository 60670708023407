import { Action, AdminAppContext } from "contexts";
import { set } from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ENV } from 'scripts/apps/environment'
import { useGetGameLevelMasterDataQuery, useDeleteAnGameLevelsMutation, useUpdateGameLevelsMutation } from "services/graphql/crm_server";
import { toast } from "react-toastify";
import { CONSTANTS } from "consts";

type masterType = { gameTypes: any[], gameLevels: any[], requirementTypes: any[], buffTypes: any[] }
type gameLevelType = {
  game_type: number;
  level: number;
  level_up_require: {
    type: number;
    value: number;
  };
  buff: {
    [key: number]: number;
  };
  open: boolean;
}

const context = {
  clientName: ENV.CRM_SERVER_CONTEXT
}

const useMainHook = () => {
  const {
    gameLevelsStore,
    gameLevelsDispatch,
    global,
    globalDispatch } = React.useContext(AdminAppContext);

  const [rows, setRows] = React.useState([] as gameLevelType[]);
  const [masterData, setMasterData] = React.useState({} as masterType);

  React.useMemo(() => {
    if (
      global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_LEVELS
    ) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_LEVELS,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const [deleteAnGameLevels] = useDeleteAnGameLevelsMutation()
  const [updateGameLevels] = useUpdateGameLevelsMutation()

  const { data, loading } = useGetGameLevelMasterDataQuery({
    context
  });

  React.useMemo(() => {
    if (data && data.getGameLevelMasterData) {
      const dataRows = (data.getGameLevelMasterData.gameLevels || []).filter((el: any) => el.game_type == gameLevelsStore.options.gameType as any).map((e: any) => {
        return {
          game_type: e.game_type || gameLevelsStore.options.gameType,
          level: e.level,
          level_up_require: e.level_up_require,
          buff: e.buff,
          open: false,
        }
      }).sort((a: any, b: any) => a.level - b.level)
      setRows(dataRows);
      setMasterData(data.getGameLevelMasterData as masterType)
    }
  }, [data]);

  const history = useHistory();
  const columns = [
    {
      numeric: false,
      disablePadding: false,
      label: "Level",
      id: "level",
      underline: true,
      hasSub: true,
    },
    {
      numeric: false,
      disablePadding: false,
      label: "Level Up Require",
      id: "level_up_require",
    },
    {
      numeric: false,
      disablePadding: false,
      label: "Buff",
      id: "buff",
    }
  ]


  const handleClose = (event: any, level: any) => {
    setRows(rows.map((x) => {
      const item: any = { ...x };
      if (item.level === level) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    })
    )
  };
  const handleDelete = async (level: any) => {
    const newRows = rows.filter(e => e.level != level.level)
    console.log('handleDelete', newRows)
    console.log('handleDelete', level)
    setRows(newRows)
    const lvlToDel = masterData.gameLevels.find(e => e.level == level.level && e.game_type == level.game_type)
    if (lvlToDel) {
      console.log('handleDelete', {
        data: {
          game_type: lvlToDel.game_type,
          level: lvlToDel.level
        }
      })
      await deleteAnGameLevels({
        variables: {
          data: {
            game_type: lvlToDel.game_type,
            level: lvlToDel.level
          }
        },
        context
      })
      toast.success("Delete game level success");
    }
  }

  const onChangeGameType = (event: any) => {
    const { value } = event.target;
    gameLevelsDispatch(
      Action.gameLevels.setState(set(gameLevelsStore, `options.gameType`, value))
    );
    setRows(
      masterData.gameLevels.filter(el => el.game_type == value).map(e => {
        return {
          game_type: e.game_type,
          level: e.level,
          level_up_require: e.level_up_require,
          buff: e.buff,
          open: false,
        }
      }).sort((a: any, b: any) => a.level - b.level)
    )
  }
  const onChangeLvlUpRequire = (event: any, level: any) => {
    const { value } = event.target;
    const newRows = rows.map((item: any) => {
      if (item.level == level) {
        item.level_up_require.value = +value;
      }
      return item;
    })

    const idx = rows
    setRows(newRows)
  };
  const onChangeRequirementType = (event: any, level: any) => {
    const { value } = event.target;
    const newRows = rows.map((item: any) => {
      if (item.level == level) {
        item.level_up_require.type = value;
      }
      return item;
    })
    setRows(newRows)
  };

  const onChangeBuff = (event: any, id: number, level: any) => {
    const { value } = event.target;
    setRows(rows.map((x: any) => {
      const item: any = { ...x };
      if (item.level == level) {
        item.buff[id] = +value;
      }
      return item;
    }))
  }
  const handleAddMore = async () => {
    const newLevel = rows.length + 1
    const lastRow = rows[rows.length - 1]
    const newRow = Object.assign({}, {
      level_up_require: {
        type: 1,
        value: 0
      },
      buff: { ...lastRow.buff },
      open: false,
      game_type: gameLevelsStore.options.gameType,
      level: newLevel,
    })
    setRows([...rows, newRow])
  }
  const handleSave = async () => {
    await updateGameLevels({
      variables: {
        data: rows.map(e => {
          return {
            buff: e.buff,
            game_type: e.game_type,
            level: e.level,
            level_total_require: [],
            level_up_require: e.level_up_require!
          }
        })
      },
      context
    })
    toast.success("Save game levels success");
  }

  const gotoBuff = () => {
    history.push(
      CONSTANTS.NAVIGATION_URL.GAME_LEVELS_BUFF
    );
  }

  const gotoRequirements = () => {
    history.push(
      CONSTANTS.NAVIGATION_URL.GAME_LEVELS_REQUIREMENTS
    );
  }


  return {
    columns,
    loading,
    rows,
    gameTypesOptions: masterData.gameTypes,
    onChangeGameType,
    gameLevelsStore,
    gameLevelsMasterData: masterData.gameLevels,
    requirementTypes: masterData.requirementTypes,
    buffTypes: masterData.buffTypes,
    handleClose,
    handleDelete,
    onChangeLvlUpRequire,
    // requirementTypesOptions,
    onChangeRequirementType,
    onChangeBuff,
    handleAddMore,
    handleSave,
    gotoBuff,
    gotoRequirements,
  };
};

export default useMainHook;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as React from "react";
import { ENV } from "scripts/apps/environment";
import style from './StripeWrapper.module.scss';

export const StripeWrapper: React.FunctionComponent = ({ children }) => {
    const stripePromise = loadStripe(ENV.PAYMENT.STRIPE_PUBLIC_KEY);
    return (
        <div className={style["StripeWrapper"]}>
            <Elements stripe={stripePromise}>{children}</Elements>
        </div>
    );
};

import React from "react";
import { Route, Switch } from "react-router-dom";
import { Requirements } from "./Requirements/Requirements";
import { Buff } from "./Buff/Buff";
import { Main } from "./Main/Main";
import { CONSTANTS } from "consts";

const GameLevels: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_LEVELS_REQUIREMENTS}
                component={Requirements}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_LEVELS_BUFF}
                component={Buff}
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_LEVELS}
                component={Main}
            />
        </Switch>
    );
};
export default GameLevels;

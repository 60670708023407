import { createTheme } from '@mui/material';

import palette from './palette';
import typography from './typography';
import {components} from './components'

const theme = createTheme({
    palette,
    typography,
    components: components,
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
});

export default theme;

import React from 'react'
import style from './style.module.scss';
import { Avatar as ComponentMui, AvatarProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps extends ComponentMuiProps {
    children?: any;
    className?: any
};
const Avatar = (props:ComponentProps) => {
    const {children, className} = props;
    return (
            <ComponentMui {...props} className={style['Avatar-Wraper'] + ` ${className}`}>
                {children}
            </ComponentMui>
    )
}

export default Avatar;
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import { set } from "lodash";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import GLOBAL_FUNCTIONS from "scripts/apps/global-functions";
import {
  useAdminGameProductCategoryGroupsQuery,
  useAdminProductCategoriesQuery,
  useSupportCategoriesQuery,
} from "services";
import { setTimeWithTimeZone } from "utils";

const useMainHook = () => {
  const {
    global,
    supportCategoryStore,
    globalDispatch,
    supportCategoryDispatch,
  } = React.useContext(AdminAppContext);

  React.useLayoutEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const params: any = useParams();
  // console.log("pppppp", params);

  const [state, setState] = React.useState({
    columns: [
      {
        numeric: false,
        disablePadding: false,
        label: "Name",
        id: "name",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Description",
        id: "description",
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Group",
        id: "categoryGroupId",
        hasSub: false,
      },
      {
        numeric: false,
        disablePadding: false,
        label: "Sort Order",
        id: "sortOrder",
        hasSub: false,
      },
      {
        numeric: true,
        disablePadding: false,
        label: "Parent Category Name",
        id: "parentCategory",
        hasSub: false,
      },
    ],
    rows: [],

    totalCount: 0,

    import_file: false,
    open_import: false,
    selected: {
      value: "",
      label: "All",
    },
  });

  const {
    data: ProductCategoryGroups,
    loading: loadingProductCategoryGroups,
    refetch: refetchProductCategoryGroups,
  } = useAdminGameProductCategoryGroupsQuery();

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const searchParam = searchParams.get("search") || "";
  const pageSizeParam = searchParams.get("pageSize") || 10;
  const pageParam = searchParams.get("page") || 0;
  const sortParam = searchParams.get("sortOrder") || 1;
  const categoryGroupIdParam = searchParams.get("categoryGroupId") || "";

  const [queryParameter, setQueryParameter] = React.useState<any>({
    pageSize: Number(pageSizeParam) || 10,
    page: Number(pageParam) || 0,
    name: searchParam || "",
    sortOrder: Number(sortParam),
    categoryGroupId: Number(categoryGroupIdParam) || "",
  });

  React.useEffect(() => {
    let urlParams = `?tab=childCategory&page=${queryParameter.page}&pageSize=${queryParameter.pageSize}`;
    if (queryParameter.name) {
      urlParams = urlParams + `&search=${queryParameter.name}`;
    }
    if (queryParameter.sortOrder) {
      urlParams = urlParams + `&sortOrder=${queryParameter.sortOrder}`;
    }
    if (queryParameter.categoryGroupId) {
      urlParams =
        urlParams + `&categoryGroupId=${queryParameter.categoryGroupId}`;
    }
    history.replace(urlParams);
  }, [queryParameter]);

  const { data, loading, refetch } = useAdminProductCategoriesQuery({
    variables: {
      input: {
        ...queryParameter,
        categoryParentId: Number(params.id),
        categoryGroupId: Number(queryParameter.categoryGroupId),
        page: Number(queryParameter.page) + 1,
      },
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (data && data.adminProductCategories) {
      setState({
        ...state,
        //@ts-ignore
        rows: data?.adminProductCategories?.data.map((item) => {
          const GroupCategory =
            ProductCategoryGroups?.adminGameProductCategoryGroups?.filter(
              (el: any) => el.id == item.categoryGroupId
            ) || [];
          return {
            id: item?.id,
            name: item?.name,
            description: item?.description,
            // categoryGroupId: item?.categoryGroupId || "",
            categoryGroupId: GroupCategory[0]?.name || "",
            sortOrder: item?.sortOrder || "",
            parentCategory: item?.parentCategory
              ? item?.parentCategory?.name
              : "",
          };
        }),
        totalCount: data.adminProductCategories.metadata.totalRecords,
      });
    }
  }, [data]);

  const handleChangePage = (newPage: number) => {
    setQueryParameter({
      ...queryParameter,
      page: newPage,
    });
  };
  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      page: 0,
      name: newValue,
    });
  };

  const onChangeSearchValue = (value: any) => {
    supportCategoryDispatch(
      Action.supportCategory.setState(
        set(supportCategoryStore, `search`, value)
      )
    );
  };
  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const handleCloseAction = (event: any, id: any) => {
    const products = state.rows.map((x: any) => {
      const item: any = { ...x };
      if (item.id === id) {
        item.open = item.open !== undefined ? !item.open : true;
      } else {
        item.open = false;
      }
      return item;
    });
    setState(
      GLOBAL_FUNCTIONS.SetObject(state, {
        rows: products,
      })
    );
  };

  const handleViewDetail = (event: any, item: any) => {
    history.push(`${CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY}/detail/${item.id}`);
  };

  const goToSub = (even: any, item: any) => {
    const path = `${CONSTANTS.NAVIGATION_URL.GAME_SHOP_CATEGORY}/detail/${item.id}`;
    history.push({ pathname: path });
  };

  const handleChangeGroup = (event: any) => {
    const newValue = event.target.value;
    setQueryParameter({
      ...queryParameter,
      categoryGroupId: newValue,
    });
  };

  const propsTable = {
    data: {
      ...state,
      currentPage: queryParameter.page,
    },
    goToSub,
    action: {
      detail: true,
    },
    handleClose: handleCloseAction,
    handleChangePage,
    handleViewDetail,
  };
  return {
    propsTable,
    state,
    loading,
    handleSearch,
    onSearchKeyDown,
    onChangeSearchValue,
    queryParameter,
    handleChangeGroup,
  };
};

export default useMainHook;

import { Button, Grid } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook, { useActiveSidebarBetaRequest } from "./Main.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import ListTable from "Components/ListTable";
import { torem } from "styles/theme/toRem";
import { ReactComponent as RefreshIcon } from "Components/base/Icons/Refresh.svg";
import { selectOptionsFilterDate } from "./GameBetaRequest.constant";
import Select from "Components/base/SelectAutoComplete";

const Main: React.FunctionComponent = () => {
  const {
    propsTable,
    loading,
    handleSearch,
    handleClickRefresh,
    onChangeSelect,
  } = useMainHook();
  const { orderBy } = propsTable;

  useActiveSidebarBetaRequest();
  return (
    <div>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div className="aviron-title">Manage Beta Request</div>
        </Grid>
        <Grid item xs={12} md={8} lg={9} className="buttonGroup">
          <Button
            className="btn-default"
            onClick={handleClickRefresh}
            startIcon={<RefreshIcon />}
            style={{ marginRight: torem(16) }}
          >
            RESET
          </Button>
        </Grid>
      </Grid>
      <div className="bodyMainContentWrapper">
        <ListTable
          content={<CmsTableAdmin {...propsTable} />}
          header={{
            title: "Beta Request List",
            inputSearch: (
              <InputDelay
                name="input_search"
                handleSearch={handleSearch}
                props={{
                  placeholder: "Search by email or username ...",
                  type: "text",
                }}
              />
            ),
            selectFilter: (
              <Select
                value={orderBy}
                options={selectOptionsFilterDate}
                onChange={onChangeSelect}
                inputProps={{ name: "orderBy" }}
                fullWidth
              />
            ),
            statusTab: propsTable?.statusTab,
          }}
        />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Main;

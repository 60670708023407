import React from "react";
import style from "./Select.module.scss";
import {
    Select as ComponentMui,
    SelectProps as ComponentMuiProps,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MenuItem from "../Menu/MenuItem";
import { torem } from "styles/theme/toRem";

interface ComponentProps extends ComponentMuiProps {
    children?: any;
    placeholder?:string
    value?:any
}
const Typography = (props: ComponentProps | ComponentMuiProps) => {
    const { children, placeholder, value } = props;
    return (
        <div className={style["Select-Wraper"] + " Select-Wraper"}>
            {value === "" && (
                <div className={style["placeholder"] + ' placeholderSelect'}>{placeholder}</div>
            )}
            <ComponentMui
                {...props}
                //@ts-ignore
                IconComponent={ExpandMore}
                value={value}
                className={value==="" ? style['placeholderInput'] : ""}
                MenuProps={{
                    sx: {
                        maxHeight: torem(350)
                    },
                }}
            >
                {placeholder && (
                    <MenuItem value={""} style={{ color: "#808080" }}>
                        {placeholder}
                    </MenuItem>
                )}
                {children}
            </ComponentMui>
        </div>
    );
};

export default Typography;

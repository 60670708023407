import React, { ReactChildren } from 'react'
import style from './style.module.scss';
import { Input as ComponentMui, InputProps as ComponentMuiProps } from '@mui/material';

interface ComponentProps extends ComponentMuiProps {};
const InputLabel = (props:ComponentProps) => {
    return (
        <ComponentMui {...props} className={style['InputLabel-Wraper']} />
    )
}

export default InputLabel;
import {
    Button,
    FormControl,
    Grid,
    Link,
    TextField,
    Typography,
} from "Components/base";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useLoginHook from "./Login.hook";
import useStyles from "./Login.styles";

const Login: React.FunctionComponent = () => {
    const classes: any = useStyles();
    const { handleSignIn, hasError, formState, handleChange, isProcessing } =
        useLoginHook();
    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.backgroundContainer} item lg={8}>
                    <div className={classes.background} />
                </Grid>
                <Grid className={classes.content} item lg={4} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentHeader}></div>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={handleSignIn}
                            >
                                <Typography
                                    className={classes.title}
                                    variant="h2"
                                >
                                    Sign in
                                </Typography>
                                <FormControl fullWidth className="form-aviron">
                                    <TextField
                                        error={hasError("email")}
                                        fullWidth
                                        helperText={
                                            hasError("email")
                                                ? formState.errors.email[0]
                                                : null
                                        }
                                        label="Email address"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        type="text"
                                        value={formState.values.email || ""}
                                        variant="outlined"
                                        data-cy="login-email-field"
                                        margin="normal"
                                    />
                                </FormControl>
                                <FormControl fullWidth className="form-aviron">
                                    <TextField
                                        error={hasError("password")}
                                        fullWidth
                                        helperText={
                                            hasError("password")
                                                ? formState.errors.password[0]
                                                : null
                                        }
                                        label="Password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleChange}
                                        type="password"
                                        value={formState.values.password || ""}
                                        variant="outlined"
                                        data-cy="login-password-field"
                                        margin="normal"
                                    />
                                </FormControl>
                                {isProcessing ? (
                                    <Button
                                        className={classes.signInButton}
                                        color="primary"
                                        // disabled={!formState.isValid}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            marginTop: "16px",
                                        }}
                                    >
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </Button>
                                ) : (
                                    <Button
                                        className={classes.signInButton}
                                        color="primary"
                                        // disabled={!formState.isValid}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        data-cy="login-submit-button"
                                        sx={{
                                            marginTop: "16px",
                                        }}
                                    >
                                        Sign in now
                                    </Button>
                                )}
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                    sx={{
                                        marginTop: "8px",
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/forgot-password"
                                        variant="h6"
                                    >
                                        Forgot or set password?
                                    </Link>
                                </Typography>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default Login;

import React, { ReactChildren } from "react";
import style from "./style.module.scss";
import {
    FormControl as ComponentMui,
    FormControlProps as ComponentMuiProps,
} from "@mui/material";

interface ComponentProps {
    children?: any;
    className?:any
}
const FormControl = (props: ComponentProps | ComponentMuiProps) => {
    const { children, className } = props;
    return (
        <ComponentMui {...props} className={style["FormControlWrapper"] + ` ${className}`}>
            {children}
        </ComponentMui>
    );
};

export default FormControl;

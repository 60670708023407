import React, { useEffect, useState, Suspense } from "react";
import style from "./ConditionForm.module.scss";
import AddIcon from "Components/base/Icons/AddIcon";
import Collapse from "../Collapse";
import { Field, FieldArray, Form, Formik, useFormik } from "formik";
import { RingLoading } from "Components/RingLoading/RingLoading";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "Components/base";
import _ from "lodash";
import * as Yup from "yup";
import { ACCHIVEMENT_TYPE } from "consts/achivement";
import AppSelect from "Components/MonthlyChallenge/AppSelect";
import GameTypeSelect from "Components/GameShopProduct/GameTypeSelect";

interface ConditionFormProps {
  initValues?: any;
  desc?: string | any;
  formik?: any;
  isViewForm?: boolean;
  isDisableApp?: boolean;
}

const validationSchema = Yup.object().shape({
  lv1FormFields: Yup.array().of(
    Yup.object().shape({
      app: Yup.string().required("App is required"),
      order: Yup.string().required("Sort Order is required"),
      clientIndex: Yup.string().required("Client Index is required"),
      new: Yup.bool().required(""),
      gameType: Yup.string().required("Game Type is required"),
    })
  ),
});

const ConditionForm = ({
  initValues,
  desc,
  formik,
  isViewForm,
  isDisableApp,
}: ConditionFormProps) => {
  // console.log({initValues});
  return (
    <div className={style["ConditionForm"] + " ConditionForm"}>
      <Suspense fallback={<RingLoading />}>
        <div className={style["description"]}>{desc}</div>
        <Formik
          onSubmit={(values) => {}}
          initialValues={initValues}
          validationSchema={validationSchema}
          //@ts-ignore
          innerRef={formik}
        >
          {({ values, setValues, errors, touched }: any) => (
            <>
              {values?.lv1FormFields ? (
                <Form>
                  <FieldArray
                    name="lv1FormFields"
                    render={(arrayHelpers) => (
                      <>
                        {values.lv1FormFields.length > 0 &&
                          values.lv1FormFields.map(
                            (lv1FormField: any, index: any) => {
                              const fieldErrors =
                                (!_.isEmpty(errors) &&
                                  errors?.lv1FormFields?.length &&
                                  errors.lv1FormFields[index]) ||
                                {};
                              const fieldTouched =
                                (!_.isEmpty(touched) &&
                                  touched.lv1FormFields?.length &&
                                  touched.lv1FormFields[index]) ||
                                {};
                              return (
                                <div key={index}>
                                  <div
                                    className={
                                      style["conditionCard"] +
                                      " " +
                                      style["conditionGroupForm"]
                                    }
                                  >
                                    {values?.lv1FormFields?.length > 0 && (
                                      <Collapse
                                        //@ts-ignore
                                        headerText={`App ${index + 1}`}
                                        defaultOpen={true}
                                        removeIconProps={
                                          !isViewForm
                                            ? {
                                                action: () =>
                                                  arrayHelpers.remove(index),
                                                text: "Remove App",
                                              }
                                            : null
                                        }
                                      >
                                        <div className={style["fieldRow"]}>
                                          <Field
                                            name={`lv1FormFields.${index}.app`}
                                            render={({ field }: any) => {
                                              return (
                                                <FormControl
                                                  fullWidth
                                                  className="form-aviron select"
                                                >
                                                  <InputLabel required>
                                                    App
                                                  </InputLabel>
                                                  <AppSelect
                                                    otherProps={{
                                                      style: {
                                                        width: "100%",
                                                      },
                                                      inputProps: {
                                                        disabled: isViewForm,
                                                      },
                                                      fullWidth: true,
                                                      placeholder:
                                                        "Select a App",
                                                      ...field,
                                                    }}
                                                  />
                                                  <FormHelperText
                                                    error={
                                                      !_.isEmpty(
                                                        fieldErrors?.app
                                                      )
                                                    }
                                                  >
                                                    {fieldErrors?.app
                                                      ? // && fieldTouched?.conditionType
                                                        fieldErrors?.app
                                                      : null}
                                                  </FormHelperText>
                                                </FormControl>
                                              );
                                            }}
                                          />
                                          <Field
                                            name={`lv1FormFields.${index}.gameType`}
                                            render={({ field }: any) => (
                                              <FormControl
                                                fullWidth
                                                className="form-aviron select"
                                              >
                                                <InputLabel required>
                                                  Game Type
                                                </InputLabel>
                                                <GameTypeSelect
                                                  otherProps={{
                                                    style: {
                                                      width: "100%",
                                                    },
                                                    inputProps: {
                                                      // ...field,
                                                      disabled: isViewForm,
                                                    },
                                                    fullWidth: true,
                                                    ...field,
                                                  }}
                                                  appId={lv1FormField.app}
                                                />
                                                <FormHelperText
                                                  error={
                                                    !_.isEmpty(
                                                      fieldErrors?.gameType
                                                    )
                                                  }
                                                >
                                                  {fieldErrors?.gameType
                                                    ? // && fieldTouched?.conditionType
                                                      fieldErrors?.gameType
                                                    : null}
                                                </FormHelperText>
                                              </FormControl>
                                            )}
                                          />
                                        </div>
                                        <div className={style["fieldRow"]}>
                                          <Field
                                            name={`lv1FormFields.${index}.order`}
                                            render={({ field }: any) => (
                                              <FormControl
                                                fullWidth
                                                className="form-aviron"
                                              >
                                                <TextField
                                                  {...field}
                                                  required
                                                  label="Sort Order"
                                                  placeholder="Enter Number"
                                                  fullWidth
                                                  margin="normal"
                                                  variant="outlined"
                                                  error={
                                                    !_.isEmpty(
                                                      fieldErrors?.order
                                                    )
                                                  }
                                                  helperText={
                                                    fieldErrors?.order
                                                      ? // && fieldTouched?.conditionValue
                                                        fieldErrors?.order
                                                      : null
                                                  }
                                                  disabled={isViewForm}
                                                />
                                              </FormControl>
                                            )}
                                          />
                                          <Field
                                            name={`lv1FormFields.${index}.clientIndex`}
                                            render={({ field }: any) => (
                                              <FormControl
                                                fullWidth
                                                className="form-aviron"
                                              >
                                                <TextField
                                                  {...field}
                                                  required
                                                  label="Client Index"
                                                  placeholder="Enter Number"
                                                  fullWidth
                                                  margin="normal"
                                                  variant="outlined"
                                                  error={
                                                    !_.isEmpty(
                                                      fieldErrors?.clientIndex
                                                    )
                                                  }
                                                  helperText={
                                                    fieldErrors?.clientIndex
                                                      ? // && fieldTouched?.conditionValue
                                                        fieldErrors?.clientIndex
                                                      : null
                                                  }
                                                  disabled={isViewForm}
                                                />
                                              </FormControl>
                                            )}
                                          />
                                        </div>
                                        <div className={style["fieldRow"]}>
                                          <Field
                                            name={`lv1FormFields.${index}.new`}
                                            render={({ field }: any) => (
                                              <FormControl fullWidth>
                                                <RadioGroup
                                                  required
                                                  name={field.name}
                                                  {...field}
                                                  label={"New"}
                                                  className={
                                                    style["RadioGroupWrapper"]
                                                  }
                                                >
                                                  {[
                                                    {
                                                      name: "Yes",
                                                      value: true,
                                                    },
                                                    {
                                                      name: "No",
                                                      value: false,
                                                    },
                                                  ].map((type: any) => {
                                                    return (
                                                      <FormControlLabel
                                                        value={type.value}
                                                        control={
                                                          <Radio
                                                            disabled={
                                                              isViewForm
                                                            }
                                                          />
                                                        }
                                                        label={type.name}
                                                        key={type.value}
                                                      />
                                                    );
                                                  })}
                                                </RadioGroup>
                                              </FormControl>
                                            )}
                                          />
                                        </div>
                                      </Collapse>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        {values.lv1FormFields.length <= 2 && !isViewForm && (
                          <div
                            className={style["addGroupConditionButton"]}
                            onClick={() => {
                              arrayHelpers.push({
                                app: "",
                                order: "",
                                clientIndex: "",
                                new: true,
                                gameType: "",
                              });
                            }}
                          >
                            <div className={style["addButtonText"]}>
                              <AddIcon />
                              Add New App
                            </div>
                          </div>
                        )}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                        {/* <pre>{JSON.stringify({ touched }, null, 2)}</pre>
                        <pre>{JSON.stringify({ errors }, null, 2)}</pre> */}
                      </>
                    )}
                  />
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    id="appConditionFormikId"
                  >
                    aaa
                  </button>
                </Form>
              ) : null}
            </>
          )}
        </Formik>
      </Suspense>
    </div>
  );
};

export default ConditionForm;

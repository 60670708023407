import { makeStyles } from "@mui/styles";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import DynamicAppForm from "Components/DynamicAppForm/Condition";
import CurrencySelect from "Components/GameShopProduct/CurrencySelect";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";
import ProductStatus from "Components/GameShopProduct/ProductStatus";
import { ImageUploadInput } from "Components/ImageUploadInput/ImageUploadInput";
import { RingLoading } from "Components/RingLoading/RingLoading";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "Components/base";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { PRODUCT_STORE_TYPE, STATUS_GAME_SHOP_PRODUCT } from "consts/gameShop";
import lodash from "lodash";
import React from "react";
import { torem } from "styles/theme/toRem";
import Collapse from "../../../../Components/DynamicContionForm/Collapse";
import useDetailHook from "./Detail.hook";
import style from "./Detail.module.scss";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "12px",
    margin: "12px",
  },
  buttonGroup: {
    margin: "12px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "48%",
  },
}));

const AchievementDetail = () => {
  const inputLabel = React.useRef(null);
  const classes = useStyles();
  const initConditionForm = {
    lv1FormFields: [
      {
        lv2FormFields: [
          {
            type: "rank_in_game",
            operator: "eq",
            operator_value: 1,
            lv3FormFields: [],
          },
        ],
      },
    ],
  };
  const initLevelForm = {
    lv1FormFields: [],
  };
  const {
    loading,
    setBack,
    formik,
    appFormik,
    handleSubmitForms,
    handleUploadThumb,
    initAppFormik,
    handleDeleteProduct,
  } = useDetailHook();

  const handleChangeSelectSearch = (name: any, value: any) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className={style["AddPostWrapper"]}>
      <Grid
        container
        spacing={torem(16)}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-sub-title pointer" onClick={setBack}>
            Product Management &gt; Product Detail
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: torem(16) }}
          >
            <div className="aviron-title" role="presentation">
              &nbsp;Product Detail
            </div>
            <ProductStatus status={formik.values.status} />
          </div>
        </Grid>
        <Grid item className={"buttonGroup"}>
          {formik.values.status === STATUS_GAME_SHOP_PRODUCT.inactive && (
            <ButtonWithLoading
              variant="outlined"
              color="error"
              loading={loading}
              type="submit"
              onClick={handleDeleteProduct}
              startIcon={<TrashIcon />}
              style={{ minWidth: torem(120) }}
            >
              Delete
            </ButtonWithLoading>
          )}
          <ButtonWithLoading
            variant="contained"
            loading={loading}
            type="submit"
            onClick={handleSubmitForms}
            startIcon={<SaveIcon />}
            style={{ minWidth: torem(120), marginLeft: torem(16) }}
          >
            Update
          </ButtonWithLoading>
        </Grid>
      </Grid>
      <div className={style["addBody"]}>
        {!lodash.isEmpty(formik.errors) && (
          <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
            <FormHelperText>Error in Profile Form</FormHelperText>
          </div>
        )}
        <div id="profileFormId">
          <Collapse headerText="Profile" defaultOpen={true}>
            <form>
              <div className={style["profileContent"]}>
                <Grid
                  container
                  spacing={torem(35)}
                  className="inputSelectFormGroup"
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth className="form-aviron">
                      <TextField
                        required
                        id="name"
                        label="Name"
                        placeholder="Enter Name"
                        fullWidth
                        name="name"
                        margin="normal"
                        variant="outlined"
                        error={formik.errors.name ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        helperText={
                          // @ts-ignore
                          formik.errors.name && formik.touched.name
                            ? formik.errors.name
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className="form-aviron selectField">
                      <InputLabel required>Store</InputLabel>
                      <Select
                        value={formik.values.store}
                        onChange={formik.handleChange}
                        inputProps={{ name: "store" }}
                        placeholder={"Select Store"}
                        fullWidth
                      >
                        {PRODUCT_STORE_TYPE.map(
                          // @ts-ignore
                          (type, index) => {
                            return (
                              <MenuItem
                                value={type.key}
                                key={"currencyType" + index}
                              >
                                {type.label}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                      <FormHelperText
                        error={formik.errors.store ? true : false}
                      >
                        {formik.errors.store && formik.touched.store
                          ? formik.errors.store
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <FormControl fullWidth className="form-aviron">
                  <TextField
                    required
                    id="description"
                    label="Description"
                    placeholder="Enter Description"
                    fullWidth
                    name="description"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={3}
                    value={formik.values.description}
                    error={
                      formik.errors.description && formik.touched.description
                        ? true
                        : false
                    }
                    // required={true}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    defaultValue={formik.values.description}
                    helperText={
                      formik.errors.description && formik.touched.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </FormControl>
                <Grid
                  container
                  spacing={torem(35)}
                  className="inputSelectFormGroup"
                >
                  <Grid item xs={6}>
                    <FormControl fullWidth className="form-aviron">
                      <TextField
                        required
                        id="price"
                        label="Price"
                        placeholder="Enter Price"
                        fullWidth
                        name="price"
                        margin="normal"
                        variant="outlined"
                        error={formik.errors.price ? true : false}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.price}
                        helperText={
                          // @ts-ignore
                          formik.errors.price && formik.touched.price
                            ? formik.errors.price
                            : null
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className="form-aviron selectField">
                      <InputLabel
                        // ref={inputLabel}
                        htmlFor="outlined-machine-type"
                        required
                      >
                        Currency
                      </InputLabel>
                      <CurrencySelect
                        onChange={formik.handleChange}
                        value={formik.values.currency}
                        otherProps={{
                          onBlur: formik.handleBlur,
                          error:
                            formik.errors.currency && formik.touched.currency
                              ? true
                              : false,
                          placeholder: "Select a Currency",
                        }}
                      />
                      <FormHelperText
                        error={formik.errors.currency ? true : false}
                      >
                        {formik.errors.currency && formik.touched.currency
                          ? formik.errors.currency
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={torem(35)}>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className="form-aviron"
                      style={{ marginTop: torem(16) }}
                    >
                      <InputLabel required>Category</InputLabel>
                      <ParentProductCategorySelect
                        formik={formik}
                        menuProps={{ width: torem(500) }}
                        initValue={{
                          id: formik?.values?.category?.id,
                          name: formik?.values?.category?.name,
                        }}
                        name={"category"}
                        isChild={true}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className="form-aviron"
                      style={{ marginTop: torem(16) }}
                    >
                      <InputLabel required>Game Type</InputLabel>
                      <SelectAutoComplete
                        //@ts-ignore
                        // value={formik.values.gameType}
                        options={ACCHIVEMENT_TYPE.map((item) => {
                          return {
                            label: item.label,
                            value: item.key,
                          };
                        })}
                        onChange={handleChangeSelectSearch}
                        inputProps={{ name: "gameType" }}
                        placeholder={"Select Game Type"}
                        fullWidth
                      />
                      <FormHelperText
                        error={formik.errors.gameType ? true : false}
                      >
                        {formik.errors.gameType && formik.touched.gameType
                          ? formik.errors.gameType
                          : null}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}
                </Grid>
                <div className={style["uploadImageField"]}>
                  <ImageUploadInput
                    label="Product Image"
                    uploadImage={handleUploadThumb}
                    thumbValue={formik.values.icon}
                    onChangeValue={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                    nameFieldForm="icon"
                    isHideUploadFile
                  />
                </div>
                {/* <pre>
                  {JSON.stringify(formik.values, null, 2)}
                </pre> */}
              </div>
            </form>
          </Collapse>
        </div>
        {!lodash.isEmpty(appFormik?.current?.errors) && (
          <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
            <FormHelperText>Error in Apps Form</FormHelperText>
          </div>
        )}
        <div className={style["conditionWrapper"]} id="appFormId">
          <Collapse headerText="Apps" defaultOpen={true}>
            <form>
              {initAppFormik && (
                <DynamicAppForm
                  initValues={initAppFormik}
                  formik={appFormik}
                  isViewForm={
                    formik.values.status === STATUS_GAME_SHOP_PRODUCT.active
                  }
                />
              )}
            </form>
          </Collapse>
        </div>
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default AchievementDetail;


import { useHistory } from "react-router-dom";
import { useGetRequirementsQuery, useUpdateRequirementsMutation, useDeleteRequirementMutation } from "services/graphql/crm_server";
import * as React from "react";
import { toast } from "react-toastify";

import { ENV } from 'scripts/apps/environment'

const context = {
    clientName: ENV.CRM_SERVER_CONTEXT
  }

type rqType = {
    id: number;
    name: string;
    open: boolean;
}

const useRequirementsHook = () => {
    const history = useHistory();
    const [updateRequirements] = useUpdateRequirementsMutation()
    const [deleteRequirement] = useDeleteRequirementMutation()
    const { data, loading } = useGetRequirementsQuery({
        context
      });
      const [requirements, setRequirements] = React.useState([] as rqType[]);
    React.useMemo(() => {
        if (data && data.getRequirements) {
            setRequirements(data.getRequirements.map((e: any) => {
                return {
                    id: e.id,
                    name: e.name,
                    open: false,
                }
            }))
        }
    }, [data]);

    // const updateRequirement = async () => {
    //     await updateRequirements({
    //         variables: {
    //           data: requirements.map((e: any) => {
    //             return {
    //                 id: e.id,
    //                 name: e.name,
    //             }
    //             })
    //         },
    //         context
    //       })
    // }

    const columns = [
        {
            numeric: false,
            disablePadding: false,
            label: "Id",
            id: "id",
            underline: true,
            hasSub: true,
        },
        {
            numeric: false,
            disablePadding: false,
            label: "Name",
            id: "name",
        }
      ]

      const onChangeRqName = (event: any, buff: any) => {
        const {value} = event.target
        setRequirements(requirements.map((item: any) => {
            if (item.id === buff.id) {
                item.name = value
            } 
            return item;
        })
      )
    }

    const handleClose = (event: any, id: any) => {
        setRequirements(requirements.map((x) => {
              const item: any = { ...x };
              if (item.id === id) {
                  item.open = item.open !== undefined ? !item.open : true;
              } else {
                  item.open = false;
              }
              return item;
          })
        )
      };

      const handleDelete = async (rq: any) => {
        const rqToDel = requirements.find(e => e.id == rq.id)
        const newRq = requirements.filter(e => e.id != rq.id)
        setRequirements(newRq)
        if (rqToDel) {
          await deleteRequirement({
            variables: {
              id: rqToDel.id
            },
            context
          })
          toast.success("Delete game level success");
        }
      }

      const setBack = () => {
        history.goBack();
    };
    const handleAddMore = () => {
        const newRqId = Math.max(...requirements.map(e => e.id)) + 1
      const newRq = Object.assign({}, {
        id: newRqId,
        name: "",
        open: false,
        })
      setRequirements([...requirements, newRq])
    }
    const handleSave = async () => {
        console.log('handleSave', requirements)
        const rqToSave = requirements.map(e => {
            return {
                id: e.id,
                name: e.name,
            }
          })
        if (rqToSave.every(item => 
            Object.values(item).some(value => !value)
          )) {
            toast.error("Some value are empty");
            return
          }
        await updateRequirements({
            variables: {
              data: rqToSave
            },
            context
          })
          toast.success("Save game levels success");
    }

    return {
        loading,
        requirements,
        columns,
        onChangeRqName,
        handleClose,
        handleDelete,
        setBack,
        handleAddMore,
        handleSave,
    };
};

export default useRequirementsHook;

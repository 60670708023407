import React, { useEffect, useState, Suspense } from "react";
import style from "./ConditionForm.module.scss";
import AddIcon from "Components/base/Icons/AddIcon";
import Collapse from "../Collapse";
import { FieldArray, Form, Formik, useFormik } from "formik";
import { RingLoading } from "Components/RingLoading/RingLoading";
import Lv2Form from "./Lv2Form";
interface ConditionFormProps {
  initValues?: any;
  desc?: string | any;
  formik?: any;
  otherForm?: any;
  disabled?: boolean
}
const ConditionForm = ({
  initValues,
  desc,
  formik,
  otherForm,
  disabled=false
}: ConditionFormProps) => {
  // console.log('initValues condition', initValues);
  return (
    <div className={style["ConditionForm"] + " ConditionForm"}>
      <Suspense fallback={<RingLoading />}>
        <div className={style["description"]}>
          {desc
            ? desc
            : "User achieve this achievement when all conditions are true"}
        </div>
        <Formik
          onSubmit={async (values) => {
            otherForm && (await otherForm?.submitForm());
          }}
          initialValues={initValues}
          innerRef={formik}
        >
          {({ values, setValues }: any) => (
            <>
              {values.lv1FormFields ? (
                <Form>
                  <FieldArray
                    name="lv1FormFields"
                    render={(arrayHelpers) => (
                      <>
                        {values.lv1FormFields.length > 0 &&
                          values.lv1FormFields.map(
                            (lv1FormField: any, index: any) => {
                              return (
                                <div key={index}>
                                  <div
                                    className={
                                      style["conditionCard"] +
                                      " " +
                                      style["conditionGroupForm"]
                                    }
                                  >
                                    <Collapse
                                      headerText="Condition 1"
                                      defaultOpen={true}
                                      removeIconProps={
                                        index !== 0 && !disabled
                                          ? {
                                              action: () =>
                                                arrayHelpers.remove(index),
                                            }
                                          : null
                                      }
                                    >
                                      <Lv2Form
                                        setParentValues={setValues}
                                        parentValues={values}
                                        parentIndex={index}
                                        initValue={lv1FormField.lv2FormFields}
                                        disabled={disabled}
                                      />
                                    </Collapse>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        {values.lv1FormFields.length <= 1 && !disabled && (
                          <div
                            className={style["addGroupConditionButton"]}
                            onClick={() =>
                              arrayHelpers.push({
                                lv2FormFields: [
                                  {
                                    type: "rank_in_game",
                                    operator: "eq",
                                    operator_value: 1,
                                    lv3FormFields: [],
                                  },
                                ],
                              })
                            }
                          >
                            <div className={style["addButtonText"]}>
                              <AddIcon />
                              Add Condition
                            </div>
                          </div>
                        )}
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </>
                    )}
                  />
                  <button
                    type="submit"
                    id={"conditionFormikId"}
                    style={{ display: "none" }}
                  >
                    aaa
                  </button>
                </Form>
              ) : null}
            </>
          )}
        </Formik>
      </Suspense>
    </div>
  );
};

export default ConditionForm;

import React, { ReactNode } from 'react'
import { Grid as ComponentMui, GridProps as ComponentMuiProps } from '@mui/material';
import './MuiGrid.scss';

interface GridProps extends ComponentMuiProps {
    children?: any
    component?: string
};
const Grid = (props:GridProps | ComponentMuiProps) => {
    const {children} = props;
    return (
            <ComponentMui {...props}>
                {children}
            </ComponentMui>
    )
}

export default Grid;
import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Input,
    Select,
    Button,
} from "Components/base";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Main.hook";
// import Select from "react-select";

export const Main: React.FunctionComponent = () => {
    const {
        columns,
        loading,
        rows,
        gameTypesOptions,
        onChangeGameType,
        gameLevelsStore,
        handleClose,
        handleDelete,
        requirementTypes,
        onChangeLvlUpRequire,
        onChangeRequirementType,
        buffTypes,
        onChangeBuff,
        handleAddMore,
        handleSave,
        gotoBuff,
        gotoRequirements,
    } = useMainHook();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);


    return loading ? (<RingLoading />) : (
        <div>
            <div className="aviron-title">Game Levels management</div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <Select
                        value={gameLevelsStore.options.gameType as any}
                        // options={gameTypesOptions}
                        onChange={onChangeGameType}
                        name="game_types_options"
                        fullWidth
                    >
                        {gameTypesOptions?.map(gt => {
                            return (
                                <MenuItem value={gt.id} key={`gameTypesOptions-${gt.id}`}>{gt.name.toUpperCase()}</MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item xs={12} md={6} lg={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={6}></Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                variant="contained"
                                onClick={gotoBuff}
                                color="secondary"
                                className="btn-secondary"
                                data-cy="add-machine-btn"
                            >
                                <i className="icon-secondary" /> Edit Buff Types
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Button
                                variant="contained"
                                onClick={gotoRequirements}
                                color="secondary"
                                className="btn-secondary"
                                data-cy="add-machine-btn"
                            >
                                <i className="icon-secondary" /> Edit Requirements
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="table">
                <Table className="video-list-table custom-table-element">
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    key={`columns-${column.id}`}
                                    padding={column.disablePadding ? "none" : "normal"}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell key="action">
                                <span className="column-no">Actions</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map(el => {
                                return (
                                    <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${el.game_type}-${el.level}`}>
                                        <TableCell>
                                            {el.level}
                                        </TableCell>
                                        <TableCell>
                                            <Input value={el.level_up_require.value} onChange={(event) => onChangeLvlUpRequire(event, el.level)} style={{ width: 60, marginRight: 10 }} />
                                            <Select
                                                value={el.level_up_require.type}
                                                // options={requirementTypesOptions}
                                                name={`equirementTypes-${el.level}`}
                                                onChange={(event: any) => onChangeRequirementType(event, el.level)}
                                                // inputProps={{ name: "selected" }}
                                                autoWidth
                                            >
                                                {requirementTypes?.map(rq => {
                                                    return (
                                                        <MenuItem value={rq.id} key={`requirementTypes-${rq.id}`}>{rq.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                buffTypes?.map(b => {
                                                    return (
                                                        <div key={`buffTypes-${el.game_type}-${el.level}-${b.id}`}>
                                                            <Input value={(el.buff as any)[b.id] || 0} onChange={(event) => onChangeBuff(event, b.id, el.level)} style={{ width: 60, marginRight: 10 }} />
                                                            {b.name}&nbsp;({b.value_type})
                                                        </div>
                                                    )
                                                })
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <div className="position-relative">
                                                <button
                                                    aria-controls={`action-menu-${el.level}`}
                                                    aria-haspopup="true"
                                                    aria-describedby={el.level.toString()}
                                                    className="actions-table pointer"
                                                    // variant="contained"
                                                    role="presentation"
                                                    onClick={(event) => {

                                                        setAnchorEl(
                                                            event.currentTarget
                                                        );
                                                        handleClose(
                                                            event,
                                                            el.level
                                                        );
                                                    }}
                                                    data-cy="cms-table-action-btn"
                                                >
                                                    <i className="icon-ic_more_vert" />
                                                </button>
                                                <Menu
                                                    id={`action-menu-${el.level}`}
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    //open={Boolean(anchorEl)}
                                                    open={el.open}
                                                    onClose={(event) => {
                                                        setAnchorEl(null);
                                                        handleClose(event, el.level);
                                                    }}
                                                    data-cy="cms-table-action-menu"
                                                >
                                                    <MenuItem
                                                        className="actions-table-delete pointer"
                                                        role="presentation"
                                                        onBlur={(event) =>
                                                            handleClose(
                                                                event,
                                                                el.level
                                                            )
                                                        }
                                                        onClick={(event) => handleDelete(el)}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </div>
            <Grid
                item
                xs={12}
                md={6}
                lg={8}
            >
                <Button
                    variant="contained"
                    onClick={(event) => handleAddMore()}
                    color="secondary"
                    className="btn-primary"
                    data-cy="add-machine-btn"
                >
                    <i className="icon-e-add" /> ADD LEVEL
                </Button>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    color="secondary"
                    className="btn-secondary"
                    data-cy="add-machine-btn"
                >
                    <i className="icon-secondary" /> SAVE
                </Button>
            </Grid>
        </div>
    );
};

import { makeStyles } from "@mui/styles";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "Components/base";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { CATEGORY_GROUP, CATEGORY_TYPE } from "consts/gameShop";
import React from "react";
import { torem } from "styles/theme/toRem";
import useDetailHook from "./Detail.hook";
import style from "./Detail.module.scss";
import DetaiTabs from "./DetaiTabs";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";
import { RingLoading } from "Components/RingLoading/RingLoading";
import GroupCategory from "Components/GameShopProduct/GroupCategorySelect";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "12px",
    margin: "12px",
  },
  buttonGroup: {
    margin: "12px",
    padding: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "48%",
  },
}));

const Detail = () => {
  const inputLabel = React.useRef(null);
  const classes = useStyles();
  const { loading, setBack, formik, handleDelete, propsTable, originId } =
    useDetailHook();

  return (
    <div className={style["DetailGameShopCategoryWrapper"]}>
      <form>
        <Grid
          container
          spacing={torem(16)}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item>
            <div className="aviron-sub-title pointer" onClick={setBack}>
              Product Category Management &gt; Product Category Detail
            </div>
            <div className="aviron-title" role="presentation">
              &nbsp; Product Category Detail
            </div>
          </Grid>
          <Grid item className={"buttonGroup"} spacing={1}>
            <ButtonWithLoading
              variant="outlined"
              color="error"
              onClick={handleDelete}
              startIcon={<TrashIcon />}
              style={{ minWidth: torem(120) }}
            >
              Delete
            </ButtonWithLoading>
            <ButtonWithLoading
              variant="contained"
              type="submit"
              onClick={(event: any) => {
                // console.log("submit: ");
                formik.handleSubmit(event);
              }}
              style={{ marginLeft: torem(16), minWidth: torem(120) }}
              startIcon={<SaveIcon />}
            >
              Save
            </ButtonWithLoading>
          </Grid>
        </Grid>
        <div className={style["formWrapper"]}>
          <div className={style["formContainer"]}>
            <div>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  required
                  id="name"
                  label="Name"
                  placeholder="Enter Title"
                  fullWidth
                  name="name"
                  margin="normal"
                  variant="outlined"
                  error={formik.errors.name ? true : false}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  helperText={
                    // @ts-ignore
                    formik.errors.name && formik.touched.name
                      ? formik.errors.name
                      : null
                  }
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron select">
                <InputLabel required>Group</InputLabel>
                <GroupCategory
                  value={formik.values.categoryGroupId}
                  onChange={formik.handleChange}
                  otherProps={{
                    placeholder: "Choose Group",
                  }}
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron select">
                <InputLabel>Parent Product Category</InputLabel>
                <ParentProductCategorySelect
                  formik={formik}
                  menuProps={{ width: torem(500) }}
                  initValue={{
                    id: formik?.values?.parentCategory?.id,
                    name: formik?.values?.parentCategory?.name,
                  }}
                  name={"parentCategory"}
                  originId={originId}
                  ignoreProduct = {true}
                />
              </FormControl>
            </div>
            <div className={style["rightContent"]}>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="sortOrder"
                  label="Sort Order"
                  placeholder="Enter Sort Order"
                  fullWidth
                  name="sortOrder"
                  margin="normal"
                  variant="outlined"
                  value={formik.values.sortOrder}
                  error={
                    formik.errors.sortOrder && formik.touched.sortOrder
                      ? true
                      : false
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.sortOrder}
                  helperText={
                    formik.errors.sortOrder && formik.touched.sortOrder
                      ? formik.errors.sortOrder
                      : null
                  }
                />
              </FormControl>
              <FormControl fullWidth className="form-aviron">
                <TextField
                  id="description"
                  required
                  label="Description"
                  placeholder="Enter Description"
                  fullWidth
                  name="description"
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                      ? true
                      : false
                  }
                  // required={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue={formik.values.description}
                  helperText={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : null
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
      </form>
      <div className={style["listTableWrapper"]}>
        <DetaiTabs />
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

export default Detail;

import moment from "moment";
import { BaseReducer } from "../base";

class ExperienceCategoryReducer extends BaseReducer {
    constructor() {
        super(
            "EXPERIENCE_CATEGORY",
            {
                currentPage: 0,
                search: "",
                advancedFilter: {
                    state: {
                        value: "",
                        label: "All",
                    },
                    planType: {
                        value: "",
                        label: "All",
                    },
                    customerType: {
                        value: "",
                        label: "All",
                    },
                    startTime: moment().subtract(1, "years").format(),
                    endTime: moment().endOf("days").format(),
                },
            },
            {}
        );
    }
}

export const experienceCategoryReducer = new ExperienceCategoryReducer();
